import React from 'react'
import { Form, Input, TimePicker } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { hasError, getErrorMessage } from '~/lib/errorMessages'
import { TIME_WITHOUT_SECOND_FORMAT } from '~/constants/format'
import moment from 'moment'

class CreateDeliveryTimeSlotForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    const formattedValues = {
                        name: values.name,
                        startTime: moment(values.startTime).format(TIME_WITHOUT_SECOND_FORMAT),
                        endTime: moment(values.endTime).format(TIME_WITHOUT_SECOND_FORMAT)
                    }
                    this.props.onSubmit(formattedValues, () => this.props.form.resetFields())
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl, initial, error } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        }
        return (
            <Form {...formItemLayout} style={{ backgroundColor: 'white', padding: 16 }}>
                <Form.Item
                    error
                    label={intl.formatMessage({ ...messages.name })}
                    validateStatus={hasError(error, 32004) ? 'error' : null}
                    help={getErrorMessage(intl, error, 32004)}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.startTime })}>
                    {
                        getFieldDecorator('startTime', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? moment(initial.startTime, TIME_WITHOUT_SECOND_FORMAT) : undefined
                        })(
                            <TimePicker
                                minuteStep={1}
                                format={TIME_WITHOUT_SECOND_FORMAT}
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.endTime })}>
                    {
                        getFieldDecorator('endTime', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? moment(initial.endTime, TIME_WITHOUT_SECOND_FORMAT) : undefined
                        })(
                            <TimePicker
                                minuteStep={1}
                                format={TIME_WITHOUT_SECOND_FORMAT}
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateDeliveryTimeSlotForm))