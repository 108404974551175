import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { DatePicker, Card, Statistic, Radio } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { Helmet } from 'react-helmet'
import { rangeOptions } from '~/constants/options'
import moment from 'moment'
import FoodOrderBarChart from '~/components/charts/foodOrderBarChart'
import FoodOrderSummaryPerShopBarChart from '~/components/charts/foodOrderSummaryPerShopBarChart'
import StoreMap from '~/components/storeMap'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

@inject('analysisStore', 'commonStore') @observer
class MainPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mode: 'TODAY',
            deliveryMethod: ['DELIVERY', 'SELF_PICK_UP'],
            range: [moment().startOf('day'), moment().endOf('day')]
        }
    }

    async componentDidMount() {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        const { range, deliveryMethod } = this.state
        const [startDate, endDate] = range
        await analysisStore.getFoodOrderSummary(token, undefined, startDate.format(), endDate.format(), deliveryMethod)
        await analysisStore.getFoodOrderSummaryByPeriod(token, undefined, 'HOURLY', moment().startOf('day').add(8, 'hour').format(), endDate.format(), deliveryMethod)
        await analysisStore.getFoodOrderSummaryPerShopByRange(token, moment().startOf('month').format(), moment().endOf('month').format(), deliveryMethod)
    }

    async handleOnPeriodDataFetch(mode, startDate, endDate, deliveryMethod) {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        await analysisStore.getFoodOrderSummaryByPeriod(token, undefined, mode, startDate.format(), endDate.format(), deliveryMethod)
    }

    async handleOnShopOrderDataFetch(startDate, endDate, deliveryMethod) {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        await analysisStore.getFoodOrderSummaryPerShopByRange(token, startDate.format(), endDate.format(), deliveryMethod)
    }

    renderDeliveryMethodButton() {
        const { intl } = this.props
        const { deliveryMethod } = this.state
        var value
        if (deliveryMethod.includes('DELIVERY') && deliveryMethod.includes('SELF_PICK_UP')) {
            value = 'ALL'
        } else {
            value = deliveryMethod[0]
        }
        return (
            <Radio.Group onChange={(e) => this.handleOnDeliveryMethodChange(e.target.value)} defaultValue="ALL" value={value} key={0} style={{ marginRight: 16 }}>
                <Radio.Button key={'ALL'} value={'ALL'}>{intl.formatMessage({ ...messages.all })}</Radio.Button>
                <Radio.Button key={'DELIVERY'} value={'DELIVERY'}>{intl.formatMessage({ ...messages.delivery })}</Radio.Button>
                <Radio.Button key={'SELF_PICK_UP'} value={'SELF_PICK_UP'}>{intl.formatMessage({ ...messages.selfPickUp })}</Radio.Button>
            </Radio.Group>
        )
    }

    renderRangeButton() {
        const { intl } = this.props
        const buttons = [
            <Radio.Group onChange={(e) => this.handleOnModeChange(e.target.value)} defaultValue="TODAY" value={this.state.mode} key={0}>
                {rangeOptions.map((r, i) => <Radio.Button key={r.value} value={r.value}>{intl.formatMessage({ ...r.text })}</Radio.Button>)}
            </Radio.Group>
        ]
        return this.state.mode !== 'CUSTOMIZE'
            ? buttons
            : buttons.concat(<DatePicker.RangePicker
                value={this.state.range}
                key={1}
                onChange={(v) => this.handleOnRangeChange(v)} />
            )
    }

    async handleOnRangeChange(v) {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        const { mode, deliveryMethod } = this.state
        if (mode === 'CUSTOMIZE' && v && v.length > 0) {
            analysisStore.getFoodOrderSummary(token, undefined, v[0].format(), v[1].format(), deliveryMethod)
        }
        this.setState({ range: v })
    }

    async handleOnDeliveryMethodChange(value) {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        const { range } = this.state
        const [startDate, endDate] = range
        var deliveryMethod
        if (value === 'ALL') {
            deliveryMethod = ['DELIVERY', 'SELF_PICK_UP']
        } else {
            deliveryMethod = [value]
        }
        this.setState({ deliveryMethod })
        await analysisStore.getFoodOrderSummary(token, undefined, startDate.format(), endDate.format(), deliveryMethod)
    }

    async handleOnModeChange(value) {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        let startDate = undefined
        let endDate = undefined
        switch (value) {
            case 'TODAY':
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            case 'YESTERDAY':
                startDate = moment().startOf('day').subtract(1, 'day')
                endDate = moment().endOf('day').subtract(1, 'day')
                break
            case 'WEEK':
                startDate = moment().startOf('week')
                endDate = moment().endOf('week')
                break
            case 'MONTH':
                startDate = moment().startOf('month')
                endDate = moment().endOf('month')
                break
            case 'CUSTOMIZE':
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            default:
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
        }
        const { deliveryMethod } = this.state
        analysisStore.getFoodOrderSummary(token, undefined, startDate.format(), endDate.format(), deliveryMethod)
        this.setState({ mode: value, range: [startDate, endDate] })
    }

    render() {
        const { analysisStore, intl, restaurantStore } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.home })}</title>
                </Helmet>
                <Card
                    style={{ marginBottom: 16 }}
                    title={
                        <ActionWrapper>
                            <ActionLeftWrapper>{intl.formatMessage({ ...messages.foodOrder })}</ActionLeftWrapper>
                            {this.renderDeliveryMethodButton()}
                            {this.renderRangeButton()}
                        </ActionWrapper>
                    }>
                    <Statistic title={intl.formatMessage({ ...messages.numberOfOrder })} value={analysisStore.foodOrderTotalCount} />
                    <Statistic title={intl.formatMessage({ ...messages.orderTotalAmount })} value={analysisStore.foodOrderTotalAmount} precision={2} suffix='HKD' />
                </Card>
                <FoodOrderBarChart
                    onFetchData={(mode, startDate, endDate, deliveryMethod) => this.handleOnPeriodDataFetch(mode, startDate, endDate, deliveryMethod)}
                    data={analysisStore.foodOrderSummaryByPeriod.map((s, i) => {
                        return { name: `${s._id}`, count: s.totalCount, amount: s.totalAmount }
                    })}/>
                <FoodOrderSummaryPerShopBarChart
                    onFetchData={(startDate, endDate, deliveryMethod) => this.handleOnShopOrderDataFetch(startDate, endDate, deliveryMethod)}
                    countData={analysisStore.foodOrderSummaryPerShop.map((s, i) => {
                        return { name: `${s.shop.name}`, [intl.formatMessage({ ...messages.orderCount })]: s.count }
                    })}
                    amountData={analysisStore.foodOrderSummaryPerShop.map((s, i) => {
                        return { name: `${s.shop.name}`, [intl.formatMessage({ ...messages.orderTotalAmount })]: s.sum }
                    })} />
                <StoreMap />
            </Container>
        )
    }
}

export default withRouter(injectIntl(MainPage))