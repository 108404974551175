import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css'
import { editorLangs, finderLangs } from '~/constants/braftEditorLangs'

const Wrapper = styled.div`
    /* border-radius: 4px; */
    border: 1px solid #d9d9d9;
    height: 300px;

    .bf-content {
        height: 208px;
    }
`

class RichTextEditor extends Component {
    constructor(props) {
        super(props)
        this.state = {
            editorState: BraftEditor.createEditorState()
        }
    }

    componentDidMount() {
        // 获取媒体库实例
        this.braftFinder = this.editorInstance.getFinderInstance()
    }

    async handleOnMediaUpload(param) {
        console.log(param)
        const { commonStore, mediaStore } = this.props
        const { token } = commonStore
        try {
            const path = await mediaStore.uploadPhoto(token, param.file)
            param.success({
                url: path,
              })
        } catch (e) {
            param.error({
                msg: 'unable to upload.'
            })
        }
    }

    render() {
        const { disabled, placeholder, value, onChange } = this.props
        return (
            <Wrapper>
                <BraftEditor
                    ref={instance => this.editorInstance = instance}
                    controls={[
                        'undo', 'redo', 'separator',
                        'bold', 'italic', 'underline', 'separator',
                        'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
                        'headings', 'list-ul', 'list-ol', 'separator',
                        'media', 'separator',
                        'clear'
                    ]}
                    language={(languages, context) => {
                        return context === 'braft-editor' ? editorLangs : finderLangs
                    }}
                    height={300}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    placeholder={placeholder}
                    media={{
                        accepts: {
                            image: true,
                            audio: false,
                            video: true
                        },
                        externals: {
                            image: true,
                            video: true,
                            audio: false,
                            embed: false
                        },
                        uploadFn: this.handleOnMediaUpload.bind(this)
                    }}
                />
            </Wrapper>
        )
    }
}

export default injectIntl(
    inject('commonStore', 'mediaStore')(
        observer(RichTextEditor)
    )
)