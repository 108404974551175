export function getStatus(statusCode) {
    let status = ''
    switch (statusCode) {
        case 8001: // 已換車手
            status = 'ZEEK_PARTNER_CHANGED'
        case 9005: //配送員已接單
            status = 'ZEEK_PARTNER_CONFIRMED'
            break
        case 9010: //配送員已到達店付近
            status = 'ZEEK_PARTNER_NEAR_STORE'
            break
        case 9011: //配送員已到店
        case 9015: //訂單正在配送中
        case 9017: //配送員已到達目的地付近
            status = 'DELIVERING'
            break
        case 9021: //訂單已完成
            status = 'COMPLETE'
            break
        case 9025:
        case 9026:
            status = 'CANCELLED'
            break
        case 9001:
        default:
            status = 'PREPARING'
    }
    return status
}