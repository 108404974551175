import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Card, Radio, DatePicker } from 'antd'
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
    Legend,
    Label
} from "bizcharts"
import messages from '~/messages'
import moment from 'moment'
import { analysisPeriodModeOptions } from '~/constants/options'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
`

const Title = styled.h3`

`

class OrderBarChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mode: 'HOURLY',
            selectedDate: moment()
        }
    }

    renderModeButton() {
        const { intl } = this.props
        const buttons = [
            <Radio.Group onChange={(e) => this.handleOnModeChange(e.target.value)} defaultValue="HOURLY" value={this.state.mode} key={0}>
                {analysisPeriodModeOptions.map((r, i) => <Radio.Button key={r.value} value={r.value}>{intl.formatMessage({ ...r.text })}</Radio.Button>)}
            </Radio.Group>
        ]
        return buttons
    }

    handleOnRangeChange(mode, date) {
        let startDate = undefined
        let endDate = undefined
        switch (mode) {
            case 'HOURLY':
                this.setState({ selectedDate: date })
                startDate = date.clone().startOf('day')
                endDate = date.clone().endOf('day')
                break
            case 'DAILY':
                this.setState({ selectedDate: date })
                startDate = date.clone().startOf('week')
                endDate = date.clone().endOf('week')
                break
            case 'MONTHLY':
                startDate = moment().subtract(11, 'month').startOf('month')
                endDate = moment().startOf('month')
                break
            default:
                break
        }
        this.props.onFetchData(mode, startDate, endDate)
    }

    renderRangePicker() {
        const { mode } = this.state
        switch (mode) {
            case 'HOURLY':
                return (
                    <DatePicker
                        allowClear={false}
                        value={this.state.selectedDate}
                        onChange={(date) => this.handleOnRangeChange(mode, date)}
                        disabledDate={(current) => {
                            return current && current > moment().endOf('day')
                        }}
                    />
                )
            case 'DAILY':
                return (
                    <DatePicker.WeekPicker
                        allowClear={false}
                        value={this.state.selectedDate}
                        onChange={(date) => this.handleOnRangeChange(mode, date)}
                        disabledDate={(current) => {
                            return current && current > moment().endOf('day')
                        }}
                    />
                )
            case 'MONTHLY':
                return null
            default:
                return null
        }
    }

    async handleOnModeChange(value) {
        let startDate = undefined
        let endDate = undefined
        switch (value) {
            case 'HOURLY':
                this.setState({ mode: value, selectedDate: moment() })
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            case 'DAILY':
                this.setState({ mode: value, selectedDate: moment() })
                startDate = moment().startOf('week')
                endDate = moment().endOf('week')
                break
            case 'MONTHLY':
                this.setState({ mode: value })
                startDate = moment().subtract(11, 'month').startOf('month')
                endDate = moment().endOf('month')
                break
            default:
                break
        }
        this.props.onFetchData(value, startDate, endDate)
    }

    render() {
        const { data, intl } = this.props
        return (
            <Card title={
                <ActionWrapper>
                    <ActionLeftWrapper>{this.renderModeButton()}</ActionLeftWrapper>
                    {this.renderRangePicker()}
                </ActionWrapper>
            }>
                <TitleWrapper><Title>{intl.formatMessage({ ...messages.orderCount })}</Title></TitleWrapper>
                <Chart height={400} data={data} padding={[50, 'auto', 'auto', 'auto']} forceFit
                    scale={{
                        name: {
                            alias: 'date',
                        },
                        count: {
                            min: 0,
                            tickCount: 7,
                            alias: '訂單數',
                        },
                        amount: {
                            min: 0,
                            tickCount: 7,
                            alias: '金額',
                        },
                    }}>
                    <Axis name="name" />
                    <Axis name="count" title />
                    <Axis name="amount" title />
                    <Tooltip
                        crosshairs={{
                            type: "rect"
                        }}
                    />
                    <Geom type="interval" position={`name*count`} color={'#F4470F'}>
                        <Label
                            content={[`name*count`, (name, count) => `${count}單`]}
                            labelLine={{
                                lineWidth: 1,
                                stroke: '#ff8800',
                                lineDash: [2, 2],
                            }} />
                    </Geom>
                    <Geom
                        type="line"
                        position={`name*amount`}
                        color={'#F4470F'}
                        // shape="smooth"
                        size={2}
                        label={{
                            formatter: val => `$${val}`,
                            textStyle: {
                                fill: 'red'
                            },
                        }} />
                    <Geom
                        type="point"
                        position={`name*amount`}
                        color="#f33274"
                        shape="circle"
                        size={4}
                    />
                    <Legend position="bottom" />
                </Chart>
            </Card >
        )
    }

}

export default injectIntl(OrderBarChart)