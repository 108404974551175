import React from 'react'
import { Form, Input, Table, Select, Icon, InputNumber } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import update from 'immutability-helper'
import { getRandomId } from '~/lib/idGenerator'
import { statusOptions } from '~/constants/options'

class ReservationOptionTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            options: [{
                key: getRandomId(),
                name: undefined,
                price: undefined,
                stock: undefined,
                cost: undefined,
                status: undefined
            }],
            reservationId: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { initialValue, reservationId } = props
        if (state.reservationId !== reservationId && initialValue && initialValue.length > 0) {
            return {
                options: initialValue.map(c => {
                    return {
                        key: getRandomId(),
                        name: c.name,
                        price: c.price,
                        stock: c.stock,
                        cost: c.cost,
                        status: c.status
                    }
                }),
                reservationId: reservationId
            } 
        }
        return state
    }

    reset() {
        this.setState({
            options: [{
                key: getRandomId(),
                name: undefined,
                price: undefined,
                stock: undefined,
                cost: undefined,
                status: undefined
            }],
            reservationId: undefined
        })
    }

    submit() {
        const { onChange } = this.props
        onChange(this.state.options)
    }

    handleOnAddNewClick(index) {
        const option = {
            key: getRandomId(),
            name: undefined,
            price: undefined,
            stock: undefined,
            cost: undefined,
            status: undefined
        }
        const options = update(this.state.options, { $push: [option] })
        this.setState({ options })
    }

    handleOnDeleteClick(index) {
        if (this.state.options.length > 1) {
            const options = update(this.state.options, { $splice: [[index, 1]] })
            this.setState({ options })
        }
    }

    handleOnValueChange(index, _update) {
        const options = update(this.state.options, { [index]: _update })
        this.setState({ options })
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                render: (text, record, index) => {
                    return (
                        <Input
                            value={record.name}
                            onChange={(e) => this.handleOnValueChange(index, { 'name': { $set: e.target.value } })}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                        />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.price }),
                dataIndex: 'price',
                key: 'price',
                width: 100,
                render: (text, record, index) => {
                    return (
                        <InputNumber
                            value={record.price}
                            min={0}
                            onChange={(value) => this.handleOnValueChange(index, { 'price': { $set: value } })}
                            formatter={value => `$ ${value}`}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.stock }),
                dataIndex: 'stock',
                key: 'stock',
                width: 100,
                render: (text, record, index) => {
                    return (
                        <InputNumber
                            value={record.stock}
                            min={0}
                            onChange={(value) => this.handleOnValueChange(index, { 'stock': { $set: value } })}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.cost }),
                dataIndex: 'cost',
                key: 'cost',
                width: 100,
                render: (text, record, index) => {
                    return (
                        <InputNumber
                            value={record.cost}
                            min={0}
                            onChange={(value) => this.handleOnValueChange(index, { 'cost': { $set: value } })}
                            formatter={value => `$ ${value}`}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record, index) => {
                    return (
                        <Select
                            value={record.status}
                            onChange={(value) => this.handleOnValueChange(index, { 'status': { $set: value } })}
                            placeholder={intl.formatMessage({ ...messages.pleaseSelect })}>
                            {
                                statusOptions.map((item, index) => {
                                    return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                })
                            }
                        </Select>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 70,
                render: (text, record, index) => {
                    return (
                        <span>
                            <Icon type="plus" onClick={() => this.handleOnAddNewClick(index)} style={{ marginRight: 8, cursor: 'pointer' }} />
                            <Icon type="delete" onClick={() => this.handleOnDeleteClick(index)} style={{ cursor: 'pointer' }} />
                        </span>
                    )
                }
            },
        ]
    }

    render() {
        return (
            <Table
                columns={this.renderTableColumn()}
                dataSource={this.state.options}
                rowKey={record => record.key}
                pagination={false} />
        )
    }
}

export default injectIntl(Form.create()(ReservationOptionTable))