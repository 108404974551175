import React from 'react'
import { Form, Input, Table, Select, Icon, InputNumber } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import update from 'immutability-helper'
import { getRandomId } from '~/lib/idGenerator'

class StoreTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            stores: [{
                key: getRandomId(),
                address: undefined
            }],
            shopId: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { initialValue, shopId } = props
        if (state.shopId !== shopId && initialValue && initialValue.length > 0) {
            return {
                stores: initialValue.map(c => {
                    return {
                        key: getRandomId(),
                        address: c.address,
                    }
                }),
                shopId: shopId
            } 
        }
        return state
    }

    reset() {
        this.setState({
            stores: [{
                key: getRandomId(),
                address: undefined,
            }],
            shopId: undefined
        })
    }

    submit() {
        const { onChange } = this.props
        onChange(this.state.stores)
    }

    handleOnAddNewClick(index) {
        const store = {
            key: getRandomId(),
            address: undefined,
        }
        const stores = update(this.state.stores, { $push: [store] })
        this.setState({ stores })
    }

    handleOnDeleteClick(index) {
        if (this.state.stores.length > 1) {
            const stores = update(this.state.stores, { $splice: [[index, 1]] })
            this.setState({ stores })
        }
    }

    handleOnValueChange(index, _update) {
        const stores = update(this.state.stores, { [index]: _update })
        this.setState({ stores })
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.address }),
                dataIndex: 'address',
                key: 'address',
                render: (text, record, index) => {
                    return (
                        <Input
                            value={record.address}
                            onChange={(e) => this.handleOnValueChange(index, { 'address': { $set: e.target.value } })}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                        />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 70,
                render: (text, record, index) => {
                    return (
                        <span>
                            <Icon type="plus" onClick={() => this.handleOnAddNewClick(index)} style={{ marginRight: 8, cursor: 'pointer' }} />
                            <Icon type="delete" onClick={() => this.handleOnDeleteClick(index)} style={{ cursor: 'pointer' }} />
                        </span>
                    )
                }
            },
        ]
    }

    render() {
        return (
            <Table
                columns={this.renderTableColumn()}
                dataSource={this.state.stores}
                rowKey={record => record.key}
                pagination={false} />
        )
    }
}

export default injectIntl(Form.create()(StoreTable))