import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/lib/imageCompressor'

class OptionStore {
    @observable isSubmitting = false
    @observable error = null
    @observable options = []
    @observable count = 0
    @observable foodOptions = []
    @observable foodOptionCount = 0
    @observable productSubchoices = []
    @observable productSubchoiceCount = 0
    @observable foodSubchoices = []
    @observable foodSubchoiceCount = 0
    
    createOption = flow(function* (token, shop, fieldName, remark, priority, choices) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('fieldName', fieldName)
        formData.append('remark', remark)
        formData.append('priority', priority)
        formData.append('choices', JSON.stringify(choices.map(c => {
            return {
                name: c.name,
                subchoices: c.subchoices.map(s => s._id)
            }
        })))
        for (const c of choices) {
            if (c.photos.length > 0) {
                const { originFileObj } = c.photos[0]
                if (originFileObj) {
                    var thumbnail = yield makeThumbnail(originFileObj)
                    const blob = thumbnail.slice(0, thumbnail.size, thumbnail.type)
                    const newFile = new File([blob], c.name, { type: thumbnail.type })
                    formData.append('photos', newFile)
                }
            }
        }
        try {
            yield api.createOption(token, shop, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateOption = flow(function* (token, shop, id, fieldName, remark, priority, choices) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('fieldName', fieldName)
        formData.append('remark', remark)
        formData.append('priority', priority)
        formData.append('choices', JSON.stringify(choices.map(c => {
            return {
                _id: c._id,
                name: c.name,
                subchoices: c.subchoices.map(s => s._id)
            }
        })))
        for (const c of choices) {
            if (c.photos.length > 0) {
                const { originFileObj } = c.photos[0]
                if (originFileObj) {
                    var thumbnail = yield makeThumbnail(originFileObj)
                    const blob = thumbnail.slice(0, thumbnail.size, thumbnail.type)
                    const newFile = new File([blob], c.name, { type: thumbnail.type })
                    formData.append('photos', newFile)
                }
            }
        }
        try {
            yield api.updateOption(token, shop, id, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listOption = flow(function* (token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listOption(token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax)
            const { options, count } = response.data
            this.options = options
            this.count = count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    deleteOption = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteOption(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createFoodOption = flow(function* (token, shop, fieldName, selectionRequired, remark, priority, choices) {
        this.isSubmitting = true
        try {
            yield api.createFoodOption(token, shop, fieldName, selectionRequired, remark, priority, choices.map(c => {
                return {
                    name: c.name,
                    priority: c.priority,
                    priceAdjustment: c.priceAdjustment,
                    subchoices: c.subchoices.map(s => s._id)
                }
            }))
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateFoodOption = flow(function* (token, shop, id, fieldName, selectionRequired, remark, priority, choices) {
        this.isSubmitting = true
        try {
            yield api.updateFoodOption(token, shop, id, fieldName, selectionRequired, remark, priority, choices.map(c => {
                return {
                    _id: c._id,
                    name: c.name,
                    priority: c.priority,
                    priceAdjustment: c.priceAdjustment,
                    subchoices: c.subchoices.map(s => s._id)
                }
            }))
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listFoodOption = flow(function* (token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listFoodOption(token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax)
            const { options, count } = response.data
            console.log(options)
            this.foodOptions = options
            this.foodOptionCount = count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    deleteFoodOption = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteFoodOption(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createProductSubchoice = flow(function* (token, shop, fieldName, remark, priority, choices) {
        this.isSubmitting = true
        try {
            yield api.createProductSubchoice(token, shop, fieldName, remark, priority, choices.map(c => {
                return c.name
            }))
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateProductSubchoice = flow(function* (token, shop, id, fieldName, remark, priority, choices) {
        console.log(choices)
        this.isSubmitting = true
        try {
            yield api.updateProductSubchoice(token, shop, id, fieldName, remark, priority, choices.map(c => {
                return c.name
            }))
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listProductSubchoice = flow(function* (token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listProductSubchoice(token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax)
            const { subchoices, count } = response.data
            this.productSubchoices = subchoices
            this.productSubchoiceCount = count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    deleteProductSubchoice = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteProductSubchoice(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createFoodSubchoice = flow(function* (token, shop, fieldName, remark, priority, choices) {
        this.isSubmitting = true
        try {
            yield api.createFoodSubchoice(token, shop, fieldName, remark, priority, choices.map(c => {
                return c.name
            }))
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateFoodSubchoice = flow(function* (token, shop, id, fieldName, remark, priority, choices) {
        this.isSubmitting = true
        try {
            yield api.updateFoodSubchoice(token, shop, id, fieldName, remark, priority, choices.map(c => {
                return c.name
            }))
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listFoodSubchoice = flow(function* (token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listFoodSubchoice(token, shop, limit, skip, sort, name, status, createdAtMin, createdAtMax)
            const { subchoices, count } = response.data
            this.foodSubchoices = subchoices
            this.foodSubchoiceCount = count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.isSubmitting = false
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            return Promise.reject()
        }
    })

    deleteFoodSubchoice = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteFoodSubchoice(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new OptionStore()
