import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import CreateFeedForm from '~/components/forms/createFeedForm'

class EditFeedModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, feed } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={intl.formatMessage({ ...messages.editFeed })}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                {

                    visible
                        ? (
                            <CreateFeedForm
                                initial={feed}
                                isSubmitting={isSubmitting}
                                onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                                wrappedComponentRef={(inst) => this.formRef = inst} />
                        )
                        : null
                }
            </Modal>
        )
    }
}

export default injectIntl(EditFeedModal)