import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, message, Input, Tooltip, Icon, Checkbox, Row, DatePicker, Modal, InputNumber, Divider, Popconfirm, Tag } from 'antd'
import { LIMIT } from '~/constants/common'
import { DATE_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import CreateAdsBannerModal from '~/components/createAdsBannerModal'
import EditAdsBannerModal from '~/components/editAdsBannerModal'
import { statusOptions, pageSizeOptions } from '~/constants/options'
import update from 'immutability-helper'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

@inject('commonStore', 'adsBannerStore', 'shopStore') @observer
class MainPageAdvertisementPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createAdsBannerVisible: false,
            editAdsBannerVisible: false,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                status: ['ACTIVE'],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortedInfo: undefined,
            selectedAdsBannerId: undefined,
            previewVisible: false,
            previewImage: undefined
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { adsBannerStore, commonStore, location, shopStore, match } = this.props
        const { token } = commonStore
        await shopStore.getShop(token, match.params._shopId)
        const { currentShop } = shopStore
        const { pageSize, filterValues, sortedInfo } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await adsBannerStore.listAdsBanner(token, currentShop._id, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.name,
            filterValues.status,
            filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
            filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
        )
        this.setState({ currentPage })
    }

    handleOnAdsBannerCreate(values, reset) {
        const { adsBannerStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        adsBannerStore.createAdsBanner(token, currentShop._id, values)
            .then(() => {
                reset()
                this.setState({ createAdsBannerVisible: false })
                message.success(intl.formatMessage({ ...messages.createAdsBannerSuccess }))
                adsBannerStore.listAdsBanner(token, currentShop._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.status,
                    filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                    filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createAdsBannerFailure }))
            })
    }

    handleOnAdsBannerUpdate(values, reset) {
        const { adsBannerStore, commonStore, intl, shopStore } = this.props
        const { selectedAdsBannerId } = this.state
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        const { token } = commonStore
        const { currentShop } = shopStore
        adsBannerStore.updateAdsBanner(token, currentShop._id, selectedAdsBannerId, values)
            .then(() => {
                reset()
                this.setState({ editAdsBannerVisible: false, selectedAdsBannerId: undefined })
                adsBannerStore.listAdsBanner(token, currentShop._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.status,
                    filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                    filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
                )
                message.success(intl.formatMessage({ ...messages.updateAdsBannerSuccess }))
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateAdsBannerFailure }))
            })
    }


    async handleOnAdBannerDelete(record) {
        const { adsBannerStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await adsBannerStore.deleteAdsBanner(token, currentShop._id, record._id)
            message.success(intl.formatMessage({ ...messages.deleteAdsBannerSuccess }))
            await adsBannerStore.listAdsBanner(token, currentShop._id, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.name,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.deleteAdsBannerFailure }))
        }
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { adsBannerStore, commonStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { pageSize } = pagination
        const {
            name,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        adsBannerStore.listAdsBanner(token, currentShop._id, pageSize, pageSize * (page - 1),
            sortField,
            name,
            status,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined
        )
        const href = `/shop/${currentShop._id}/mainPageAdvertisement?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEditClick(record) {
        this.setState({ editAdsBannerVisible: true, selectedAdsBannerId: record._id })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
            case 'sort':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: undefined })
    }

    handlePreview(url) {
        this.setState({
            previewImage: url,
            previewVisible: true,
        })
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'sort':
                return (
                    <InputNumber
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name' || dataIndex === 'sort')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                fixed: 'left',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.photo }),
                dataIndex: 'photos',
                key: 'photos',
                fixed: 'left',
                width: 100,
                render: (text, record) => {
                    return (
                        <a onClick={() => this.handlePreview(record.photo)}>
                            <img src={record.photo} height={50} width={50} style={{ objectFit: 'contain' }} />
                        </a>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.detail }),
                dataIndex: 'detail',
                key: 'detail',
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 200,
                        }
                    }
                },
                render: (text, record) => {
                    return (
                        <Tooltip title={record.detail}>
                            <EllipsisText>{record.detail}</EllipsisText>
                        </Tooltip>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.sort }),
                dataIndex: 'sort',
                key: 'sort',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'sort' && sortedInfo.order,
                // ...this.getColumnSearchProps('sort')
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value===text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            <Divider type='vertical' />
                            <Popconfirm
                                title={intl.formatMessage({ ...messages.deleteReminder })}
                                onConfirm={() => this.handleOnAdBannerDelete(record)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { adsBannerStore, intl } = this.props
        const { currentPage, pageSize, selectedAdsBannerId } = this.state
        var selectedAdsBannerIndex
        if (selectedAdsBannerId) {
            const index = adsBannerStore.adsBanners.findIndex(o => o._id === selectedAdsBannerId)
            selectedAdsBannerIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.mainPageAdvertisement })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type='primary' onClick={() => this.setState({ createAdsBannerVisible: true })}>
                            {intl.formatMessage({ ...messages.createAdsBanner })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(adsBannerStore.adsBanners)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: adsBannerStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        scroll={{ x: 1300 }}
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={adsBannerStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <CreateAdsBannerModal
                    visible={this.state.createAdsBannerVisible}
                    isSubmitting={adsBannerStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnAdsBannerCreate(values, reset)}
                    onClose={() => this.setState({ createAdsBannerVisible: false })} />
                <EditAdsBannerModal
                    adsBanner={toJS(adsBannerStore.adsBanners[selectedAdsBannerIndex])}
                    visible={this.state.editAdsBannerVisible}
                    isSubmitting={adsBannerStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnAdsBannerUpdate(values, reset)}
                    onClose={() => this.setState({ editAdsBannerVisible: false, selectedAdsBannerId: undefined })} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Container>
        )
    }
}

export default withRouter(injectIntl(MainPageAdvertisementPage))