import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/lib/imageCompressor'

class MallBannerStore {
    @observable isSubmitting = false
    @observable error = null
    @observable mallBanners = []
    @observable count = 0
    
    createMallBanner = flow(function* (token, values) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('name', values.name)
        formData.append('detail', values.detail)
        formData.append('sort', values.sort)
        formData.append('status', values.status)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        try {
            yield api.createMallBanner(token, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listMallBanner = flow(function* (token, limit, skip,
        sort,
        name,
        status,
        createdAtMin,
        createdAtMax
    ) {
        this.isSubmitting = true
        try {
            const response = yield api.listMallBanner(token, limit, skip, sort, name, status, createdAtMin, createdAtMax)
            const { mallBanners, count } = response.data
            this.mallBanners = mallBanners
            this.count = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateMallBanner = flow(function* (token, id, values) {
        const formData = new FormData()
        formData.append('name', values.name)
        formData.append('detail', values.detail)
        formData.append('sort', values.sort)
        formData.append('status', values.status)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        this.isSubmitting = true
        try {
            const response = yield api.updateMallBanner(token, id, formData)
            const index = this.mallBanners.findIndex((b) => {
                return b._id===id
            })
            this.mallBanners[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteMallBanner = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            yield api.deleteMallBanner(token, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

}

export default new MallBannerStore()
