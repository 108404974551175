import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, message, Divider, Popconfirm } from 'antd'
import { TIME_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import CreateDeliveryTimeSlotModal from '~/components/createDeliveryTimeSlotModal'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'deliveryTimeSlotStore', 'shopStore') @observer
class DeliveryTimeSlotPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            createDeliveryTimeSlotVisible: false,
            selectedDeliveryTimeSlotId: undefined
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { shopStore, commonStore, deliveryTimeSlotStore, match } = this.props
        const { token } = commonStore
        await shopStore.getShop(token, match.params._shopId)
        const { currentShop } = shopStore
        await deliveryTimeSlotStore.listDeliveryTimeSlot(token, currentShop._id)
    }

    async handleOnDeliveryTimeSlotCreate(values, reset) {
        const { shopStore, commonStore, intl, deliveryTimeSlotStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { selectedDeliveryTimeSlotId } = this.state
        const { name, startTime, endTime } = values
        try {
            if (selectedDeliveryTimeSlotId) {
                await deliveryTimeSlotStore.updateDeliveryTimeSlot(token, currentShop._id, selectedDeliveryTimeSlotId, name, startTime, endTime)
                reset()
                message.success(intl.formatMessage({ ...messages.updateDeliveryTimeSlotSuccess }))
            } else {
                await deliveryTimeSlotStore.createDeliveryTimeSlot(token, currentShop._id, name, startTime, endTime)
                reset()
                message.success(intl.formatMessage({ ...messages.createDeliveryTimeSlotSuccess }))
            }
            this.setState({ createDeliveryTimeSlotVisible: false, selectedDeliveryTimeSlotId: undefined })
            await deliveryTimeSlotStore.listDeliveryTimeSlot(token, currentShop._id)
        } catch (e) {
            if (selectedDeliveryTimeSlotId) {
                message.error(intl.formatMessage({ ...messages.updateDeliveryTimeSlotFailure }))
            } else {
                message.error(intl.formatMessage({ ...messages.createDeliveryTimeSlotFailure }))
            }
        }
    }

    async handleOnDeliveryTimeSlotDelete(record) {
        const { shopStore, commonStore, intl, deliveryTimeSlotStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        try {
            await deliveryTimeSlotStore.deleteDeliveryTimeSlot(token, currentShop._id, record._id)
            message.success(intl.formatMessage({ ...messages.deleteDeliveryTimeSlotSuccess }))
            await deliveryTimeSlotStore.listDeliveryTimeSlot(token, currentShop._id)
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.deleteDeliveryTimeSlotFailure }))
        }
    }

    handleOnEditClick(record) {
        this.setState({ createDeliveryTimeSlotVisible: true, selectedDeliveryTimeSlotId: record._id })
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.startTime }),
                dataIndex: 'startTime',
                key: 'startTime',
            },
            {
                title: intl.formatMessage({ ...messages.endTime }),
                dataIndex: 'endTime',
                key: 'endTime',
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            <Divider type='vertical' />
                            <Popconfirm
                                title={intl.formatMessage({ ...messages.deleteReminder })}
                                onConfirm={() => this.handleOnDeliveryTimeSlotDelete(record)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                },
            }
        ]
    }

    render() {
        const { deliveryTimeSlotStore, intl } = this.props
        const { selectedDeliveryTimeSlotId } = this.state
        var selectedDeliveryTimeSlotIndex
        if (selectedDeliveryTimeSlotId) {
            const index = deliveryTimeSlotStore.deliveryTimeSlots.findIndex(o => o._id === selectedDeliveryTimeSlotId)
            selectedDeliveryTimeSlotIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.deliveryTimeSlot })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type='primary' onClick={() => this.setState({ createDeliveryTimeSlotVisible: true })}>
                            {intl.formatMessage({ ...messages.createDeliveryTimeSlot })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(deliveryTimeSlotStore.deliveryTimeSlots)}
                        loading={deliveryTimeSlotStore.isSubmitting}
                        rowKey={record => record._id}
                        pagination={false}
                    />
                </TableWrapper>
                <CreateDeliveryTimeSlotModal
                    deliveryTimeSlot={selectedDeliveryTimeSlotIndex !== undefined ? toJS(deliveryTimeSlotStore.deliveryTimeSlots[selectedDeliveryTimeSlotIndex]) : undefined}
                    visible={this.state.createDeliveryTimeSlotVisible}
                    isSubmitting={deliveryTimeSlotStore.isSubmitting}
                    error={deliveryTimeSlotStore.error}
                    onSubmit={(values, reset) => this.handleOnDeliveryTimeSlotCreate(values, reset)}
                    onClose={() => this.setState({ createDeliveryTimeSlotVisible: false, selectedDeliveryTimeSlotId: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(DeliveryTimeSlotPage))