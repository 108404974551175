import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, message, Input, Tooltip, Icon, Checkbox, Row, DatePicker, Dropdown, Divider, Carousel, Modal, Tree, Popconfirm, Tag, Menu } from 'antd'
import { LIMIT, OPTION_LIMIT } from '~/constants/common'
import { DATE_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import CreateProductModal from '~/components/createProductModal'
import EditProductModal from '~/components/editProductModal'
import OptionManagementModal from '~/components/optionManagementModal'
import FoodOptionManagementModal from '~/components/foodOptionManagementModal'
import ReservationManagementModal from '~/components/reservationManagementModal'
import CouponManagementModal from '~/components/couponManagementModal'
import CreateCouponModal from '~/components/createCouponModal'
import ProductImportModal from '~/components/productImportModal'
import ImportProgressModal from '~/components/ImportProgressModal'
import UpdateManyProductCategoryModal from '~/components/updateManyProductCategoryModal'
import UpdateManyProductOptionModal from '~/components/updateManyProductOptionModal'
import UpdateManyProductFoodOptionModal from '~/components/updateManyProductFoodOptionModal'
import { statusOptions, pageSizeOptions, productTypeOptions, groupOptions } from '~/constants/options'
import update from 'immutability-helper'
import '~/styles/carousel-dot.css'
import _ from 'lodash'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'productStore', 'categoryStore', 'reservationStore', 'couponStore', 'shopStore', 'optionStore') @observer
class ProductListPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createProductVisible: false,
            editProductVisible: false,
            optionManagementVisible: false,
            foodOptionManagementVisible: false,
            reservationManagementVisible: false,
            couponManagementVisible: false,
            productImportVisible: false,
            createManyCouponVisible: false,
            updateManyProductCategoryVisible: false,
            updateManyProductOptionVisible: false,
            updateManyProductFoodOptionVisible: false,
            pageSize: LIMIT,
            filterValues: {
                productId: undefined,
                name: undefined,
                group: [],
                productType: [],
                isRecommended: [],
                status: ['ACTIVE'],
                category: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            searchText: undefined,
            sortedInfo: undefined,
            selectedProductId: undefined,
            previewVisible: false,
            previewImages: [],
            importProgressVisible: false,
            selectedProducts: [],
            selectedRowKeys: []
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { productStore, categoryStore, commonStore, shopStore, location, match } = this.props
        const { token } = commonStore
        const { pageSize, filterValues, sortedInfo } = this.state
        await shopStore.getShop(token, match.params._shopId)
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const { currentShop } = shopStore
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
            sortField,
            filterValues.searchText,
            filterValues.productId,
            filterValues.name,
            filterValues.isRecommended,
            filterValues.group,
            filterValues.category,
            filterValues.productType,
            filterValues.status,
            filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
            filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
        )
        await categoryStore.listCategory(token, currentShop._id, 0, 0)
        this.setState({ currentPage })
    }

    handleOnProductCreate(values, reset) {
        const { productStore, commonStore, intl, shopStore } = this.props
        const { currentShop } = shopStore
        const { token } = commonStore
        const { currentPage, pageSize, searchText, sortedInfo } = this.state
        const {
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        productStore.createProduct(token, currentShop._id, values)
            .then(() => {
                reset()
                this.setState({ createProductVisible: false })
                message.success(intl.formatMessage({ ...messages.createProductSuccess }))
                productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
                    sortField,
                    searchText,
                    productId,
                    name,
                    isRecommended,
                    group,
                    category,
                    productType,
                    status,
                    createdAtMin ? createdAtMin.format() : undefined,
                    createdAtMax ? createdAtMax.format() : undefined
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createProductFailure }))
            })
    }

    handleOnProductUpdate(values, reset) {
        const { productStore, commonStore, intl, shopStore } = this.props
        const { currentShop } = shopStore
        const { selectedProductId, currentPage, pageSize, sortedInfo, searchText } = this.state
        const { token } = commonStore
        const {
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        productStore.updateProduct(token, currentShop._id, selectedProductId, values)
            .then(() => {
                reset()
                this.setState({ editProductVisible: false, selectedProductId: undefined })
                message.success(intl.formatMessage({ ...messages.updateProductSuccess }))
                productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
                    sortField,
                    searchText,
                    productId,
                    name,
                    isRecommended,
                    group,
                    category,
                    productType,
                    status,
                    createdAtMin ? createdAtMin.format() : undefined,
                    createdAtMax ? createdAtMax.format() : undefined
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateProductFailure }))
            })
    }

    handleOnCreateManyCouponClick() {
        this.setState({ createManyCouponVisible: true })
    }

    handleUpdateManyProductCategory() {
        this.setState({ updateManyProductCategoryVisible: true })
    }

    async handleUpdateManyOption() {
        const { optionStore, commonStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        await optionStore.listOption(token, currentShop._id, OPTION_LIMIT, 0)
        this.setState({ updateManyProductOptionVisible: true })
    }

    async handleUpdateManyFoodOption() {
        const { optionStore, commonStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        await optionStore.listFoodOption(token, currentShop._id, OPTION_LIMIT, 0)
        this.setState({ updateManyProductFoodOptionVisible: true })
    }

    async handleOnManyCouponCreate(values, reset) {
        const { couponStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        try {
            await couponStore.createManyCoupon(token, currentShop._id, this.state.selectedProducts.map(p => p._id), values)
            reset()
            this.setState({ createManyCouponVisible: false })
            message.success(intl.formatMessage({ ...messages.createCouponSuccess }))
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.createCouponFailure }))
        }
    }

    async handleOnManyProductCategoryUpdate(values, reset) {
        const { categoryStore, productStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, sortedInfo, searchText } = this.state
        const {
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await categoryStore.updateManyProductCategory(token, currentShop._id, values.category[values.category.length - 1], this.state.selectedProducts.map(p => p._id))
            reset()
            this.setState({ updateManyProductCategoryVisible: false })
            message.success(intl.formatMessage({ ...messages.updateManyProductCategorySuccess }))
            productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
                sortField,
                searchText,
                productId,
                name,
                isRecommended,
                group,
                category,
                productType,
                status,
                createdAtMin ? createdAtMin.format() : undefined,
                createdAtMax ? createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.updateManyProductCategoryFailure }))
        }
    }

    async handleOnManyProductOptionUpdate(values, reset) {
        const { productStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, sortedInfo, searchText } = this.state
        const {
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await productStore.groupUpdateProductOption(token, currentShop._id, this.state.selectedProducts.map(p => p._id), values.options.map(p => p._id))
            reset()
            this.setState({ updateManyProductOptionVisible: false })
            message.success(intl.formatMessage({ ...messages.updateManyProductOptionSuccess }))
            productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
                sortField,
                searchText,
                productId,
                name,
                isRecommended,
                group,
                category,
                productType,
                status,
                createdAtMin ? createdAtMin.format() : undefined,
                createdAtMax ? createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.updateManyProductOptionFailure }))
        }
    }

    async handleOnManyProductFoodOptionUpdate(values, reset) {
        const { productStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, sortedInfo, searchText } = this.state
        const {
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await productStore.groupUpdateProductFoodOption(token, currentShop._id, this.state.selectedProducts.map(p => p._id), values.options.map(p => p._id))
            reset()
            this.setState({ updateManyProductFoodOptionVisible: false })
            message.success(intl.formatMessage({ ...messages.updateManyProductOptionSuccess }))
            productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
                sortField,
                searchText,
                productId,
                name,
                isRecommended,
                group,
                category,
                productType,
                status,
                createdAtMin ? createdAtMin.format() : undefined,
                createdAtMax ? createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.updateManyProductOptionFailure }))
        }
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { pageSize } = pagination
        const { productStore, commonStore, shopStore } = this.props
        const { currentShop } = shopStore
        const { token } = commonStore
        const { searchText } = this.state
        const {
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        productStore.listProduct(token, currentShop._id, pageSize, pageSize * (page - 1), currentShop.type,
            sortField,
            searchText,
            productId,
            name,
            isRecommended,
            group,
            category,
            productType,
            status,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined
        )
        const href = `/shop/${currentShop._id}/productList?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEditClick(record) {
        const { productStore, commonStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        await productStore.getProduct(token, currentShop._id, record._id)
        this.setState({ editProductVisible: true, selectedProductId: record._id })
    }

    async handleOnProductDelete(record) {
        const { productStore, commonStore, shopStore, intl } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await productStore.deleteProduct(token, currentShop._id, record._id)
            message.success(intl.formatMessage({ ...messages.deleteProductSuccess }))
            await productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
                sortField,
                filterValues.searchText,
                filterValues.productId,
                filterValues.name,
                filterValues.isRecommended,
                filterValues.group,
                filterValues.category,
                filterValues.productType,
                filterValues.status,
                filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.deleteProductFailure }))
        }
    }

    async handleOnOptionManagementClick(record) {
        const { productStore, commonStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        if (shopStore.currentShop.type === 'RESTAURANT_SHOPPER') {
            await productStore.listProductFoodOption(token, currentShop._id, record._id)
            this.setState({ foodOptionManagementVisible: true, selectedProductId: record._id })
        } else {
            await productStore.listProductOption(token, currentShop._id, record._id)
            this.setState({ optionManagementVisible: true, selectedProductId: record._id })
        }
    }

    async handleOnReservationManagementClick(record) {
        const { commonStore, reservationStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        await reservationStore.listReservation(token, currentShop._id, record._id, LIMIT, 0,
            undefined,
            undefined,
            undefined,
            ['ACTIVE'])
        this.setState({ reservationManagementVisible: true, selectedProductId: record._id })
    }

    async handleOnCouponListClick(record) {
        const { commonStore, couponStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        await couponStore.listProductCoupon(token, currentShop._id, record._id, LIMIT, 0)
        this.setState({ couponManagementVisible: true, selectedProductId: record._id })
    }

    handleOnRowSelect(selectedRowKeys, selectedRows) {
        const { productStore } = this.props
        const products = toJS(productStore.products)
        this.setState({ selectedRowKeys: selectedRowKeys })
        const filteredProducts = _.unionBy(this.state.selectedProducts, products, '_id').filter(p => {
            return selectedRowKeys.includes(p._id)
        })
        this.setState({ selectedProducts: filteredProducts })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleOnSearchButtonClick(value) {
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
            case 'productId':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'group':
            case 'productType':
            case 'isRecommended':
            case 'status':
            case 'category':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                productId: undefined,
                name: undefined,
                group: [],
                productType: [],
                isRecommended: [],
                status: [],
                category: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            searchText: undefined,
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImages: [] })
    }

    handlePreview(imageUriArray) {
        this.setState({
            previewImages: imageUriArray,
            previewVisible: true,
        })
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
            case 'productId':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                searchText: undefined,
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'group':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {groupOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'productType':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {productTypeOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'isRecommended':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        <Row><Checkbox value={true}>{this.props.intl.formatMessage({ ...messages.yes })}</Checkbox></Row>
                        <Row><Checkbox value={false}>{this.props.intl.formatMessage({ ...messages.no })}</Checkbox></Row>
                    </Checkbox.Group>
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )

            case 'category':
                const renderTreeNodes = (data, level) => {
                    const newLevel = level + 1
                    return data.map(item => {
                        return (
                            <Tree.TreeNode
                                title={item.name}
                                key={item._id}
                                dataRef={item}
                                selectable={false}>
                                {item.subcategories ? renderTreeNodes(item.subcategories, newLevel) : null}
                            </Tree.TreeNode>
                        )
                    })
                }
                return (
                    <Tree
                        checkable
                        checkedKeys={this.state.filterValues[dataIndex]}
                        onCheck={(checkedKeys) => this.hanldeOnCategoryCheck(checkedKeys)}>
                        {renderTreeNodes(toJS(this.props.categoryStore.categories), 0)}
                    </Tree>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name' || dataIndex === 'productId')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    hanldeOnCategoryCheck(selectedKeys) {
        const filterValues = update(this.state.filterValues, { category: { $set: selectedKeys } })
        this.setState({ filterValues })
    }

    validateProductData(products, photos) {
        const { intl, categoryStore, shopStore } = this.props
        const { currentShop } = shopStore
        const activeCategories = toJS(categoryStore.rawCategories)
        const errors = []
        // if (products.length === photos.length) {
        var index = 0
        for (var p of products) {
            const { name, categoryName, productId, introduction, description, logisticDescription, isRecommended, group, stock, buyPrice, sellPrice, priority } = p
            if (!name) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.name }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!categoryName) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.category }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            } else {
                const categoryIndex = activeCategories.findIndex(c => c.name === categoryName)
                if (categoryIndex < 0) {
                    errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.category }), message: intl.formatMessage({ ...messages.addCategoryErrorReminder }, { category: categoryName }) })
                } else {
                    p.category = activeCategories[categoryIndex]._id
                }
            }
            if (productId === undefined) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.productId }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            } else {
                const matchedPhotos = photos.filter(o => {
                    const fileName = o.name.split('.')[0].toLowerCase()
                    const imageName = fileName.split('^')[0]
                    return imageName === productId.toString().toLowerCase()
                })
                p.photos = matchedPhotos
                if (matchedPhotos.length <= 10) {
                    p.photos = matchedPhotos
                }
                if (matchedPhotos.length > 10) {
                    errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.productId }), message: intl.formatMessage({ ...messages.tenPhotosPerProductAtMost }) })
                }
                if (currentShop.type === 'RETAIL' && matchedPhotos.length === 0) {
                    if (matchedPhotos.length > 10) {
                        errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.productId }), message: intl.formatMessage({ ...messages.productImageNotFound }) })
                    }
                }
            }
            if (!introduction) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.introduction }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!description) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.description }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!logisticDescription) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.logisticDescription }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!isRecommended) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.isRecommended }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (!group) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.group }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            } else {
                const groupIndex = groupOptions.findIndex(g => g.text.defaultMessage === group)
                if (groupIndex < 0) {
                    errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.group }), message: intl.formatMessage({ ...messages.groupErrorReminder }, { group: group }) })
                } else {
                    p.group = groupOptions[groupIndex].value
                }
            }
            if (stock === undefined) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.stock }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (buyPrice === undefined) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.buyPrice }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (sellPrice === undefined || (Number(sellPrice) > 0 && Number(sellPrice) < 5)) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.sellPrice }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            if (priority === undefined) {
                errors.push({ row: index + 2, field: intl.formatMessage({ ...messages.priority }), message: intl.formatMessage({ ...messages.pleaseInput }) })
            }
            
            index++
            if (errors.length > 0) {
                break
            }
        }
        // } else {
        //     errors.push({ message: intl.formatMessage({ ...messages.productAndImageCountNotMatch }) })
        // }
        return { products, errors }
    }

    async handleOnImport(values, reset, errorCallback) {
        const { intl, productStore, commonStore, shopStore } = this.props
        const { currentShop } = shopStore
        const { token } = commonStore
        const { file, photos } = values
        const header = ['name', 'categoryName', 'productId', 'introduction', 'description', 'logisticDescription', 'isRecommended', 'group', 'stock', 'buyPrice', 'sellPrice', 'priority']
        import('~/lib/xlsxHelper').then(async xlsxHelper => {
            let productList = await xlsxHelper.importFile(file[0].originFileObj, header)
            productList.shift()
            const { products, errors } = this.validateProductData(productList, photos)
            if (errors.length === 0) {
                this.setState({ importProgressVisible: true })
                try {
                    await productStore.importProduct(token, currentShop._id, products)
                    message.success(intl.formatMessage({ ...messages.importProductSuccess }))
                    this.setState({ productImportVisible: false })
                    reset()
                    const { currentPage, pageSize, sortField, searchText } = this.state
                    const {
                        productId,
                        name,
                        isRecommended,
                        group,
                        category,
                        productType,
                        status,
                        createdAtMin,
                        createdAtMax
                    } = this.state.filterValues
                    await productStore.listProduct(token, currentShop._id, pageSize, pageSize * (currentPage - 1), currentShop.type,
                        sortField,
                        searchText,
                        productId,
                        name,
                        isRecommended,
                        group,
                        category,
                        productType,
                        status,
                        createdAtMin ? createdAtMin.format() : undefined,
                        createdAtMax ? createdAtMax.format() : undefined
                    )
                }
                catch (e) {
                    message.error(intl.formatMessage({ ...messages.importProductFailure }))
                }
            } else {
                errorCallback(errors)
            }
        })
    }

    handleOnExportClick() {
        const { intl, shopStore } = this.props
        const { selectedProducts } = this.state
        if (selectedProducts.length > 0) {
            const sheetHeader = [
                `${intl.formatMessage({ ...messages.name })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.category })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.productId })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.introduction })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.description })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.logisticDescription })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.isRecommended })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.group })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.stock })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.buyPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.sellPrice })} (${intl.formatMessage({ ...messages.mustInput })})`,
                `${intl.formatMessage({ ...messages.priority })} (${intl.formatMessage({ ...messages.mustInput })})`,
            ]

            const data = []
            toJS(selectedProducts).forEach(p => {
                console.log(p)
                const groupIndex = groupOptions.findIndex(g => g.value === p.group)
                const productIdIndex = p.productId.indexOf(shopStore.currentShop.initial)
                return data.push([
                    p.name,
                    p.category.name,
                    productIdIndex > -1 ? p.productId.substr(shopStore.currentShop.initial.length) : p.productId,
                    p.introduction,
                    p.description,
                    p.logisticDescription,
                    p.isRecommended ? 'Y' : 'N',
                    groupIndex > -1 ? intl.formatMessage({ ...groupOptions[groupIndex].text }) : '',
                    p.stock,
                    p.cost,
                    p.price,
                    p.priority
                ])
            })
            import('~/lib/xlsxHelper').then(xlsxHelper => {
                xlsxHelper.exportData(sheetHeader, data, 'shopper_product.xlsx')
            })
        } else {
            message.error(intl.formatMessage({ ...messages.exportProductReminder }))
        }
    }

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.productId }),
                dataIndex: 'productId',
                key: 'productId',
                fixed: 'left',
                width: 150,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'productId' && sortedInfo.order,
                ...this.getColumnSearchProps('productId')
            },
            {
                title: intl.formatMessage({ ...messages.photo }),
                dataIndex: 'photos',
                key: 'photos',
                width: 100,
                render: (text, record) => {
                    const thumbnail = record.photos[0] ? record.photos[0].path : null
                    return (
                        <ImageWrapper onClick={() => this.handlePreview(record.photos.map(p => p.path))}>
                            <img src={thumbnail} height={50} width={50} style={{ objectFit: 'contain' }} />
                            <div>{intl.formatMessage({ ...messages.photoCount }, { count: record.photos.length })}</div>
                        </ImageWrapper>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.category }),
                dataIndex: 'category',
                key: 'category',
                width: 120,
                render: (text, record) => {
                    return record.category.name
                },
                ...this.getColumnSearchProps('category')
            },
            {
                title: intl.formatMessage({ ...messages.sellPrice }),
                dataIndex: 'price',
                key: 'price',
            },
            {
                title: intl.formatMessage({ ...messages.priority }),
                dataIndex: 'priority',
                key: 'priority',
            },
            {
                title: intl.formatMessage({ ...messages.productType }),
                dataIndex: 'productType',
                key: 'productType',
                width: 120,
                render: (text, record) => {
                    const index = productTypeOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...productTypeOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('productType')
            },
            {
                title: intl.formatMessage({ ...messages.group }),
                dataIndex: 'group',
                key: 'group',
                width: 120,
                render: (text, record) => {
                    const index = groupOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...groupOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('group')
            },
            {
                title: intl.formatMessage({ ...messages.introduction }),
                dataIndex: 'introduction',
                key: 'introduction',
                width: 200,
            },
            {
                title: intl.formatMessage({ ...messages.description }),
                dataIndex: 'description',
                key: 'description',
                width: 200,
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 200,
                        }
                    }
                },
                render: (text, record) => {
                    var tmp = document.createElement("DIV")
                    tmp.innerHTML = record.description
                    return (
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: record.description }}></div>}>
                            <EllipsisText>{tmp.textContent || tmp.innerText}</EllipsisText>
                        </Tooltip>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.logisticDescription }),
                dataIndex: 'logisticDescription',
                key: 'logisticDescription',
                width: 200,
                onCell: () => {
                    return {
                        style: {
                            whiteSpace: 'nowrap',
                            maxWidth: 200,
                        }
                    }
                },
                render: (text, record) => {
                    var tmp = document.createElement("DIV")
                    tmp.innerHTML = record.logisticDescription
                    return (
                        <Tooltip title={<div dangerouslySetInnerHTML={{ __html: record.logisticDescription }}></div>}>
                            <EllipsisText>{tmp.textContent || tmp.innerText}</EllipsisText>
                        </Tooltip>
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.recommend }),
                dataIndex: 'isRecommended',
                key: 'isRecommended',
                width: 100,
                render: (text, record) => {
                    if (record.isRecommended) {
                        return intl.formatMessage({ ...messages.yes })
                    } else {
                        return intl.formatMessage({ ...messages.no })
                    }
                },
                ...this.getColumnSearchProps('isRecommended')
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 120,
                render: (text, record) => {
                    return (
                        <span>
                            <Dropdown overlay={
                                <Menu>
                                    {
                                        record.productType === 'ORDER'
                                            ? (
                                                <Menu.Item>
                                                    <a onClick={() => this.handleOnOptionManagementClick(record)}>
                                                        {intl.formatMessage({ ...messages.optionManagement })}
                                                    </a>
                                                </Menu.Item>
                                            )
                                            : null
                                    }
                                    {
                                        record.productType === 'RESERVATION'
                                            ? (
                                                <Menu.Item>
                                                    <a onClick={() => this.handleOnReservationManagementClick(record)}>
                                                        {intl.formatMessage({ ...messages.reservationManagement })}
                                                    </a>
                                                </Menu.Item>
                                            )
                                            : null
                                    }
                                    <Menu.Item>
                                        <a onClick={() => this.handleOnCouponListClick(record)}>
                                            {intl.formatMessage({ ...messages.couponList })}
                                        </a>
                                    </Menu.Item>
                                    <Menu.Item>
                                        <a onClick={() => this.handleOnEditClick(record)}>
                                            {intl.formatMessage({ ...messages.edit })}
                                        </a>
                                    </Menu.Item>
                                </Menu>
                            }>
                                <a>
                                    {intl.formatMessage({ ...messages.other })} <Icon type="down" />
                                </a>
                            </Dropdown>
                            <Divider type='vertical' />
                            <Popconfirm
                                title={intl.formatMessage({ ...messages.deleteReminder })}
                                onConfirm={() => this.handleOnProductDelete(record)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl, categoryStore } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'productId':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.productId })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'group':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = groupOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.group })}: ${text}`}</Tag>)
                        }
                        break
                    case 'productType':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = productTypeOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.productType })}: ${text}`}</Tag>)
                        }
                        break
                    case 'isRecommended':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            var result = []
                            if (value.includes(true)) {
                                result.push(intl.formatMessage({ ...messages.yes }))
                            }
                            if (value.includes(false)) {
                                result.push(intl.formatMessage({ ...messages.no }))
                            }
                            const text = result.join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.isRecommended })}: ${text}`}</Tag>)
                        }
                        break
                    case 'category':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            console.log(value)
                            const filteredOptions = toJS(categoryStore.rawCategories).filter(c => { return value.includes(c._id) })
                            const text = filteredOptions.map(o => {
                                return o.name
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.category })}: ${text}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { productStore, intl, categoryStore, reservationStore, couponStore, shopStore } = this.props
        const { currentPage, pageSize, selectedProductId } = this.state
        var selectedProductIndex
        if (selectedProductId) {
            const index = productStore.products.findIndex(o => o._id === selectedProductId)
            selectedProductIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.productManagement })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type='primary' onClick={() => this.setState({ createProductVisible: true })}>
                            {intl.formatMessage({ ...messages.createProduct })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                        <Button type='primary' onClick={() => this.setState({ productImportVisible: true })}>
                            {intl.formatMessage({ ...messages.import })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleOnExportClick()} disabled={this.state.selectedRowKeys.length < 1}>
                            {intl.formatMessage({ ...messages.export })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleOnCreateManyCouponClick()} disabled={this.state.selectedRowKeys.length < 1}>
                            {intl.formatMessage({ ...messages.createManyCoupons })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleUpdateManyProductCategory()} disabled={this.state.selectedRowKeys.length < 1}>
                            {intl.formatMessage({ ...messages.updateManyProductCategory })}
                        </Button>
                        {
                            shopStore.currentShop && shopStore.currentShop.type === 'RETAIL'
                                ? (
                                    <Button type="primary" onClick={() => this.handleUpdateManyOption()} disabled={this.state.selectedRowKeys.length < 1}>
                                        {intl.formatMessage({ ...messages.updateManyProductOption })}
                                    </Button>
                                )
                                : null
                        }
                        {
                            shopStore.currentShop && shopStore.currentShop.type === 'RESTAURANT_SHOPPER'
                                ? (
                                    <Button type="primary" onClick={() => this.handleUpdateManyFoodOption()} disabled={this.state.selectedRowKeys.length < 1}>
                                        {intl.formatMessage({ ...messages.updateManyProductOption })}
                                    </Button>
                                )
                                : null
                        }
                    </ActionLeftWrapper>
                    <Input.Search
                        placeholder={intl.formatMessage({ ...messages.productIdOrName })}
                        value={this.state.searchText}
                        onChange={e => {
                            this.setState({
                                searchText: e.target.value,
                                filterValues: update(this.state.filterValues, { 'name': { $set: undefined }, 'productId': { $set: undefined } })
                            })
                        }}
                        onSearch={value => this.handleOnSearchButtonClick(value)}
                        enterButton
                        allowClear
                        style={{ width: 200 }}
                    />
                </ActionWrapper>
                {
                    this.state.selectedRowKeys.length > 0
                        ? <ActionWrapper><div>{intl.formatMessage({ ...messages.selectedCount }, { count: this.state.selectedRowKeys.length })}</div></ActionWrapper>
                        : null
                }
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(productStore.products)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: productStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        scroll={{ x: 2200 }}
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={productStore.isSubmitting}
                        rowKey={record => record._id}
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: this.state.selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => this.handleOnRowSelect(selectedRowKeys, selectedRows)
                        }}
                    />
                </TableWrapper>
                <CreateProductModal
                    visible={this.state.createProductVisible}
                    isSubmitting={productStore.isSubmitting}
                    categories={categoryStore.categories}
                    error={productStore.error}
                    shop={shopStore.currentShop}
                    onSubmit={(values, reset) => this.handleOnProductCreate(values, reset)}
                    onClose={() => this.setState({ createProductVisible: false })} />
                <EditProductModal
                    product={selectedProductIndex > -1 ? toJS(productStore.products[selectedProductIndex]) : undefined}
                    visible={this.state.editProductVisible}
                    isSubmitting={productStore.isSubmitting}
                    categories={toJS(categoryStore.categories)}
                    error={productStore.error}
                    shop={shopStore.currentShop}
                    onSubmit={(values, reset) => this.handleOnProductUpdate(values, reset)}
                    onClose={() => this.setState({ editProductVisible: false, selectedProductId: undefined })} />
                <OptionManagementModal
                    product={selectedProductIndex > -1 ? toJS(productStore.products[selectedProductIndex]) : undefined}
                    visible={this.state.optionManagementVisible}
                    isSubmitting={productStore.isSubmitting}
                    onClose={() => this.setState({ optionManagementVisible: false, selectedProductId: undefined })} />
                <FoodOptionManagementModal
                    product={selectedProductIndex > -1 ? toJS(productStore.products[selectedProductIndex]) : undefined}
                    visible={this.state.foodOptionManagementVisible}
                    isSubmitting={productStore.isSubmitting}
                    onClose={() => this.setState({ foodOptionManagementVisible: false, selectedProductId: undefined })} />
                <ReservationManagementModal
                    product={selectedProductIndex > -1 ? toJS(productStore.products[selectedProductIndex]) : undefined}
                    visible={this.state.reservationManagementVisible}
                    isSubmitting={reservationStore.isSubmitting}
                    onClose={() => this.setState({ reservationManagementVisible: false, selectedProductId: undefined })} />
                <CouponManagementModal
                    product={selectedProductIndex > -1 ? toJS(productStore.products[selectedProductIndex]) : undefined}
                    visible={this.state.couponManagementVisible}
                    isSubmitting={couponStore.isSubmitting}
                    onClose={() => this.setState({ couponManagementVisible: false, selectedProductId: undefined })} />
                <ProductImportModal
                    visible={this.state.productImportVisible}
                    isSubmitting={productStore.isSubmitting}
                    onSubmit={(values, reset, error) => this.handleOnImport(values, reset, error)}
                    onClose={() => this.setState({ productImportVisible: false })} />
                <ImportProgressModal
                    visible={this.state.importProgressVisible}
                    importProductTotalCount={productStore.importProductTotalCount}
                    importedProductCount={productStore.importedProductCount}
                    isSubmitting={productStore.isSubmitting}
                    onClose={() => this.setState({ importProgressVisible: false })} />
                <CreateCouponModal
                    visible={this.state.createManyCouponVisible}
                    isSubmitting={couponStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnManyCouponCreate(values, reset)}
                    onClose={() => this.setState({ createManyCouponVisible: false })} />
                <UpdateManyProductCategoryModal
                    visible={this.state.updateManyProductCategoryVisible}
                    isSubmitting={productStore.isSubmitting}
                    categories={categoryStore.categories}
                    onSubmit={(values, reset) => this.handleOnManyProductCategoryUpdate(values, reset)}
                    onClose={() => this.setState({ updateManyProductCategoryVisible: false })} />
                <UpdateManyProductOptionModal
                    visible={this.state.updateManyProductOptionVisible}
                    isSubmitting={productStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnManyProductOptionUpdate(values, reset)}
                    onClose={() => this.setState({ updateManyProductOptionVisible: false })} />
                <UpdateManyProductFoodOptionModal
                    visible={this.state.updateManyProductFoodOptionVisible}
                    isSubmitting={productStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnManyProductFoodOptionUpdate(values, reset)}
                    onClose={() => this.setState({ updateManyProductFoodOptionVisible: false })} />
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <Carousel>
                        {
                            this.state.previewImages.map((uri, index) => {
                                return (
                                    <div key={index}>
                                        <img alt="example" style={{ width: '100%' }} src={uri} />
                                    </div>
                                )
                            })
                        }
                    </Carousel>
                </Modal>
            </Container>
        )
    }
}

export default withRouter(injectIntl(ProductListPage))