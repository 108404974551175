
import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Modal, Button, DatePicker } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { DATE_ONLY_FORMAT } from '~/constants/format'
import moment from 'moment'
import pdfMake from "pdfmake/build/pdfmake"
import fontLoader from '~/lib/fontLoader'

const TRANSACTION_FEE_PERCENTAGE = process.env.REACT_APP_TRANSACTION_FEE_PERCENTAGE
const RETAIL_SHOPPER_LOGISTICS_FEE_PERCENTAGE = process.env.REACT_APP_RETAIL_SHOPPER_LOGISTICS_FEE_PERCENTAGE
const RETAIL_CUSTOM_LOGISTICS_FEE_PERCENTAGE = process.env.REACT_APP_RETAIL_CUSTOM_LOGISTICS_FEE_PERCENTAGE

@inject('commonStore', 'analysisStore') @observer
class RetailReportModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMakingPDF: false,
            range: [moment(), moment()]
        }
    }


    getPdf(callback) {
        const { shop, intl, analysisStore } = this.props
        const { range } = this.state
        const { retailReport } = analysisStore
        var img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = function () {
            var canvas = document.createElement('CANVAS')
            const ctx = canvas.getContext('2d')
            canvas.height = img.height
            canvas.width = img.width
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            const { platformFee, transactionFee, totalRevenue, totalCount, subtotal, dates, couponDiscount, refundTotal } = toJS(retailReport)
            const tableData = dates.map(({ _id, dailyTotalAmount, dailyOrderCount }, index) => {
                return [
                    moment(_id).format(DATE_ONLY_FORMAT),
                    { text: `${dailyOrderCount}` },
                    { text: `HK$${dailyTotalAmount}` }
                ]
            })

            const definition = {
                content: [
                    { image: dataURL, width: 50, height: 50, alignment: 'center' },
                    { text: 'Shopper', style: 'header', alignment: 'center' },
                    { text: intl.formatMessage({ ...messages.onlineSalesReport }), style: 'header', alignment: 'center' },
                    { text: ' ' },
                    { text: intl.formatMessage({ ...messages.to }) },
                    { text: `${shop.name}` },
                    { text: ' ' },
                    { text: `${intl.formatMessage({ ...messages.date })}: ${range[0].startOf('day').format(DATE_ONLY_FORMAT)} - ${range[1].endOf('day').format(DATE_ONLY_FORMAT)}` },
                    { text: `${intl.formatMessage({ ...messages.currency })}: ${intl.formatMessage({ ...messages.hkd })}` },
                    { text: `${intl.formatMessage({ ...messages.orderCount })}: ${totalCount}` },
                    { text: ' ' },
                    {
                        table: {
                            headerRows: 1,
                            widths: ['*', 100, 100],
                            body: [
                                [
                                    { text: intl.formatMessage({ ...messages.date }) },
                                    { text: intl.formatMessage({ ...messages.orderCount }) },
                                    { text: intl.formatMessage({ ...messages.totalAmount }) }
                                ],
                                ...tableData,
                                [
                                    { text: `${intl.formatMessage({ ...messages.subtotal })}:`, alignment: 'right' },
                                    `${totalCount}`,
                                    `HK$${subtotal}`
                                ]
                            ]
                        },
                    },
                    { text: ' ' },
                    {
                        table: {
                            headerRows: 1,
                            widths: ['*', 200],
                            body: [
                                [
                                    { text: intl.formatMessage({ ...messages.subtotal }), alignment: 'right' },
                                    `HK$${subtotal}`,
                                ],
                                [
                                    {
                                        text: `${intl.formatMessage({ ...messages.transactionFee })}(${TRANSACTION_FEE_PERCENTAGE}%)`,
                                        alignment: 'right'
                                    },
                                    { text: `HK$${transactionFee}`, color: 'red' },
                                ],
                                [
                                    {
                                        text: `${intl.formatMessage({ ...messages.shopperPlatformFee })}(${shop.isCustomLogistics ? RETAIL_CUSTOM_LOGISTICS_FEE_PERCENTAGE : RETAIL_SHOPPER_LOGISTICS_FEE_PERCENTAGE}%)`,
                                        alignment: 'right'
                                    },
                                    { text: `HK$${platformFee}`, color: 'red' },
                                ],
                                [
                                    {
                                        text: intl.formatMessage({ ...messages.amountReceived }),
                                        alignment: 'right'
                                    },
                                    { text: `HK$${totalRevenue}`, color: 'green' },
                                ]
                            ]
                        }
                    },
                    { text: ' ' },
                    { text: `${intl.formatMessage({ ...messages.refundAmount })}: $${refundTotal}`, color: 'red' },
                    { text: `${intl.formatMessage({ ...messages.couponDiscount })}: $${couponDiscount}`, color: 'red' },
                    { text: `${intl.formatMessage({ ...messages.amountReceivedAfterRefund })}: $${totalRevenue - refundTotal - couponDiscount}`, color: 'green' },
                ],
                defaultStyle: {
                    font: 'msyh'
                }
            }

            fontLoader.load()
                .then(() => {
                    const doc = pdfMake.createPdf(definition)
                    callback(doc)

                    canvas = null
                })
        }
        img.src = `${process.env.PUBLIC_URL}/favicon.ico`
    }

    async handleOnGetClick() {
        const { analysisStore, commonStore, shop } = this.props
        const { token } = commonStore
        const { range } = this.state
        const start = range[0].startOf('day').format()
        const end = range[1].endOf('day').format()
        await analysisStore.getRetailReport(token, shop._id, start, end)
        this.setState({ isMakingPDF: true })
        this.getPdf((doc) => {
            doc.download(`${shop.name}_report_${range[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${range[1].endOf('day').format(DATE_ONLY_FORMAT)}.pdf`)
            this.setState({ isMakingPDF: false })
        })
    }

    render() {
        const { intl, visible, isSubmitting, shop } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.retailReport })}
                onCancel={() => this.props.onClose()}
                footer={[
                    <Button onClick={() => this.props.onClose()}>{intl.formatMessage({ ...messages.cancel })}</Button>
                ]}>
                <DatePicker.RangePicker
                    allowClear={false}
                    value={this.state.range}
                    onChange={(dates) => this.setState({ range: dates })}
                    disabledDate={(current) => {
                        return current && current > moment().endOf('day')
                    }} />
                <Button style={{ marginLeft: 16 }} loading={this.state.isMakingPDF} onClick={() => this.handleOnGetClick()}>{intl.formatMessage({ ...messages.downloadReport })}</Button>
            </Modal>
        )
    }
}

export default injectIntl(RetailReportModal)