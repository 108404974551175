import React, { Component } from 'react'
import { Modal, Progress, Button } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'

class DownloadProgressModal extends Component {

    render() {
        const { intl, visible, isSubmitting, totalCount, downloadedCount } = this.props
        return (
            <Modal
                visible={visible}
                closable={false}
                mask
                maskClosable={false}
                centered
                width={960}
                title={downloadedCount === totalCount ? intl.formatMessage({ ...messages.downloaded }) : intl.formatMessage({ ...messages.downloading })}
                // okText={intl.formatMessage({ ...messages.confirm })}
                // cancelText={intl.formatMessage({ ...messages.cancel })}
                onCancel={() => {
                    this.props.onClose()
                }}
                footer={[
                    <Button key="submit" type="primary" disabled={downloadedCount !== totalCount} onClick={() => this.props.onClose()}>
                        { intl.formatMessage({...messages.confirm}) }
                    </Button>,
                ]}>
                <Progress percent={Math.round(downloadedCount / totalCount * 100)} />
                <div>{intl.formatMessage({ ...messages.downloadedWithCountAndTotal }, { count: downloadedCount, total: totalCount })}</div>
            </Modal>
        )
    }
}

export default injectIntl(DownloadProgressModal)