import React, { Component } from 'react'
import styled from 'styled-components'
import { inject, observer } from 'mobx-react'
import { Dropdown, Button, Icon, Menu, Avatar } from 'antd'
import { withRouter } from 'react-router-dom'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import UserDetailModal from '~/components/userDetailModal'
import ShopSelectModal from '~/components/shopSelectModal'

const AppBar = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    box-shadow: 1px 1px 1px #888888;
    background: #fff;
    height: 64px;
`

const AppBarLeftSideWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    flex-grow: 1;
`

const AppBarRightSideWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const ShopName = styled.div`
    margin: 0 16px;
`

const Username = styled.div`
    cursor: pointer;
`

@inject('commonStore', 'shopStore') @observer
class ApplicationBar extends Component {

    constructor(props) {
        super(props)
        this.state = {
            userDetailVisible: false,
            shopSelectVisible: false
        }
    }

    handleOnUserInformationClick() {
        this.setState({ userDetailVisible: true })
    }

    handleOnLogoutClick() {
        this.props.onLogout()
    }

    renderMenu() {
        const { intl } = this.props
        return (
            <Menu>
                <Menu.Item onClick={() => this.handleOnUserInformationClick()}>
                    <a>{intl.formatMessage({ ...messages.userInformation })}</a>
                </Menu.Item>
                <Menu.Item onClick={() => this.handleOnLogoutClick()}>
                    <a>{intl.formatMessage({ ...messages.logout })}</a>
                </Menu.Item>
            </Menu>
        )
    }

    render() {
        const { intl, username, isSiderCollapsed, shopStore } = this.props

        return (
            <AppBar>
                <AppBarLeftSideWrapper>
                    <Icon
                        type={isSiderCollapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={() => this.props.onMenuFoldClick()}
                        style={{ cursor: 'pointer' }}
                        theme="outlined" />
                    {
                        shopStore.currentShop
                            ? (
                                <ShopName>
                                    {`${intl.formatMessage({ ...messages.selectedShop })}: `}
                                    <Avatar size="small" src={shopStore.currentShop.logo} />
                                    {`${shopStore.currentShop.name} (${shopStore.currentShop.email})`}
                                    <Button type='link' onClick={() => this.setState({ shopSelectVisible: true })}>{intl.formatMessage({...messages.otherShop})}</Button>
                                </ShopName>
                            )
                            : null
                    }
                </AppBarLeftSideWrapper>
                <AppBarRightSideWrapper>
                    <Dropdown trigger={['hover']} overlay={this.renderMenu()}>
                        <Username>
                            {username} <Icon type="down" />
                        </Username>
                    </Dropdown>
                    <UserDetailModal 
                        visible={this.state.userDetailVisible}
                        onClose={() => this.setState({ userDetailVisible: false })}/>
                </AppBarRightSideWrapper>

                <ShopSelectModal
                    visible={this.state.shopSelectVisible}
                    onClose={() => this.setState({ shopSelectVisible: false })}
                />
            </AppBar>
        )
    }
}

export default withRouter(injectIntl(ApplicationBar))
