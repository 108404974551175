import React, { Component, Suspense, lazy } from 'react'
import styled from 'styled-components'
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import { Provider } from 'mobx-react'
import { ThemeProvider } from 'styled-components'
import { IntlProvider, addLocaleData } from "react-intl"
import zh from "react-intl/locale-data/zh"
import { ConfigProvider } from 'antd'
import zh_TW from 'antd/lib/locale-provider/zh_TW'
import en_US from 'antd/lib/locale-provider/en_US'
import translations from "./i18n/locales"
import queryString from 'query-string'
import moment from 'moment'

import PrivateRoute from '~/components/routes/privateRoute'
import PageLayout from '~/components/layouts/main'


import adsBannerStore from './stores/adsBannerStore'
import analysisStore from './stores/analysisStore'
import authStore from './stores/authStore'
import categoryStore from './stores/categoryStore'
import commonStore from './stores/commonStore'
import couponStore from './stores/couponStore'
import deliveryTimeSlotStore from './stores/deliveryTimeSlotStore'
import feedStore from './stores/feedStore'
import mallBannerStore from './stores/mallBannerStore'
import notificationStore from './stores/notificationStore'
import locationStore from './stores/locationStore'
import optionStore from './stores/optionStore'
import orderStore from './stores/orderStore'
import pospalStore from './stores/pospalStore'
import productStore from './stores/productStore'
import promotionCodeStore from './stores/promotionCodeStore'
import reservationStore from './stores/reservationStore'
import restaurantStore from './stores/restaurantStore'
import shopStore from './stores/shopStore'
import userStore from './stores/userStore'
import mediaStore from './stores/mediaStore'

import CategoryManagementPage from './containers/CategoryManagementPage'
import ChainManagementPage from './containers/ChainManagementPage'
import CouponManagementPage from './containers/CouponManagementPage'
import DeliveryTimeSlotPage from './containers/DeliveryTimeSlotPage'
import FeedManagementPage from './containers/FeedManagementPage'
import FoodOptionManagementPage from './containers/FoodOptionManagementPage'
import FoodOrderManagementPage from './containers/FoodOrderManagementPage'
import FoodSaleAnalysisPage from './containers/FoodSaleAnalysisPage'
import FoodSubchoiceManagementPage from './containers/FoodSubchoiceManagementPage'
import ForgotPasswordPage from './containers/ForgotPasswordPage'
import LoginPage from './containers/LoginPage'
import MainPage from './containers/MainPage'
import MainPageAdvertisementPage from './containers/MainPageAdvertisementPage'
import MallFoodOrderPage from './containers/MallFoodOrderPage'
import MallOrderPage from './containers/MallOrderPage'
import MallMemberAnalysisPage from './containers/MallMemberAnalysisPage'
import MallNotificationPage from './containers/MallNotificationPage'
import MallPromotionCodePage from './containers/MallPromotionCodePage'
import MemberAnalysisPage from './containers/MemberAnalysisPage'
import MemberBehaviorAnalysisPage from './containers/MemberBehaviorAnalysisPage'
import MemberCreditPage from './containers/MemberCreditPage'
import MemberFoodBehaviorAnalysisPage from './containers/MemberFoodBehaviorAnalysisPage'
import MemberListPage from './containers/MemberListPage'
import NotificationPage from './containers/NotificationPage'
import OfflineCouponPage from './containers/OfflineCouponPage'
import OnlineCouponPage from './containers/OnlineCouponPage'
import OptionManagementPage from './containers/OptionManagementPage'
import OrderManagementPage from './containers/OrderManagementPage'
import PospalCategoryListPage from './containers/PospalCategoryListPage'
import PospalMemberListPage from './containers/PospalMemberListPage'
import PospalProductListPage from './containers/PospalProductListPage'
import PospalTicketListPage from './containers/PospalTicketListPage'
import PospalTicketPrintPage from './containers/PospalTicketPrintPage'
import ProductListPage from './containers/ProductListPage'
import ProductStockPage from './containers/ProductStockPage'
import ResetPasswordPage from './containers/ResetPasswordPage'
import SaleAnalysisPage from './containers/SaleAnalysisPage'
import SalesReportPage from './containers/SalesReportPage'
import SettingPage from './containers/SettingPage'
import ShopManagementPage from './containers/ShopManagementPage'
import ShopperBannerPage from './containers/ShopperBannerPage'
import ShopperReportPage from './containers/ShopperReportPage'
import StaffManagementPage from './containers/StaffManagementPage'
import SubchoiceManagementPage from './containers/SubchoiceManagementPage'
import StoreStatusPage from './containers/StoreStatusPage'

addLocaleData([...zh])

moment.locale('zh-hk')

const theme = {
    primary: '#FA5C32'
}

class App extends Component {

    isTokenExpired() {
        const tokenExpAt = localStorage.getItem('tokenExpAt')
        const expiresIn = Date.parse(tokenExpAt) - Date.now() // in milliseconds
        return expiresIn < 0
    }

    componentDidMount() {
        if (localStorage.getItem('token') != null && !this.isTokenExpired()) {
            authStore.getSelf(localStorage.getItem('token'))
        }
    }

    render() {
        const params = queryString.parse(window.location.search)
        const locale = params.locale || 'zh'
        const messages = translations[locale]
        return (
            <Provider
                adsBannerStore={adsBannerStore}
                analysisStore={analysisStore}
                authStore={authStore}
                categoryStore={categoryStore}
                commonStore={commonStore}
                couponStore={couponStore}
                deliveryTimeSlotStore={deliveryTimeSlotStore}
                feedStore={feedStore}
                locationStore={locationStore}
                mallBannerStore={mallBannerStore}
                notificationStore={notificationStore}
                optionStore={optionStore}
                orderStore={orderStore}
                pospalStore={pospalStore}
                productStore={productStore}
                promotionCodeStore={promotionCodeStore}
                reservationStore={reservationStore}
                restaurantStore={restaurantStore}
                shopStore={shopStore}
                userStore={userStore}
                mediaStore={mediaStore}
            >
                <IntlProvider locale={locale} messages={messages}>
                    <ConfigProvider locale={locale === 'zh' ? zh_TW : en_US}>
                        <ThemeProvider theme={theme}>
                            <Router>
                                <Switch>
                                    <Route exact path="/login" component={LoginPage} />
                                    <Route exact path="/forgotPassword" component={ForgotPasswordPage} />
                                    <Route exact path="/resetPassword" component={ResetPasswordPage} />
                                    <PageLayout>
                                        <PrivateRoute exact path="/shop/:_shopId/categoryManagement" component={CategoryManagementPage} />
                                        <PrivateRoute exact path="/chainManagement" component={ChainManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/couponManagement" component={CouponManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/deliveryTimeSlot" component={DeliveryTimeSlotPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/feedManagement" component={FeedManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/foodOption" component={FoodOptionManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/foodOrder" component={FoodOrderManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/foodSubchoice" component={FoodSubchoiceManagementPage} />
                                        <PrivateRoute exact path="/" component={MainPage} />
                                        <PrivateRoute exact path="/mallFoodOrder" component={MallFoodOrderPage} />
                                        <PrivateRoute exact path="/mallOrder" component={MallOrderPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/mainPageAdvertisement" component={MainPageAdvertisementPage} />
                                        <PrivateRoute exact path="/mallMemberAnalysis" component={MallMemberAnalysisPage} />
                                        <PrivateRoute exact path="/mallNotification" component={MallNotificationPage} />
                                        <PrivateRoute exact path="/mallPromotionCode" component={MallPromotionCodePage} />
                                        <PrivateRoute exact path="/shop/:_shopId/memberAnalysis" component={MemberAnalysisPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/memberBehaviorAnalysis" component={MemberBehaviorAnalysisPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/memberFoodBehaviorAnalysis" component={MemberFoodBehaviorAnalysisPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/memberList" component={MemberListPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/notification" component={NotificationPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/offlineCoupon" component={OfflineCouponPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/onlineCoupon" component={OnlineCouponPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/productOption" component={OptionManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/orderManagement" component={OrderManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/pospalCategory" component={PospalCategoryListPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/pospalMember" component={PospalMemberListPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/pospalProduct" component={PospalProductListPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/pospalTickets" component={PospalTicketListPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/pospalTicketPrint" component={PospalTicketPrintPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/productList" component={ProductListPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/saleAnalysis" component={SaleAnalysisPage} />
                                        <PrivateRoute exact path="/shopperBanner" component={ShopperBannerPage} />
                                        <PrivateRoute exact path="/shopperReport" component={ShopperReportPage} />
                                        <PrivateRoute exact path="/salesReport" component={SalesReportPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/foodSaleAnalysis" component={FoodSaleAnalysisPage} />
                                        <PrivateRoute exact path="/shopManagement" component={ShopManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/staffManagement" component={StaffManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/productSubchoice" component={SubchoiceManagementPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/productStock" component={ProductStockPage} />
                                        <PrivateRoute exact path="/shop/:_shopId/memberCredit" component={MemberCreditPage} />
                                        <PrivateRoute exact path="/setting" component={SettingPage} />
                                        <PrivateRoute exact path="/restaurantStatusManagement" component={StoreStatusPage} />
                                    </PageLayout>
                                </Switch>
                            </Router>
                        </ThemeProvider>
                    </ConfigProvider>
                </IntlProvider>
            </Provider>
        )
    }
}

export default App
