import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Input, InputNumber, Spin, Avatar, Modal, Select } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import RichTextEditor from '~/components/richTextEditor'
import BraftEditor from 'braft-editor'
import StoreTable from '~/components/forms/createShopForm/storeTable'
import validator from '~/lib/validator'
import { hasError, getErrorMessage } from '~/lib/errorMessages'
import { statusOptions, shopTypeOptions } from '~/constants/options'
import _ from 'lodash'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

// @inject('commonStore', 'shopStore') @observer
class CreateChainForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                console.log(values)
                if (!err) {
                    this.props.onSubmit(values, () => this.props.form.resetFields())
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }


    debounceShopSearch = _.debounce((search) => {
        const { shopStore, commonStore } = this.props
        const { token } = commonStore
        if (search) {
            shopStore.listShop(token, 0, 0, undefined, search)
        }
    }, 250)

    handleOnShopSearch(value) {
        this.debounceShopSearch(value)
    }

    getShopSelectOptions() {
        const { shopStore, initial } = this.props
        if (initial) {
            const data = [...shopStore.shops, ...initial.shops]
            const shops = _.uniqBy(data, (shop) => shop._id)
            return shops.map((s) => {
                return (
                    <Select.Option key={s._id}> <Avatar size="small" src={s.logo} /> {`${s.name} (${s.email})`}</Select.Option>
                )
            })
        } else {
            return shopStore.shops.map((s) => {
                return (
                    <Select.Option key={s._id}> <Avatar size="small" src={s.logo} /> {`${s.name} (${s.email})`}</Select.Option>
                )
            })
        }
    }

    render() {
        const { isSubmitting, intl, initial, error, shopStore } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 18 },
        }
        return (
            <Form {...formItemLayout} style={{ backgroundColor: 'white', padding: 16 }}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.shop })}>
                    {
                        getFieldDecorator('shops', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.shops.map(s => s._id) : undefined
                        })(
                            <Select
                                mode='multiple'
                                showSearch
                                notFoundContent={shopStore.isSubmitting ? <Spin size="small" /> : null}
                                placeholder={intl.formatMessage({ ...messages.shopName })}
                                defaultActiveFirstOption={false}
                                showArrow={false}
                                filterOption={false}
                                onSearch={value => this.handleOnShopSearch(value)}>
                                {
                                    this.getShopSelectOptions()
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.creditExchangeRate })}
                    help={intl.formatMessage({ ...messages.creditExchangeRateReminder })}>
                    {
                        getFieldDecorator('rate', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.rate : undefined
                        })(
                            <InputNumber
                                style={{ width: 300 }}
                                min={0}
                                placeholder={intl.formatMessage({ ...messages.creditExchangeRate })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(inject('commonStore', 'shopStore')(observer(Form.create()(CreateChainForm))))