import React from 'react'
import { Button } from 'antd'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import ResetPasswordForm from '~/components/forms/resetPasswordForm'
import { injectIntl } from 'react-intl'
import { withRouter } from 'react-router-dom'
import messages from '~/messages'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'

const ForgotPasswordWrapper = styled.div`
    height: 100vh;
    max-width: 300px;
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
`

const NoticeWrapper = styled.h1`
    text-align: center;
`

@inject('authStore') @observer
class ResetPasswordPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            isPasswordReset: false,
            success: false
        }
    }

    async handleOnSubmit(fields) {
        const { authStore, location } = this.props
        const { password } = fields
        const { token } = queryString.parse(location.search)
        if (token) {
            authStore.resetPassword(token, password)
                .then(() => {
                    this.setState({ isPasswordReset: true })
                })
        }
    }

    handleLogin() {
        this.props.history.push('/login')
    }

    renderSubmitSuccessNotice() {
        const { intl } = this.props
        return (
            <NoticeWrapper>
                {intl.formatMessage({ ...messages.passwordChangedReminder })}
                {/* <Button type="primary" onClick={() => this.handleLogin()}>{intl.formatMessage({ ...messages.login })}</Button> */}
            </NoticeWrapper>
        )
    }

    render() {
        const { isPasswordReset } = this.state
        const { authStore, intl } = this.props
        const { isSubmitting } = authStore
        return (
            <ForgotPasswordWrapper>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.resetPasswordPageTitle })}</title>
                </Helmet>
                {
                    isPasswordReset
                        ? (
                            this.renderSubmitSuccessNotice()
                        )
                        : (
                            <ResetPasswordForm
                                isSubmitting={isSubmitting}
                                onSubmit={(fields) => this.handleOnSubmit(fields)} />
                        )
                }
            </ForgotPasswordWrapper>
        )
    }
}

export default withRouter(injectIntl(ResetPasswordPage))
