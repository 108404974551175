import React, { Component } from 'react'
import { AutoComplete, Modal, Icon } from 'antd'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import GoogleMapReact from 'google-map-react'
import _ from 'lodash'

const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY

@inject('locationStore') @observer
class LocationPickerModal extends Component {

    state = {
        visible: false,
        lng: undefined,
        lat: undefined,
        search: undefined,
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (!nextProps.visible) {
            return {
                visible: false,
                lng: undefined,
                lat: undefined,
                search: undefined,
            }
        }

        if (nextProps.visible && !prevState.visible) {
            const { coordinate } = nextProps
            const result = {
                visible: true,
                lng: coordinate.lng,
                lat: coordinate.lat,
                search: undefined,
            }
            return result
        }

        return prevState
    }

    handleOnMapClick(x, y, lat, lng, event) {
        this.setState({ lat, lng })
    }

    debounceAddressSearch = _.debounce((search) => {
        const { locationStore } = this.props
        if (search) {
            locationStore.listGoogleMapAddress(search)
        }
    }, 500)

    handleOnAddressSearch(search) {
        this.debounceAddressSearch(search)
    }

    async handleOnAddressSelect(value, option) {
        const { locationStore } = this.props
        const index = locationStore.predictions.findIndex(p => p.description === value)
        if (index > -1) {
            await locationStore.getGoogleMapAddressDetail(locationStore.predictions[index].place_id)
            this.setState({
                lat: locationStore.googleMapAddressDetail.geometry.location.lat,
                lng: locationStore.googleMapAddressDetail.geometry.location.lng
            })
        }
    }

    render() {
        const { intl, visible, locationStore } = this.props
        const dataSource = locationStore.predictions.map(d => {
            return d.description
        })
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                title={intl.formatMessage({...messages.pickCoordinate})}
                okText={intl.formatMessage({ ...messages.confirm })}
                onOk={() => this.props.onLocationUpdate({
                    lat: this.state.lat,
                    lng: this.state.lng
                })}
                okButtonProps={{
                    disabled: !this.state.lat || !this.state.lng
                }}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                onCancel={() => {
                    this.setState({ lat: undefined, lng: undefined })
                    this.props.onClose()
                }}>
                <AutoComplete
                    style={{ marginBottom: 16, width: '100%' }}
                    value={this.state.search}
                    onChange={(v) => this.setState({ search: v })}
                    onSearch={(v) => this.handleOnAddressSearch(v)}
                    onSelect={(v, option) => this.handleOnAddressSelect(v, option)}
                    dataSource={dataSource}
                    placeholder={intl.formatMessage({ ...messages.searchLocation })}
                />
                <div style={{ height: '500px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                        defaultCenter={{
                            lat: 22.302711,
                            lng: 114.177216
                        }}
                        center={
                            this.state.lng && this.state.lat
                                ? {
                                    lat: this.state.lat,
                                    lng: this.state.lng
                                }
                                : undefined
                        }
                        defaultZoom={11}
                        zoom={this.state.lng && this.state.lat ? 17 : 11}
                        onClick={({ x, y, lat, lng, event }) => this.handleOnMapClick(x, y, lat, lng, event)}
                    >
                        {
                            this.state.lng && this.state.lat
                                ? (
                                    <Icon
                                        theme="twoTone"
                                        twoToneColor="red"
                                        style={{ fontSize: 20, transform: 'translate(-10px,-10px)' }}
                                        type="shop"
                                        lat={this.state.lat}
                                        lng={this.state.lng} />
                                )
                                : null
                        }
                    </GoogleMapReact>
                </div>
            </Modal >
        )
    }
}

export default injectIntl(LocationPickerModal)