import React from 'react'
import { Form, Input, Select, InputNumber, DatePicker, Icon, Upload, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { statusOptions, baofoodCouponTypeOptions } from '~/constants/options'
import RichTextEditor from '~/components/richTextEditor'
import BraftEditor from 'braft-editor'
import moment from 'moment'

class CreateCouponForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: ''
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    validateMinPriceRequired(rule, value, callback) {
        const { form, intl } = this.props
        const { getFieldValue } = form
        const discount = getFieldValue('discount')
        var hasError = false
        switch (getFieldValue('couponType')) {
            case 'BAOFOOD_FREE':
            case 'BAOFOOD_DISCOUNT':
            case 'BAOFOOD_CASH_VOUCHER':
            case 'BAOFOOD_ITEM_DISCOUNT':
                break
            default:
                if (discount > value) {
                    hasError = true
                }
                break
        }
        if (hasError) {
            callback(intl.formatMessage({ ...messages.minPriceRequiredMustNotLessThanDiscount }))
        } else {
            callback()
        }
    }

    validateDiscount(rule, value, callback) {
        const { form, intl } = this.props
        const { getFieldValue } = form
        const minPriceRequired = getFieldValue('minPriceRequired')
        var hasError = false
        switch (getFieldValue('couponType')) {
            case 'BAOFOOD_FREE':
            case 'BAOFOOD_DISCOUNT':
            case 'BAOFOOD_CASH_VOUCHER':
            case 'BAOFOOD_ITEM_DISCOUNT':
                break
            default:
                if (value > minPriceRequired) {
                    hasError = true
                }
                break
        }
        if (hasError) {
            callback(intl.formatMessage({ ...messages.minPriceRequiredMustNotLessThanDiscount }))
        } else {
            callback()
        }
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    renderImageUploaderArea() {
        const { intl, isSubmitting, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form.Item
                label={intl.formatMessage({ ...messages.uploadPhoto })}
                help={intl.formatMessage({ ...messages.squareRatioReminder })}>
                {getFieldDecorator(`photos`, {
                    valuePropName: 'fileList',
                    getValueFromEvent: (e) => this.normFile(e),
                    initialValue: (initial && initial.photo) ? [{
                        uid: '1',
                        name: 'xxx.png',
                        status: 'done',
                        url: initial.photo,
                    }] : []
                })(
                    <Upload
                        listType="picture-card"
                        onPreview={(file) => this.handlePreview(file)}
                        disabled={isSubmitting}
                        beforeUpload={(file) => {
                            return false
                        }}
                    >
                        {getFieldValue('photos').length >= 1 ? null : uploadButton}
                    </Upload>
                )}
            </Form.Item>
        )
    }

    renderType() {
        const { isSubmitting, intl, initial, form, isOffline } = this.props
        const { getFieldDecorator } = form
        if (isOffline) {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.type })}>
                    {
                        getFieldDecorator('couponType', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.couponType : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.type })}
                                disabled={isSubmitting} >
                                {
                                    baofoodCouponTypeOptions.map((b, index) => {
                                        return <Select.Option key={index} value={b.value}>{intl.formatMessage({ ...b.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderDiscount() {
        const { isSubmitting, intl, initial, shop, form } = this.props
        const { getFieldDecorator, getFieldValue } = form
        var inputProps = { min: 0 }
        const couponType = getFieldValue('couponType')
        switch (couponType) {
            case 'BAOFOOD_FREE':
                inputProps['max'] = 0
                inputProps['disabled'] = true
                inputProps['formatter'] = null
                break
            case 'BAOFOOD_DISCOUNT':
                inputProps['max'] = 100
                inputProps['formatter'] = (value) => `${value} %`
                break
            case 'BAOFOOD_CASH_VOUCHER':
                inputProps['formatter'] = (value) => `$ ${value}`
                break
            case 'BAOFOOD_ITEM_DISCOUNT':
                inputProps['max'] = 100
                inputProps['formatter'] = (value) => `${value} %`
                break
            default:
                inputProps['formatter'] = (value) => `$ ${value}`
                break
        }
        return (
            <Form.Item
                label={intl.formatMessage({ ...messages.discount })}>
                {
                    getFieldDecorator('discount', {
                        rules: couponType !== 'BAOFOOD_FREE'
                            ? [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => this.validateDiscount(rule, value, callback) },
                            ]
                            : [],
                        initialValue: initial ? initial.discount : undefined
                    })(
                        <InputNumber
                            disabled={isSubmitting}
                            {...inputProps}
                            placeholder={intl.formatMessage({ ...messages.discount })} />
                    )
                }
            </Form.Item>
        )
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                {this.renderType()}
                <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}>
                    {
                        getFieldDecorator('priority', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.priority : 1
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.priority })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.creditRequired })}>
                    {
                        getFieldDecorator('credit', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.credit : undefined
                        })(
                            <InputNumber
                                min={0}
                                placeholder={intl.formatMessage({ ...messages.creditRequired })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.minPriceRequired })}>
                    {
                        getFieldDecorator('minPriceRequired', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => this.validateMinPriceRequired(rule, value, callback) },
                            ],
                            initialValue: initial ? initial.minPriceRequired : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `$ ${value}`}
                                placeholder={intl.formatMessage({ ...messages.minPriceRequired })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                {this.renderDiscount()}
                <Form.Item
                    label={intl.formatMessage({ ...messages.couponTotalAmount })}>
                    {
                        getFieldDecorator('totalAmount', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.totalAmount : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => intl.formatMessage({ ...messages.couponCountUnit }, { count: value })}
                                placeholder={intl.formatMessage({ ...messages.couponTotalAmount })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.maxPurchase })}>
                    {
                        getFieldDecorator('maxPurchase', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.maxPurchase : undefined
                        })(
                            <InputNumber
                                min={1}
                                formatter={value => intl.formatMessage({ ...messages.frequencyUnit }, { count: value })}
                                placeholder={intl.formatMessage({ ...messages.maxPurchase })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.termsAndConditions })}>
                    {
                        getFieldDecorator('terms', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.terms) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.termsAndConditions })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.detail })}>
                    {
                        getFieldDecorator('detail', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.detail) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                {this.renderImageUploaderArea()}
                <Form.Item
                    label={intl.formatMessage({ ...messages.validDate })}>
                    {
                        getFieldDecorator('validDate', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? [moment(initial.startDate), moment(initial.endDate)] : undefined
                        })(
                            <DatePicker.RangePicker
                                showTime
                                disabled={isSubmitting}
                                placeholder={[intl.formatMessage({ ...messages.fromTime }), intl.formatMessage({ ...messages.toTime })]}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>

                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateCouponForm))