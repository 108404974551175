import React from 'react'
import { Form, Input, Select, Icon } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { statusOptions } from '~/constants/options'
import validator from '~/lib/validator'

class CreateStaffForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            confirmDirty: false
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }


    handleConfirmBlur(e) {
        const value = e.target.value
        this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    validateToNextPassword(rule, value, callback) {
        const form = this.props.form
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true })
        }
        callback()
    }

    compareToFirstPassword(rule, value, callback) {
        const { form, intl } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback(intl.formatMessage({ ...messages.confirmPasswordError }))
        } else {
            callback()
        }
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.username })}>
                    {
                        getFieldDecorator('username', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.username : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.username })}
                                disabled={isSubmitting || !!initial} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.email })}>
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => validator.validateEmail(rule, value, callback, intl) }
                            ],
                            initialValue: initial ? initial.email : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.email })}
                                disabled={isSubmitting || !!initial} />
                        )
                    }
                </Form.Item>
                {
                    !initial
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.password })}>
                                {
                                    getFieldDecorator('password', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseInputPassword }) },
                                            { min: 4, message: intl.formatMessage({...messages.passwordLengthError}) },
                                            { validator: (rule, value, callback) => this.validateToNextPassword(rule, value, callback) }
                                        ],
                                    })(
                                        <Input
                                            type="password"
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            placeholder={intl.formatMessage({ ...messages.password })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {
                    !initial
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.confirmPassword })}>
                                {
                                    getFieldDecorator('confirmPassword', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseConfirmPassword }) },
                                            { validator: (rule, value, callback) => this.compareToFirstPassword(rule, value, callback) }
                                        ],
                                    })(
                                        <Input
                                            prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                            type="password"
                                            placeholder={intl.formatMessage({ ...messages.confirmPassword })}
                                            onBlur={(e) => this.handleConfirmBlur(e)}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.role })}>
                    {
                        getFieldDecorator('role', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.role : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                <Select.Option value={'SHOPOWNER'} key='SHOPOWNER'>{intl.formatMessage({ ...messages.shopOwner })}</Select.Option>
                                <Select.Option value={'STAFF'} key='STAFF'>{intl.formatMessage({ ...messages.staff })}</Select.Option>
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateStaffForm))