import React from 'react'
import { Form, Input, Select, Upload, Icon, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { statusOptions } from '~/constants/options'
import RichTextEditor from '~/components/richTextEditor'
import BraftEditor from 'braft-editor'
import ReactPlayer from 'react-player'
import styled from 'styled-components'

const PlayerWrapper = styled.div`
    position: relative;
    height: 500px;
    width: 100%;
    padding-top: 56.25%;
    background-color: #000000;
    padding: 8px;
`

const Player = styled.div`
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
`

class CreateFeedForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: '',
            previewType: undefined
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: '', previewType: undefined })
    }

    handlePreview(file) {
        let type = undefined
        if (file.type) {
            if (file.type === 'VIDEO') {
                type = 'VIDEO'
            } else {
                if (file.type.split('/')[0].toLowerCase() === 'image') {
                    type = 'IMAGE'
                }
                if (file.type === 'IMAGE') {
                    type = 'IMAGE'
                }
            }
        }
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewType: type,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    renderImageUploaderArea() {
        const { intl, isSubmitting, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form.Item
                label={intl.formatMessage({ ...messages.uploadPhoto })}
                help={intl.formatMessage({ ...messages.squareRatioReminder })}>
                {getFieldDecorator(`photos`, {
                    valuePropName: 'fileList',
                    getValueFromEvent: (e) => this.normFile(e),
                    initialValue: initial ? initial.photos.map((photo, index) => {
                        return {
                            uid: photo._id,
                            name: 'xxx.png',
                            status: 'done',
                            type: photo.type,
                            url: photo.path,
                        }
                    }) : []
                })(
                    <Upload
                        listType="picture-card"
                        onPreview={(file) => this.handlePreview(file)}
                        disabled={isSubmitting}
                        beforeUpload={(file) => {
                            return false
                        }}
                    >
                        {getFieldValue('photos').length >= 5 ? null : uploadButton}
                    </Upload>
                )}
            </Form.Item>
        )
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.title })}>
                    {
                        getFieldDecorator('title', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.title : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.title })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.detail })}>
                    {
                        getFieldDecorator('detail', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.detail) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                {this.renderImageUploaderArea()}
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    {
                        this.state.previewType === 'VIDEO'
                            ?
                            <PlayerWrapper>
                                <Player>
                                    <ReactPlayer
                                        width='100%'
                                        height='100%'
                                        url={this.state.previewImage}
                                        controls={true} />
                                </Player>
                            </PlayerWrapper> 
                            : null
                    }
                    {
                        this.state.previewType === 'IMAGE'
                            ? <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                            : null
                    }
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateFeedForm))