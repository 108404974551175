import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, Input, Icon, Checkbox, Row, DatePicker, Tag, Tabs, Dropdown, Switch, message } from 'antd'
import { LIMIT } from '~/constants/common'
import { DATE_FORMAT, DATE_ONLY_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import queryString from 'query-string'
import { statusOptions, pageSizeOptions } from '~/constants/options'
import update from 'immutability-helper'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    background-color: white;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`
const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'restaurantStore') @observer
class StoreStatusPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                status: []
            },
            sortedInfo: undefined
        }
    }

    async componentDidMount() {
        const { restaurantStore, commonStore, location } = this.props
        const { token } = commonStore
        const { pageSize, sortedInfo, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await restaurantStore.listAllShopStore(token, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.name,
            filterValues.status
        )
        this.setState({ currentPage })
    }

    async handleOnStatusChange(checked, storeId) {
        const { restaurantStore, commonStore, intl, } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await restaurantStore.updateStoreStatus(token, storeId, checked ? 'ACTIVE' : 'INACTIVE')
            await restaurantStore.listAllShopStore(token, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.name,
                filterValues.status
            )
            message.success(intl.formatMessage({ ...messages.success }))
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    async handleOnHiddenChange(checked, storeId) {
        const { restaurantStore, commonStore, intl, } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, sortedInfo, filterValues } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await restaurantStore.updateStoreHidden(token, storeId, checked)
            await restaurantStore.listAllShopStore(token, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.name,
                filterValues.status
            )
            message.success(intl.formatMessage({ ...messages.success }))
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.failure }))
        }
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { restaurantStore, commonStore } = this.props
        const { token } = commonStore
        const { pageSize } = pagination
        const {
            name,
            status,
        } = this.state.filterValues
        restaurantStore.listAllShopStore(token, pageSize, pageSize * (page - 1),
            sortField,
            name,
            status
        )
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                status: []
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { restaurantStore } = this.props
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.address }),
                dataIndex: 'address',
                key: 'address',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'address' && sortedInfo.order
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'status' && sortedInfo.order,
                render: (text, record) => {
                    return (
                        <Switch
                            disabled={restaurantStore.isSubmitting}
                            checked={text === 'ACTIVE'}
                            checkedChildren={intl.formatMessage({ ...messages.active })}
                            unCheckedChildren={intl.formatMessage({ ...messages.inactive })}
                            onChange={(checked) => this.handleOnStatusChange(checked, record._id)}
                        />
                    )
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.isHiddenInApp }),
                dataIndex: 'isHiddenInApp',
                key: 'isHiddenInApp',
                width: 200,
                render: (text, record) => {
                    return (
                        <Switch
                            disabled={restaurantStore.isSubmitting}
                            checked={record.isHiddenInApp}
                            checkedChildren={intl.formatMessage({ ...messages.yes })}
                            unCheckedChildren={intl.formatMessage({ ...messages.no })}
                            onChange={(checked) => this.handleOnHiddenChange(checked, record._id)}
                        />
                    )
                }
            },
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag key={key}>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag key={key}>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { intl, restaurantStore } = this.props
        const { currentPage, pageSize } = this.state
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.restaurantStatusManagement })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(restaurantStore.stores)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: restaurantStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={
                            restaurantStore.isSubmitting
                        }
                        rowKey={record => record._id}
                    />
                </TableWrapper>
            </Container>
        )
    }
}

export default withRouter(injectIntl(StoreStatusPage))