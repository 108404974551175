import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Descriptions, Icon, Card, Divider } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { foodDeliveryMethodOptions } from '~/constants/options'
import GoogleMapReact from 'google-map-react'
import moment from 'moment'


const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY

@inject('commonStore', 'restaurantStore', 'analysisStore') @observer
class StoreMap extends Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedStore: undefined
        }
    }

    async componentDidMount() {
        const { restaurantStore, commonStore } = this.props
        const { token } = commonStore
        await restaurantStore.listActiveStore(token)
    }

    async handleOnStoreClick(store) {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        await analysisStore.getStoreRevenue(token, store._id, moment().startOf('day').format(), moment().endOf('day').format())
        this.setState({ selectedStore: store })
    }

    render() {
        const { intl, restaurantStore, analysisStore } = this.props
        const { selectedStore } = this.state
        const { storeRevenue } = analysisStore
        return (
            <Card
                title={intl.formatMessage({ ...messages.restaurantMap })}
                style={{ marginTop: 16 }}>
                <Descriptions>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.branch })} span={3}>{selectedStore ? selectedStore.name : '-'}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.address })} span={3}>{selectedStore ? selectedStore.address : '-'}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.foodDeliveryMethods })} span={3}>
                        {
                            selectedStore
                                ? selectedStore.deliveryMethods.map(d => {
                                    const i = foodDeliveryMethodOptions.findIndex(f => f.value === d)
                                    return i > -1 ? intl.formatMessage({ ...foodDeliveryMethodOptions[i].text }) : undefined
                                }).filter(d => d).join(', ')
                                : '-'
                        }
                    </Descriptions.Item>
                </Descriptions>
                <Divider />
                <Descriptions title={intl.formatMessage({ ...messages.todaySales })}>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.orderCount })}>{storeRevenue ? storeRevenue.totalCount : '-'}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.deliveryOrderCount })}>{storeRevenue ? storeRevenue.deliveryCount : '-'}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.selfPickUpOrderCount })}>{storeRevenue ? storeRevenue.selfPickUpCount : '-'}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.totalRevenue })}>{storeRevenue ? storeRevenue.totalAmount : '-'}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.deliveryRevenue })}>{storeRevenue ? storeRevenue.deliveryAmount : '-'}</Descriptions.Item>
                    <Descriptions.Item label={intl.formatMessage({ ...messages.selfPickUpRevenue })}>{storeRevenue ? storeRevenue.selfPickUpAmount : '-'}</Descriptions.Item>
                </Descriptions>
                <div style={{ height: '500px', width: '100%', marginTop: 16 }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                        defaultCenter={{
                            lat: 22.302711,
                            lng: 114.177216
                        }}
                        defaultZoom={11}
                    >
                        {
                            restaurantStore.activeStores.map(store => {
                                if (!store.location) { 
                                    return null
                                }
                                return (
                                    <Icon
                                        theme="twoTone"
                                        twoToneColor="red"
                                        onClick={() => this.handleOnStoreClick(store)}
                                        style={{ fontSize: 20, transform: 'translate(-10px,-10px)', cursor: 'pointer' }}
                                        type="shop"
                                        lat={store.location ? store.location.coordinates[1] : undefined }
                                        lng={store.location ? store.location.coordinates[0] : undefined }/>
                                )
                            })
                        }
                    </GoogleMapReact>
                </div>
            </Card>
        )
    }
}

export default injectIntl(StoreMap)