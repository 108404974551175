import React, { Component } from 'react'
import styled from 'styled-components'
import { Descriptions, Modal, Icon, Button, message } from 'antd'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { deliveryStatusOptions } from '~/constants/options'
import GoogleMapReact from 'google-map-react'
import { getMidPoint } from '~/lib/mapHelper'
import moment from 'moment'
import { DATE_FORMAT } from '~/constants/format'
 
const GOOGLE_MAP_KEY = process.env.REACT_APP_GOOGLE_MAP_KEY

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

@inject('commonStore', 'orderStore') @observer
class FoodOrderTrackingModal extends Component {

    async componentDidMount() {
        const { orderStore, commonStore, order } = this.props
        const { token } = commonStore
        await orderStore.getFoodOrderTracking(token, order._id)
    }

    async handleOnOrderCancelClick() {
        const { orderStore, commonStore, order, intl } = this.props
        const { token } = commonStore
        try {
            await orderStore.cancelZeekOrder(token, order._id)
            message.success(intl.formatMessage({ ...messages.cancelCarrierOrderSuccess }))
            await orderStore.getFoodOrderTracking(token, order._id)
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.cancelCarrierOrderFailure }))
        }
    }

    async handleOnOrderCreateClick() {
        const { orderStore, commonStore, order, intl } = this.props
        const { token } = commonStore
        try {
            await orderStore.createZeekOrder(token, order._id)
            message.success(intl.formatMessage({ ...messages.createZeekOrderSuccess }))
            await orderStore.getFoodOrderTracking(token, order._id)
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.createZeekOrderFailure }))
        }
    }

    getStatus(status) {
        const { intl } = this.props
        const index = deliveryStatusOptions.findIndex(o => o.value === status)
        if (index > -1) {
            return intl.formatMessage({ ...deliveryStatusOptions[index].text })
        } else {
            return '-'
        }
    }

    render() {
        const { intl, visible, orderStore, order } = this.props
        const { foodOrderTracking } = orderStore
        if (!foodOrderTracking) {
            return null
        }
        const { carrier, carrierOrderId, phoneNumber, name, start, destination, tracking, status, latestUpdateTime } = foodOrderTracking
        const { merchantLocation, userLocation, currentLocation } = tracking
        const center = getMidPoint(
            merchantLocation[0],
            merchantLocation[1],
            userLocation[0],
            userLocation[1]
        )
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.orderTracking })}
                footer={null}
                onCancel={() => {
                    orderStore.resetFoodOrderTracking()
                    this.props.onClose()
                }}>
                <Descriptions>
                    <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.deliveryCarrier })}>{carrier || '-'}</Descriptions.Item>
                    <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.deliveryCarrierOrderId })}>
                        { carrierOrderId || '-' }
                        {
                            carrier === 'ZEEK' && carrierOrderId && (status === 'PREPARING' || status === 'ZEEK_PARTNER_CONFIRMED' || status === 'ZEEK_PARTNER_NEAR_STORE')
                                ? <Button
                                    style={{ marginLeft: 16 }}
                                    type='primary'
                                    loading={orderStore.isSubmitting}
                                    onClick={() => this.handleOnOrderCancelClick()}>
                                    {intl.formatMessage({ ...messages.cancelCarrierOrder })}
                                </Button>
                                : null
                        }
                        {
                            (
                                (carrier === 'ZEEK' && (!carrierOrderId || status === 'CANCELLED')) ||
                                (carrier === 'C4V' && (status === 'PREPARING' || status === 'CANCELLED'))
                            )
                                ? <Button
                                    style={{ marginLeft: 16 }}
                                    type='primary'
                                    loading={orderStore.isSubmitting}
                                    onClick={() => this.handleOnOrderCreateClick()}>
                                    {intl.formatMessage({ ...messages.createZeekOrder })}
                                </Button>
                                : null
                        }
                    </Descriptions.Item>
                    {
                        carrier === 'ZEEK' && order.zeekQuotations && order.zeekQuotations.length > 0
                            ? <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.zeekOrderType })}>{order.zeekQuotations[0].mode}</Descriptions.Item>
                            : null
                    }
                    <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.status })}>{this.getStatus(status)}</Descriptions.Item>
                    <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.startingPoint })}>{start}</Descriptions.Item>
                    <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.endingPoint })}>{destination}</Descriptions.Item>
                    {
                        name
                            ? (
                                <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.foodDeliveryManName })}>{name}</Descriptions.Item>
                            )
                            : null
                    }
                    {
                        phoneNumber
                            ? (
                                <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.foodDeliveryManPhoneNumber })}>{phoneNumber}</Descriptions.Item>
                            )
                            : null
                    }
                    {
                        latestUpdateTime
                            ? (
                                <Descriptions.Item span={3} label={intl.formatMessage({ ...messages.latestUpdateTime })}>{moment(latestUpdateTime).format(DATE_FORMAT)}</Descriptions.Item>
                            )
                            : null
                    }
                </Descriptions>

                <div style={{ height: '500px', width: '100%' }}>
                    <GoogleMapReact
                        bootstrapURLKeys={{ key: GOOGLE_MAP_KEY }}
                        defaultCenter={{
                            lat: 22.302711,
                            lng: 114.177216
                        }}
                        center={{
                            lat: center[0],
                            lng: center[1]
                        }}
                        defaultZoom={16}
                    // onGoogleApiLoaded={({ map, maps }) => {
                    //     new google.maps.Marker({
                    //         position: { lat: Districts[0].Coordinate.Latitude, lng: Districts[0].Coordinate.Longitude },
                    //         animation: google.maps.Animation.DROP,
                    //         map,
                    //         icon: {
                    //             url: 'http://image.flaticon.com/icons/svg/252/252025.svg',
                    //             scaledSize: new google.maps.Size(32, 32),
                    //             origin: new google.maps.Point(0, 0),
                    //             labelOrigin: new google.maps.Point(70, 15)
                    //         },
                    //         label: {
                    //             text: intl.formatMessage({ ...messages.startingPoint }),
                    //             color: 'red',
                    //             fontSize: "16px",
                    //             fontWeight: "bold"
                    //         }
                    //     })

                    //     new google.maps.Marker({
                    //         position: { lat: Districts[1].Coordinate.Latitude, lng: Districts[1].Coordinate.Longitude },
                    //         animation: google.maps.Animation.DROP,
                    //         map,
                    //         icon: {
                    //             url: 'http://image.flaticon.com/icons/svg/252/252025.svg',
                    //             scaledSize: new google.maps.Size(32, 32),
                    //             origin: new google.maps.Point(0, 0),
                    //             labelOrigin: new google.maps.Point(70, 15)
                    //         },
                    //         label: {
                    //             text: intl.formatMessage({ ...messages.endingPoint }),
                    //             color: 'green',
                    //             fontSize: "16px",
                    //             fontWeight: "bold"
                    //         }
                    //     })
                    // }}
                    >
                        <Icon
                            theme="twoTone"
                            twoToneColor="red"
                            style={{ fontSize: 20, transform: 'translate(-10px,-10px)' }}
                            type="shop"
                            lat={merchantLocation[0]}
                            lng={merchantLocation[1]} />
                        <Icon
                            theme="twoTone"
                            twoToneColor="green"
                            style={{ fontSize: 20, transform: 'translate(-10px,-10px)' }}
                            type="home"
                            lat={userLocation[0]}
                            lng={userLocation[1]} />
                        {
                            currentLocation
                                ? (
                                    <Icon
                                        theme="twoTone"
                                        twoToneColor="blue"
                                        style={{ fontSize: 20, transform: 'translate(-10px,-10px)' }}
                                        type="car"
                                        lat={currentLocation[0]}
                                        lng={currentLocation[1]} />
                                )
                                : null
                        }

                    </GoogleMapReact>
                </div>
            </Modal>
        )
    }
}

export default injectIntl(FoodOrderTrackingModal)