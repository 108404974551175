import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, Card, Radio, DatePicker } from 'antd'
import messages from '~/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import MostOrderMemberChart from '~/components/charts/mostOrderMemberChart'
import MostAmountMemberChart from '~/components/charts/mostAmountMemberChart'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    /* background-color: white; */
    
    .ant-card:not(:last-child) {
        margin-bottom: 16px;
    }
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

const rangeOptions = [
    { value: 'TODAY', text: messages.today },
    { value: 'YESTERDAY', text: messages.yesterday },
    { value: 'WEEK', text: messages.thisWeek },
    { value: 'MONTH', text: messages.thisMonth },
    { value: 'CUSTOMIZE', text: messages.customize },
]

@inject('commonStore', 'analysisStore', 'shopStore') @observer
class MemberFoodBehaviorAnalysisPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            mode: 'TODAY',
            range: undefined
        }
    }

    async componentDidMount() {
        const { analysisStore, commonStore, shopStore, match } = this.props
        const { token } = commonStore
        await shopStore.getShop(token, match.params._shopId)
        const { currentShop } = shopStore
        const startDate = moment().startOf('day')
        const endDate = moment().endOf('day')
        await analysisStore.listMostFoodOrderMembers(token, currentShop._id)
        await analysisStore.listMostFoodAmountMembers(token, currentShop._id)
    }

    renderRangeButton() {
        const { intl } = this.props
        const buttons = [
            <Radio.Group onChange={(e) => this.handleOnModeChange(e.target.value)} defaultValue="TODAY" value={this.state.mode} key={0}>
                {rangeOptions.map((r, i) => <Radio.Button key={r.value} value={r.value}>{intl.formatMessage({ ...r.text })}</Radio.Button>)}
            </Radio.Group>
        ]
        return this.state.mode !== 'CUSTOMIZE'
            ? buttons
            : buttons.concat(<DatePicker.RangePicker
                value={this.state.range}
                key={1}
                onChange={(v) => this.handleOnRangeChange(v)} />
            )
    }

    async handleOnRangeChange(v) {
        const { analysisStore, commonStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { mode } = this.state
        if (mode === 'CUSTOMIZE' && v && v.length > 0) {
            analysisStore.getMemberFoodBehaviorAnalysis(token, currentShop._id, v[0].format(), v[1].format())
        }
        this.setState({ range: v })
    }

    async handleOnModeChange(value) {
        const { analysisStore, commonStore, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        let startDate = undefined
        let endDate = undefined
        switch (value) {
            case 'TODAY':
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            case 'YESTERDAY':
                startDate = moment().startOf('day').subtract(1, 'day')
                endDate = moment().endOf('day').subtract(1, 'day')
                break
            case 'WEEK':
                startDate = moment().startOf('week')
                endDate = moment().endOf('week')
                break
            case 'MONTH':
                startDate = moment().startOf('month')
                endDate = moment().endOf('month')
                break
            case 'CUSTOMIZE':
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
            default:
                startDate = moment().startOf('day')
                endDate = moment().endOf('day')
                break
        }
        analysisStore.getMemberFoodBehaviorAnalysis(token, currentShop._id, startDate.format(), endDate.format())
        this.setState({ mode: value, range: [startDate, endDate] })
    }

    render() {
        const { analysisStore, intl } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.memberBehaviorAnalysis })}</title>
                </Helmet>
                {/* 
                <Card
                    title={
                        <ActionWrapper>
                            <ActionLeftWrapper>{intl.formatMessage({ ...messages.memberAnalysis })}</ActionLeftWrapper>
                            {this.renderRangeButton()}
                        </ActionWrapper>
                    }>
                    <Statistic title={intl.formatMessage({ ...messages.memberTotalCount })} value={data.memberCount} />
                </Card> */}

                <MostOrderMemberChart
                    data={analysisStore.mostFoodOrderMembers} />
                <MostAmountMemberChart
                    data={analysisStore.mostFoodAmountMembers} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(MemberFoodBehaviorAnalysisPage))