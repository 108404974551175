import messages from '~/messages'

export const statusOptions = [
    { value: 'ACTIVE', text: messages.active },
    { value: 'INACTIVE', text: messages.inactive },
]

export const pageSizeOptions = [
    '10',
    '20',
    '50',
    '100'
]

export const productTypeOptions = [
    { value: 'ORDER', text: messages.orderedProduct },
    { value: 'RESERVATION', text: messages.reservedProduct },
]

export const groupOptions = [
    { value: 'PETS', text: messages.petsProduct },
    { value: 'BEAUTY_HEALTH', text: messages.beautyAndHealth },
    { value: 'SPORT_TRAVEL', text: messages.sportAndTravel },
    { value: 'FASHION', text: messages.fashion },
    { value: 'ENTERTAINMENT', text: messages.entertainment },
    { value: 'SUPERMARKET', text: messages.supermarket },
]

export const orderStatusOptions = [
    { value: 'PENDING', text: messages.pending },
    { value: 'CONFIRMED', text: messages.confirmed },
    { value: 'COMPLETE', text: messages.complete },
    { value: 'CANCELLED', text: messages.cancelled },
]

export const roleOptions = [
    { value: 'ADMIN', text: messages.admin },
    { value: 'SHOPOWNER', text: messages.shopOwner },
    { value: 'STAFF', text: messages.staff },
    { value: 'USER', text: messages.user },
]

export const shopRoleOptions = [
    { value: 'SHOPOWNER', text: messages.shopOwner },
    { value: 'STAFF', text: messages.staff },
]

export const weekOptions = [
    { value: '0', text: messages.sunday },
    { value: '1', text: messages.monday },
    { value: '2', text: messages.tuesday },
    { value: '3', text: messages.wednesday },
    { value: '4', text: messages.thursday },
    { value: '5', text: messages.friday },
    { value: '6', text: messages.saturday },
]

export const deliveryMethodOptions = [
    { value: 'SFEXPRESS', text: messages.localExpress },
    // { value: 'CONTACT_SELLER', text: messages.contactSeller },
    { value: 'SELF_PICK_UP', text: messages.selfPickUp },
]

export const shopTypeOptions = [
    { value: 'RESTAURANT', text: messages.baofoodRestaurant },
    { value: 'RETAIL', text: messages.retail },
    { value: 'RESTAURANT_SHOPPER', text: messages.shopperDelivery }
]

export const baofoodCouponTypeOptions = [
    { value: 'BAOFOOD_FREE', text: messages.free, formatter: (value) => '-' },
    { value: 'BAOFOOD_DISCOUNT', text: messages.discount, formatter: (value) => `${value} %` },
    { value: 'BAOFOOD_CASH_VOUCHER', text: messages.cashVoucher, formatter: (value) => `$${value}` },
    { value: 'BAOFOOD_ITEM_DISCOUNT', text: messages.itemDiscount, formatter: (value) => `${value} %` },
]

export const foodDeliveryMethodOptions = [
    { value: 'DELIVERY', text: messages.delivery },
    { value: 'COD', text: messages.cashOnDelivery },
    { value: 'SELF_PICK_UP', text: messages.selfPickUp },
]

export const foodOrderStatusOptions = [
    { value: 'PENDING', text: messages.pending },
    { value: 'CONFIRMED', text: messages.confirmed },
    { value: 'DELIVERING', text: messages.delivering },
    { value: 'COMPLETE', text: messages.complete },
    { value: 'CANCELLED', text: messages.cancelled },
]

export const rangeOptions = [
    { value: 'TODAY', text: messages.today },
    { value: 'YESTERDAY', text: messages.yesterday },
    { value: 'WEEK', text: messages.thisWeek },
    { value: 'MONTH', text: messages.thisMonth },
    { value: 'CUSTOMIZE', text: messages.customize },
]

export const analysisPeriodModeOptions = [
    { value: 'HOURLY', text: messages.byHour },
    { value: 'DAILY', text: messages.byDay },
    { value: 'MONTHLY', text: messages.byMonth },
]

export const call4VanStatusOptions = [
    { value: 'WaitForDriver', text: messages.waitingForFoodDeliveryManAcceptingOrder },
    { value: 'Cancelled', text: messages.cancelled },
    { value: 'DoubleConfirmed', text: messages.foodDeliveryManConfirmed },
    { value: 'InProgress', text: messages.deliveryInProgress },
    { value: 'NoDriver', text: messages.noDeliveryMan },
    { value: 'Completed', text: messages.complete },
]

export const deliveryStatusOptions = [
    { value: 'PREPARING', text: messages.waitingForFoodDeliveryManAcceptingOrder },
    { value: 'ZEEK_PARTNER_CHANGED', text: messages.foodDeliveryManChanged },
    { value: 'ZEEK_PARTNER_CONFIRMED', text: messages.foodDeliveryManConfirmed },
    { value: 'ZEEK_PARTNER_NEAR_STORE', text: messages.foodDeliveryManNearStore },
    { value: 'DELIVERING', text: messages.deliveryInProgress },
    { value: 'COMPLETE', text: messages.complete },
    { value: 'CANCELLED', text: messages.cancelled },
]

export const deliveryCarrierOptions = [
    { value: 'C4V', text: 'Call4Van' },
    { value: 'ZEEK', text: 'Zeek' },
]

export const mallPromotionCodeTypeOptions = [
    { value: 'FIXED', text: messages.amountDeduction },
    { value: 'RATE', text: messages.discountRate },
]

export const mallPromotionCodeCouponTypeOptions = [
    { value: 'DELIVERY', text: messages.delivery },
    { value: 'SELF_PICK_UP', text: messages.selfPickUp },
    { value: 'SFEXPRESS', text: messages.localExpress },
]