import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, Input, Icon, Checkbox, Row, DatePicker, Tag, Modal, Dropdown, Menu, message } from 'antd'
import { LIMIT } from '~/constants/common'
import { DATE_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import { statusOptions, pageSizeOptions } from '~/constants/options'
import CreateChainModal from '~/components/createChainModal'
import EditChainModal from '~/components/editChainModal'
import update from 'immutability-helper'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'shopStore') @observer
class ChainManagementPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createChainVisible: false,
            editChainVisible: false,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                shop: undefined,
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortedInfo: undefined,
            selectedChainId: undefined
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { shopStore, commonStore, location } = this.props
        const { token } = commonStore
        const { pageSize, sortedInfo, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await shopStore.listChain(token, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.name,
            filterValues.shop,
            filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
            filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
        )
        this.setState({ currentPage })
    }

    handleOnChainCreate(values, reset) {
        const { shopStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, sortedInfo, filterValues } = this.state
        const { name, shops, rate } = values
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        shopStore.createChain(token, name, shops, rate)
            .then(() => {
                reset()
                this.setState({ createChainVisible: false })
                message.success(intl.formatMessage({ ...messages.createChainSuccess }))
                shopStore.listChain(token, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.shop,
                    filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                    filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createChainFailure }))
            })
    }

    handleOnChainEdit(values, reset) {
        const { shopStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, sortedInfo, filterValues } = this.state
        const { name, shops, rate } = values
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        shopStore.editChain(token, this.state.selectedChainId, name, shops, rate)
            .then(() => {
                reset()
                this.setState({ editChainVisible: false, selectedChainId: undefined })
                message.success(intl.formatMessage({ ...messages.updateChainSuccess }))
                shopStore.listChain(token, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.shop,
                    filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
                    filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
                )
            })
            .catch((e) => {
                console.log(e)
                message.error(intl.formatMessage({ ...messages.updateChainFailure }))
            })
    }

    handleOnEditClick(record) {
        this.setState({ editChainVisible: true, selectedChainId: record._id })
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { shopStore, commonStore } = this.props
        const { token } = commonStore
        const { pageSize } = pagination
        const {
            name,
            shop,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        shopStore.listChain(token, pageSize, pageSize * (page - 1),
            sortField,
            name,
            shop,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined
        )
        const href = `/chainManagement?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
            case 'shop':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                shop: undefined,
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
            case 'shop':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name' || dataIndex === 'shop')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { commonStore } = this.props
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.creditExchangeRate }),
                dataIndex: 'rate',
                key: 'rate',
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                        </span>
                    )
                },
            }
        ]
    }

    renderFilterTags() {
        const { intl, categoryStore } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'shop':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.shopName })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { shopStore, intl } = this.props
        const { currentPage, pageSize, selectedChainId } = this.state
        var selectedChainIndex
        if (selectedChainId) {
            const index = shopStore.chains.findIndex(o => o._id === selectedChainId)
            selectedChainIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.chainManagement })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type='primary' onClick={() => this.setState({ createChainVisible: true })}>
                            {intl.formatMessage({ ...messages.createChain })}
                        </Button>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(shopStore.chains)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: shopStore.chainCount,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={
                            shopStore.isSubmitting
                        }
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <CreateChainModal
                    visible={this.state.createChainVisible}
                    isSubmitting={shopStore.isSubmitting}
                    error={shopStore.error}
                    onSubmit={(values, reset) => this.handleOnChainCreate(values, reset)}
                    onClose={() => this.setState({ createChainVisible: false })} />
                <EditChainModal
                    chain={toJS(shopStore.chains[selectedChainIndex])}
                    visible={this.state.editChainVisible}
                    isSubmitting={shopStore.isSubmitting}
                    error={shopStore.error}
                    onSubmit={(values, reset) => this.handleOnChainEdit(values, reset)}
                    onClose={() => this.setState({ editChainVisible: false, selectedChainId: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(ChainManagementPage))