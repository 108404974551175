import React from 'react'
import { Form, Input, Select, Icon } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import validator from '~/lib/validator'

class EmailForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.email })}>
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => validator.validateEmail(rule, value, callback, intl) }
                            ]
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.email })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(EmailForm))