import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Modal, message } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import EditUserDetailForm from '~/components/forms/editUserDetailForm'


@inject('commonStore', 'authStore') @observer
class UserDetailModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    handleOnUserUpdate(values, reset) {
        const { authStore, commonStore, intl } = this.props
        const { password } = values
        const { token } = commonStore
        if (token) {
            authStore.resetPassword(token, password)
                .then(() => {
                    reset()
                    message.success(intl.formatMessage({...messages.resetPasswordSuccess}))
                    this.props.onClose()
                })
                .catch(e => {
                    message.error(intl.formatMessage({...messages.resetPasswordFailure}))
                })
        }
    }

    render() {
        const { intl, visible, authStore, commonStore } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                title={intl.formatMessage({ ...messages.userInformation })}
                okText={intl.formatMessage({...messages.confirm})}
                cancelText={intl.formatMessage({...messages.cancel})}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                <EditUserDetailForm
                    username={commonStore.username}
                    email={commonStore.email}
                    isSubmitting={authStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnUserUpdate(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst}/>
            </Modal>
        )
    }
}

export default injectIntl(UserDetailModal)