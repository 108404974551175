import React from 'react'
import { Form, Checkbox } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'

class DeliveryMethodsCheckbox extends React.Component {

    handleOnValueChange(value) {
        const { onChange } = this.props
        onChange(value)
    }

    render() {
        const { intl, value } = this.props
        const deliveryMethods = [
            { value: 'DELIVERY', label: intl.formatMessage({ ...messages.foodDelivery }) },
            { value: 'SELF_PICK_UP', label: intl.formatMessage({ ...messages.selfPickUp }) }
        ]
        return (
            <Checkbox.Group options={deliveryMethods} defaultValue={value} onChange={(v) => { this.handleOnValueChange(v) }} />
        )
    }
}

export default injectIntl(DeliveryMethodsCheckbox)