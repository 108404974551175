import React from 'react'
import { Form, TimePicker, Table, Icon } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import update from 'immutability-helper'
import { getRandomId } from '~/lib/idGenerator'

class TimePickerTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            timeSlots: [{
                key: getRandomId(),
                time: undefined,
            }],
            reservationId: undefined
        }
    }

    static getDerivedStateFromProps(props, state) {
        const { initialValue, reservationId } = props
        if (state.reservationId !== reservationId && initialValue && initialValue.length > 0) {
            return {
                timeSlots: initialValue.map(c => {
                    return {
                        key: getRandomId(),
                        time: c.time,
                    }
                }),
                reservationId: reservationId
            } 
        }
        return state
    }

    reset() {
        this.setState({
            timeSlots: [{
                key: getRandomId(),
                time: undefined
            }],
            reservationId: undefined
        })
    }

    submit() {
        const { onChange } = this.props
        onChange(this.state.timeSlots)
    }

    handleOnAddNewClick(index) {
        const timeSlot = {
            key: getRandomId(),
            time: undefined,
        }
        const timeSlots = update(this.state.timeSlots, { $push: [timeSlot] })
        this.setState({ timeSlots })
    }

    handleOnDeleteClick(index) {
        if (this.state.timeSlots.length > 1) {
            const timeSlots = update(this.state.timeSlots, { $splice: [[index, 1]] })
            this.setState({ timeSlots })
        }
    }

    handleOnValueChange(index, _update) {
        const timeSlots = update(this.state.timeSlots, { [index]: _update })
        this.setState({ timeSlots })
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.time }),
                dataIndex: 'time',
                key: 'time',
                render: (text, record, index) => {
                    return (
                        <TimePicker
                            format='HH:mm'
                            value={record.time}
                            onChange={(time) => this.handleOnValueChange(index, { 'time': { $set: time } })}
                            placeholder={intl.formatMessage({ ...messages.time })}
                        />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                width: 70,
                render: (text, record, index) => {
                    return (
                        <span>
                            <Icon type="plus" onClick={() => this.handleOnAddNewClick(index)} style={{ marginRight: 8, cursor: 'pointer' }} />
                            <Icon type="delete" onClick={() => this.handleOnDeleteClick(index)} style={{ cursor: 'pointer' }} />
                        </span>
                    )
                }
            },
        ]
    }

    render() {
        return (
            <Table
                columns={this.renderTableColumn()}
                dataSource={this.state.timeSlots}
                rowKey={record => record.key}
                pagination={false} />
        )
    }
}

export default injectIntl(Form.create()(TimePickerTable))