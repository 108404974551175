import React from 'react'
import { Form, Input, InputNumber, Checkbox, Upload, Icon, Modal, Select } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import RichTextEditor from '~/components/richTextEditor'
import BraftEditor from 'braft-editor'
import StoreTable from '~/components/forms/createShopForm/storeTable'
import ShopSelect from '~/components/forms/createShopForm/shopSelect'
import validator from '~/lib/validator'
import { hasError, getErrorMessage } from '~/lib/errorMessages'
import { statusOptions, shopTypeOptions, deliveryMethodOptions } from '~/constants/options'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

class CreateShopForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: ''
        }
        this.storeTable = React.createRef()
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.storeTable.submit()
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => this.props.form.resetFields())
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
        this.storeTable.reset()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    render() {
        const { isSubmitting, intl, initial, error } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form {...formItemLayout} style={{ backgroundColor: 'white', padding: 16 }}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.shopType })}>
                    {
                        getFieldDecorator('type', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.type : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    shopTypeOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.email })}
                    validateStatus={hasError(error, 1003) ? 'error' : null}
                    help={getErrorMessage(intl, error, 1003)}>
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                // { validator: (rule, value, callback) => validator.validateEmail(rule, value, callback, intl) }
                            ],
                            initialValue: initial ? initial.email : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.email })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.address })}>
                    {
                        getFieldDecorator('address', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                            ],
                            initialValue: initial ? initial.address : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.address })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.initial })}
                    validateStatus={hasError(error, 2012) ? 'error' : null}
                    help={getErrorMessage(intl, error, 2012) || intl.formatMessage({...messages.initialError})}>
                    {
                        !initial
                            ? getFieldDecorator('initial', {
                                rules: [
                                    { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                    { validator: (rule, value, callback) => validator.validateInitial(rule, value, callback, intl) }
                                ],
                                initialValue: initial ? initial.initial : undefined,
                                normalize: (value) => {
                                    if (value) {
                                        return value.toUpperCase()
                                    }
                                    return value
                                }
                            })(
                                <Input
                                    placeholder={intl.formatMessage({ ...messages.initial })}
                                    disabled={isSubmitting} />
                            )
                            : initial.initial
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.phoneNumber })}>
                    {
                        getFieldDecorator('phoneNumber', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.phoneNumber : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.phoneNumber })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.website })}>
                    {
                        getFieldDecorator('website', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.website : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.website })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.appleAppStoreLink })}>
                    {
                        getFieldDecorator('appleAppStoreLink', {
                            initialValue: initial ? initial.appleAppStoreLink : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.appleAppStoreLink })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.googlePlayStoreLink })}>
                    {
                        getFieldDecorator('googlePlayStoreLink', {
                            initialValue: initial ? initial.googlePlayStoreLink : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.googlePlayStoreLink })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.domainName })}>
                    {
                        getFieldDecorator('domainName', {
                            initialValue: initial ? initial.domainName : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.domainName })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.mallDomainName })}>
                    {
                        getFieldDecorator('mallDomainName', {
                            initialValue: initial ? initial.mallDomainName : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.mallDomainName })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.websiteThemeColor })}>
                    {
                        getFieldDecorator('websiteThemeColor', {
                            initialValue: initial ? initial.websiteThemeColor : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.websiteThemeColor })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={'Facebook APP ID'}
                    help={`Danger! Don't edit!`}>
                    {
                        getFieldDecorator('facebookAppId', {
                            initialValue: initial ? initial.facebookAppId : undefined
                        })(
                            <Input
                                placeholder={'Facebook APP ID'}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={'FCM'}
                    help={`Danger! Don't edit!`}>
                    {
                        getFieldDecorator('fcm', {
                            initialValue: initial ? initial.fcm : undefined
                        })(
                            <Input
                                placeholder={'FCM'}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={'Scheme'}
                    help={`Danger! Don't edit!`}>
                    {
                        getFieldDecorator('scheme', {
                            initialValue: initial ? initial.scheme : undefined
                        })(
                            <Input
                                placeholder={'Scheme'}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.shopDescription })}>
                    {
                        getFieldDecorator('description', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.description) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.termsAndConditions })}>
                    {
                        getFieldDecorator('terms', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.terms) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                            />
                        )
                    }
                </Form.Item>
                {
                    getFieldValue('type') === 'RETAIL'
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.deliveryMethod })}>
                                {
                                    getFieldDecorator('deliveryMethods', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                                        ],
                                        initialValue: initial ? initial.deliveryMethods : []
                                    })(
                                        <Checkbox.Group
                                            options={deliveryMethodOptions.map(d => { return { value: d.value, label: intl.formatMessage({ ...d.text }) } })} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {
                    getFieldValue('type') === 'RETAIL'
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.isCustomLogistics })}>
                                {
                                    getFieldDecorator('isCustomLogistics', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                                        ],
                                        valuePropName: 'checked',
                                        initialValue: initial && initial.isCustomLogistics ? initial.isCustomLogistics : false
                                    })(
                                        <Checkbox />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {
                    getFieldValue('type') === 'RETAIL' && getFieldValue('isCustomLogistics')
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.freeCustomShippingPrice })}>
                                {
                                    getFieldDecorator('freeCustomShippingPrice', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                        ],
                                        initialValue: initial ? initial.freeCustomShippingPrice : undefined
                                    })(
                                        <InputNumber
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            placeholder={intl.formatMessage({ ...messages.freeCustomShippingPrice })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {
                    getFieldValue('type') === 'RETAIL' && getFieldValue('isCustomLogistics')
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.nonfreeCustomShippingFee })}>
                                {
                                    getFieldDecorator('nonfreeCustomShippingFee', {
                                        rules: [
                                            { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                                        ],
                                        initialValue: initial ? initial.nonfreeCustomShippingFee : undefined
                                    })(
                                        <InputNumber
                                            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                            placeholder={intl.formatMessage({ ...messages.nonfreeCustomShippingFee })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.pickUpStore })}>
                    {
                        getFieldDecorator('stores', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: (initial && initial.stores) ? initial.stores.map(s => { return { address: s } }) : []
                        })(
                            <StoreTable
                                shopId={initial ? initial._id : undefined}
                                initialValue={(initial && initial.stores) ? initial.stores.map(s => { return { address: s } }) : []}
                                wrappedComponentRef={(inst) => this.storeTable = inst} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.shopLogo })}>
                    {getFieldDecorator(`logo`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.logo) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.logo,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('logo').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.feedCover })}>
                    {getFieldDecorator(`feedCover`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.feedCover) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.feedCover,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('feedCover').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.searchCover })}>
                    {getFieldDecorator(`searchCover`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.searchCover) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.searchCover,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('searchCover').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.couponCover })}>
                    {getFieldDecorator(`couponCover`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.couponCover) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.couponCover,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('couponCover').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.loginLogo })}>
                    {getFieldDecorator(`loginLogo`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.loginLogo) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.loginLogo,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('loginLogo').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.loginBackground })}>
                    {getFieldDecorator(`loginBackground`, {
                        valuePropName: 'fileList',
                        getValueFromEvent: (e) => this.normFile(e),
                        initialValue: (initial && initial.loginBackground) ? [{
                            uid: '1',
                            name: 'xxx.png',
                            status: 'done',
                            url: initial.loginBackground,
                        }] : []
                    })(
                        <Upload
                            listType="picture-card"
                            onPreview={(file) => this.handlePreview(file)}
                            disabled={isSubmitting}
                            beforeUpload={(file) => {
                                return false
                            }}
                        >
                            {getFieldValue('loginBackground').length >= 1 ? null : uploadButton}
                        </Upload>
                    )}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                {
                    !initial && getFieldValue('type') === 'RESTAURANT_SHOPPER'
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.clonedShop })}
                                help={intl.formatMessage({ ...messages.productsCategoriesOptionsAndDeliveryTimeslotsWillbeCloned })}>
                                {
                                    getFieldDecorator('clonedShop', {
                                    })(
                                        <ShopSelect />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateShopForm))