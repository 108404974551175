import { observable, flow, action } from 'mobx'
import api from '../api'

class OrderStore {
    @observable isSubmitting = false
    @observable error = null
    @observable orders = []
    @observable count = 0
    @observable foodOrders = []
    @observable foodOrderCount = 0
    @observable foodOrderTracking = undefined

    listOrder = flow(function* (token, shop, limit, skip,
        sortField,
        orderId,
        type,
        status,
        trackingNumber,
        createdAtMin,
        createdAtMax
    ) {
        this.isSubmitting = true
        try {
            const response = shop
                ? yield api.listOrder(token, shop, limit, skip,
                    sortField,
                    orderId,
                    type,
                    status,
                    trackingNumber,
                    createdAtMin,
                    createdAtMax
                )
                : yield api.listMallOrder(token, limit, skip,
                    sortField,
                    orderId,
                    type,
                    status,
                    trackingNumber,
                    createdAtMin,
                    createdAtMax
                )
            const { orders, count } = response.data
            this.orders = orders
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getOrder = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = shop ? yield api.getOrder(token, shop, id) : yield api.getMallOrder(token, id)
            const index = this.orders.findIndex(o => o._id === id)
            this.orders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateOrder = flow(function* (token, shop, id, status, trackingNumber) {
        this.isSubmitting = true
        try {
            const response = shop ? yield api.updateOrder(token, shop, id, status, trackingNumber) : yield api.updateMallOrder(token, id, status, trackingNumber)
            const index = this.orders.findIndex((b) => {
                return b._id === id
            })
            this.orders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listFoodOrder = flow(function* (token, shop, limit, skip,
        sortField,
        orderId,
        deliveryMethod,
        status,
        createdAtMin,
        createdAtMax
    ) {
        this.isSubmitting = true
        try {
            const response = shop
                ? yield api.listFoodOrder(token, shop, limit, skip,
                    sortField,
                    orderId,
                    deliveryMethod,
                    status,
                    createdAtMin,
                    createdAtMax
                )
                : yield api.listMallFoodOrder(token, limit, skip,
                    sortField,
                    orderId,
                    deliveryMethod,
                    status,
                    createdAtMin,
                    createdAtMax
                )
            const { orders, count } = response.data
            this.foodOrders = orders
            this.foodOrderCount = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getFoodOrder = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = shop ? yield api.getFoodOrder(token, shop, id) : yield api.getMallFoodOrder(token, id)
            const index = this.foodOrders.findIndex(o => o._id === id)
            this.foodOrders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateFoodOrder = flow(function* (token, shop, id, status) {
        this.isSubmitting = true
        try {
            const response = shop ? yield api.updateFoodOrder(token, shop, id, status) : yield api.updateMallFoodOrder(token, id, status)
            const index = this.foodOrders.findIndex((b) => {
                return b._id === id
            })
            this.foodOrders[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getFoodOrderTracking = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            const response = yield api.getFoodOrderTracking(token, id)
            this.foodOrderTracking = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    refundFoodOrder = flow(function* (token, id, amount, reason) {
        this.isSubmitting = true
        try {
            yield api.refundFoodOrder(token, id, amount, reason)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    compensateFoodOrder = flow(function* (token, id, amount, reason) {
        this.isSubmitting = true
        try {
            yield api.compensateFoodOrder(token, id, amount, reason)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    cancelZeekOrder = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            yield api.cancelZeekOrder(token, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createZeekOrder = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            yield api.createZeekOrder(token, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateManyOrderStatus = flow(function* (token, shop, orderIds, status) {
        this.isSubmitting = true
        try {
            if (shop) {
                yield api.updateShopManyOrderStatus(token, shop, orderIds, status)
            } else {
                yield api.updateManyOrderStatus(token, orderIds, status)
            }
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    refundOrder = flow(function* (token, id, amount, reason) {
        this.isSubmitting = true
        try {
            yield api.refundOrder(token, id, amount, reason)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
    
    @action resetFoodOrderTracking() {
        this.foodOrderTracking = undefined
    }
}

export default new OrderStore()
