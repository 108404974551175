import React from 'react'
import { Form, Input, InputNumber, Checkbox, Cascader, Select } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { hasError, getErrorMessage } from '~/lib/errorMessages'
import { groupOptions } from '~/constants/options';

class CreatePospalCategoryForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    renderCategoryOptions(categories) {
        return categories.map((b, index) => {
            const result = {
                key: b.uid,
                value: b.uid,
                label: b.name
            }
            if (b.subcategories) {
                result.children = this.renderCategoryOptions(b.subcategories)
            }
            return result
        })
    }

    getInitialCategory(categories) {
        const { initial } = this.props
        var result = []
        for (var i = 0; i < categories.length; i++) {
            const { subcategories, uid } = categories[i]
            if (uid === initial.category.uid) {
                result.push(uid)
                break
            } else if (subcategories) {
                const temp = this.getInitialCategory(subcategories)
                if (temp.length > 0) {
                    result.push(uid)
                    result = result.concat(temp)
                }
            }
        }
        return result
    }

    getInitialOptionGroup() {
        const { initial } = this.props
        return initial.productTastes
            ? initial.productTastes.map(p => {
                return p.uid
            })
            : []
    }

    getOptionGroupText(optionGroup) {
        return optionGroup.packageName + ' - ' + optionGroup.productAttributes.reduce((accumulator, currentValue, index) => {
            if (index === optionGroup.productAttributes.length - 1) {
                return accumulator + `${currentValue.attributeName} ($${currentValue.attributeValue})`
            } else {
                return accumulator + `${currentValue.attributeName} ($${currentValue.attributeValue}) , `
            }
        }, '')
    }

    render() {
        const { isSubmitting, intl, initial, optionGroups, categories } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        console.log(initial)
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.barcode })}>
                    {
                        getFieldDecorator('barcode', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.barcode : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.barcode })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.pospalCategory })}>
                    {
                        getFieldDecorator('category', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? this.getInitialCategory(categories) : undefined
                        })(
                            <Cascader
                                options={this.renderCategoryOptions(categories)}
                                placeholder={intl.formatMessage({ ...messages.pospalCategory })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.sellPrice })}>
                    {
                        getFieldDecorator('sellPrice', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.sellPrice : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `$ ${value}`}
                                placeholder={intl.formatMessage({ ...messages.sellPrice })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.buyPrice })}>
                    {
                        getFieldDecorator('buyPrice', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.buyPrice : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `$ ${value}`}
                                placeholder={intl.formatMessage({ ...messages.buyPrice })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.memberDiscount })}>
                    {
                        getFieldDecorator('isCustomerDiscount', {
                            valuePropName: 'checked',
                            initialValue: initial ? initial.isCustomerDiscount : false
                        })(
                            <Checkbox />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.memberPrice })}>
                    {
                        getFieldDecorator('customerPrice', {
                            rules: [
                                { required: getFieldValue('isCustomerDiscount'), message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.customerPrice : undefined
                        })(
                            <InputNumber
                                min={0}
                                disabled={isSubmitting || !getFieldValue('isCustomerDiscount')}
                                formatter={value => `$ ${value}`}
                                placeholder={intl.formatMessage({ ...messages.memberPrice })} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.wholesellPrice })}>
                    {
                        getFieldDecorator('sellPrice2', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.sellPrice2 : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `$ ${value}`}
                                placeholder={intl.formatMessage({ ...messages.wholesellPrice })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.stock })}>
                    {
                        getFieldDecorator('stock', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.stock : undefined
                        })(
                            <InputNumber
                                min={0}
                                placeholder={intl.formatMessage({ ...messages.stock })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                {/* <Form.Item
                    label={intl.formatMessage({ ...messages.pospalOptionGroup })}>
                    {
                        getFieldDecorator('optionGroups', {
                            initialValue: initial ? this.getInitialOptionGroup() : undefined
                        })(
                            <Select
                                mode="multiple"
                                placeholder={intl.formatMessage({ ...messages.pospalOptionGroup })}
                                optionLabelProp="label"
                                disabled={isSubmitting}>
                                {
                                    optionGroups.map((g, index) => {
                                        return <Select.Option key={index} value={g.uid} label={g.packageName}>{this.getOptionGroupText(g)}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item> */}
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreatePospalCategoryForm))