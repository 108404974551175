import React from 'react'
import { Form, Row, Col, Select, Button } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'

class PospalSelectForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl, branches } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Row gutter={24}>
                    <Col span={8}>
                        <Form.Item
                            label={intl.formatMessage({ ...messages.branch })}>
                            {
                                getFieldDecorator('branch', {
                                    rules: [
                                        { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                                    ],
                                    initialValue: branches.length > 0 ? branches[0]._id : null
                                })(
                                    <Select
                                        placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                        disabled={isSubmitting}>
                                        {
                                            branches.map((item, index) => {
                                                return <Select.Option value={item._id} key={index}>{item.name}</Select.Option>
                                            })
                                        }
                                    </Select>
                                )
                            }
                        </Form.Item>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            label={' '}
                            colon={false}>
                            <Button type='primary' onClick={() => this.handleSubmit()}>{intl.formatMessage({...messages.enquiry})}</Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(PospalSelectForm))