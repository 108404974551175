import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/lib/imageCompressor'

class PromotionCodeStore {
    @observable isSubmitting = false
    @observable error = null
    @observable mallPromotionCodes = []
    @observable count = 0
    
    createMallPromotionCode = flow(function* (token, code, couponType, minPriceRequired, discountType, discount, maxUsagePerUser, endDate, remark, status, stores, isOnStoreAccount) {
        this.isSubmitting = true
        try {
            yield api.createMallPromotionCode(token, code, couponType, minPriceRequired, discountType, discount, maxUsagePerUser, endDate, remark, status, stores, isOnStoreAccount)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listMallPromotionCode = flow(function* (token, limit, skip,
        sort,
        code,
        status,
        endDateMin,
        endDateMax,
        createdAtMin,
        createdAtMax
    ) {
        this.isSubmitting = true
        try {
            const response = yield api.listMallPromotionCode(token, limit, skip, sort, code, status, endDateMin, endDateMax, createdAtMin, createdAtMax)
            const { codes, count } = response.data
            this.mallPromotionCodes = codes
            this.count = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    getMallPromotionCode = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            yield api.getMallPromotionCode(token, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    updateMallPromotionCode = flow(function* (token, id, code, couponType, minPriceRequired, discountType, discount, maxUsagePerUser, endDate, remark, status, stores, isOnStoreAccount) {
        this.isSubmitting = true
        try {
            const response = yield api.updateMallPromotionCode(token, id, code, couponType, minPriceRequired, discountType, discount, maxUsagePerUser, endDate, remark, status, stores, isOnStoreAccount)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteMallPromotionCode = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            yield api.deleteMallPromotionCode(token, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

}

export default new PromotionCodeStore()
