import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_ONLY_FORMAT, TIME_FORMAT, DATE_FORMAT } from '~/constants/format'
import styled from 'styled-components'
import messages from '~/messages'
import moment from 'moment'
import { Table, Button, message, Modal, Input, Icon, Checkbox, Row, Divider, Tag } from 'antd'
import { statusOptions, pageSizeOptions } from '~/constants/options'
import CreateStoreModal from '~/components/createStoreModal'
import update from 'immutability-helper'
import { LIMIT } from '~/constants/common'
import StoreReportModal from '~/components/storeReportModal'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

@inject('commonStore', 'restaurantStore') @observer
class StoreManagementModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createStoreVisible: false,
            salesReportVisible: false,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                status: [],
            },
            selectedStoreId: undefined,
            sortedInfo: undefined,
            selectedKeys: []
        }
    }

    async handleOnCreateClick(record) {
        this.setState({ createStoreVisible: true, selectedStoreId: undefined })
    }

    async handleOnEditClick(record) {
        this.setState({ createStoreVisible: true, selectedStoreId: record._id })
    }

    async handleOnSalesReportClick(record) {
        this.setState({ salesReportVisible: true, selectedStoreId: record._id })
    }

    async handleOnStoreCreate(values, reset) {
        const { restaurantStore, commonStore, intl, shop } = this.props
        const { token } = commonStore
        const { currentPage, pageSize, selectedStoreId, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        if (selectedStoreId) {
            try {
                await restaurantStore.updateStore(token, shop._id, selectedStoreId, values)
                reset()
                message.success(intl.formatMessage({ ...messages.updateRestaurantStoreSuccess }))
                this.setState({ selectedStoreId: undefined, createStoreVisible: false })
                restaurantStore.listStore(token, shop._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.status
                )
            } catch (e) {
                message.error(intl.formatMessage({ ...messages.updateRestaurantStoreFailure }))
            }
        } else {
            try {
                await restaurantStore.createStore(token, shop._id, values)
                reset()
                message.success(intl.formatMessage({ ...messages.createRestaurantStoreSuccess }))
                this.setState({ selectedStoreId: undefined, createStoreVisible: false })
                restaurantStore.listStore(token, shop._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.name,
                    filterValues.status
                )
            } catch (e) {
                message.error(intl.formatMessage({ ...messages.createRestaurantStoreFailure }))
            }
        }
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { restaurantStore, commonStore, shop } = this.props
        const { token } = commonStore
        const { pageSize } = pagination
        const {
            name,
            status
        } = this.state.filterValues
        restaurantStore.listStore(token, shop._id, pageSize, pageSize * (page - 1),
            sortField,
            name,
            status
        )
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    handleOnSelectedRowsChange(selectedRowKeys, selectedRows) {
        this.setState({ selectedKeys: selectedRowKeys })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                status: [],
            }
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                searchText: undefined,
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.address }),
                dataIndex: 'address',
                key: 'address',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'address' && sortedInfo.order
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'status' && sortedInfo.order,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt, record) => (
                    <span>
                        {moment(record.createdAt).format(DATE_FORMAT)}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 120,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnSalesReportClick(record)}>
                                {intl.formatMessage({ ...messages.salesReport })}
                            </a>
                            <Divider type='vertical' />
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                        </span>
                    )
                }
            }
        ]
    }
    

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { intl, visible, restaurantStore, shop } = this.props
        const { currentPage, pageSize, selectedStoreId } = this.state
        var selectedStoreIndex
        if (selectedStoreId) {
            const index = restaurantStore.stores.findIndex(r => r._id === selectedStoreId)
            selectedStoreIndex = index
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                footer={null}
                title={intl.formatMessage({ ...messages.restaurantStoreManagemant })}
                onCancel={() => this.props.onClose()}>
                <ActionWrapper>
                    <Button type='primary' onClick={() => this.setState({ createStoreVisible: true })}>
                        {intl.formatMessage({ ...messages.createRestaurantStore })}
                    </Button>
                    <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                        {intl.formatMessage({ ...messages.resetAllSearch })}
                    </Button>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={toJS(restaurantStore.stores)}
                    pagination={
                        {
                            showSizeChanger: true,
                            defaultPageSize: LIMIT,
                            pageSizeOptions: pageSizeOptions,
                            showQuickJumper: true,
                            current: +currentPage,
                            pageSize: pageSize,
                            total: restaurantStore.count,
                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                        }
                    }
                    scroll={{ x: 1100 }}
                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                    loading={restaurantStore.isSubmitting}
                    rowKey={record => record._id}
                />
                <CreateStoreModal
                    store={selectedStoreIndex != undefined && selectedStoreIndex > -1 ? toJS(restaurantStore.stores[selectedStoreIndex]) : undefined}
                    visible={this.state.createStoreVisible}
                    isSubmitting={restaurantStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnStoreCreate(values, reset)}
                    onClose={() => this.setState({ createStoreVisible: false, selectedStoreId: undefined })} />
                <StoreReportModal
                    shop={shop}
                    store={selectedStoreIndex != undefined && selectedStoreIndex > -1 ? toJS(restaurantStore.stores[selectedStoreIndex]) : undefined}
                    visible={this.state.salesReportVisible}
                    onClose={() => this.setState({ salesReportVisible: false, selectedStoreId: undefined })} />
            </Modal>
        )
    }
}

export default injectIntl(StoreManagementModal)