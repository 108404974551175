import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, Input, Icon, Checkbox, Row, DatePicker, Tag, Tabs, Dropdown, Menu, message } from 'antd'
import { LIMIT } from '~/constants/common'
import { DATE_FORMAT, DATE_ONLY_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import RetailShopListTable from '~/components/retailShopListTable'
import RestaurantStoreListTable from '~/components/restaurantStoreListTable'
import update from 'immutability-helper'
import pdfMake from "pdfmake/build/pdfmake"
import fontLoader from '~/lib/fontLoader'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    background-color: white;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`
const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const TRANSACTION_FEE_PERCENTAGE = process.env.REACT_APP_TRANSACTION_FEE_PERCENTAGE
const DELIVERY_PLATFORM_FEE_PERCENTAGE = process.env.REACT_APP_DELIVERY_PLATFORM_FEE_PERCENTAGE
const SELF_PICK_UP_PLATFORM_FEE_PERCENTAGE = process.env.REACT_APP_SELF_PICK_UP_PLATFORM_FEE_PERCENTAGE
const DELIVERY_FEE = process.env.REACT_APP_DELIVERY_FEE

@inject('commonStore', 'analysisStore') @observer
class SalesReportPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            range: [moment(), moment()]
        }
    }

    getPdf() {
        return new Promise((resolve, reject) => {
            const { intl, analysisStore } = this.props
            const { range } = this.state
            const { storeSummaries } = analysisStore
            var img = new Image()
            img.crossOrigin = 'anonymous'
            img.onload = function () {
                var canvas = document.createElement('CANVAS')
                const ctx = canvas.getContext('2d')
                canvas.height = img.height
                canvas.width = img.width
                ctx.drawImage(img, 0, 0)
                const dataURL = canvas.toDataURL('image/png')
                const storeSummaryData = toJS(storeSummaries)
                const tableData = storeSummaryData.map(({ _id, store, amount }, index) => {
                    return [
                        { text: `${store.name}` },
                        { text: `HK$${Math.round(amount * 100) / 100}` }
                    ]
                })

                const definition = {
                    content: [
                        { image: dataURL, width: 50, height: 50, alignment: 'center' },
                        { text: 'Shopper', style: 'header', alignment: 'center' },
                        { text: intl.formatMessage({ ...messages.shopSummary }), style: 'header', alignment: 'center' },
                        { text: ' ' },
                        { text: `${intl.formatMessage({ ...messages.date })}: ${range[0].startOf('day').format(DATE_ONLY_FORMAT)} - ${range[1].endOf('day').format(DATE_ONLY_FORMAT)}` },
                        { text: `${intl.formatMessage({ ...messages.currency })}: ${intl.formatMessage({ ...messages.hkd })}` },
                        { text: ' ' },
                        { text: intl.formatMessage({ ...messages.shopperDelivery }) },
                        {
                            table: {
                                headerRows: 1,
                                widths: ['*', 70],
                                body: [
                                    [
                                        { text: intl.formatMessage({ ...messages.shopName }) },
                                        { text: intl.formatMessage({ ...messages.amountReceived }) }
                                    ],
                                    ...tableData
                                ]
                            },
                        },
                        { text: ' ' },
                    ],
                    defaultStyle: {
                        font: 'msyh'
                    }
                }

                fontLoader.load()
                    .then(() => {
                        const doc = pdfMake.createPdf(definition)
                        canvas = null
                        resolve(doc)
                    })
            }
            img.src = `${process.env.PUBLIC_URL}/favicon.ico`
        })
    }

    getXlsxData() {
        const { intl, analysisStore } = this.props
        const { range } = this.state
        const { storeSummaries } = analysisStore
        const storeSummaryData = toJS(storeSummaries)
        const sheetHeader = [
            intl.formatMessage({ ...messages.shopName }),
            intl.formatMessage({ ...messages.deliveryOrderCount }),
            intl.formatMessage({ ...messages.deliveryRevenue }),
            intl.formatMessage({ ...messages.selfPickUpOrderCount }),
            intl.formatMessage({ ...messages.selfPickUpRevenue }),
            intl.formatMessage({ ...messages.totalRevenue }),
            `${intl.formatMessage({ ...messages.shopperPlatformFee })} (${intl.formatMessage({ ...messages.delivery })}:${DELIVERY_PLATFORM_FEE_PERCENTAGE}%)`,
            `${intl.formatMessage({ ...messages.shopperPlatformFee })} (${intl.formatMessage({ ...messages.selfPickUp })}:${SELF_PICK_UP_PLATFORM_FEE_PERCENTAGE}%)`,
            `${intl.formatMessage({ ...messages.shopperPlatformFee })}`,
            intl.formatMessage({ ...messages.transactionFee }),
            intl.formatMessage({ ...messages.refundAmount }),
            intl.formatMessage({ ...messages.couponDiscount }),
            intl.formatMessage({ ...messages.amountReceived }),
        ]

        const data = []
        toJS(storeSummaryData).forEach(d => {
            return data.push([
                d.store.name,
                d.deliveryOrderCount,
                d.deliveryAmount,
                d.selfPickUpOrderCount,
                d.selfPickUpAmount,
                d.totalAmount,
                d.platformFee.delivery,
                d.platformFee.selfPickUp,
                d.platformFee.total,
                d.transactioneFee,
                d.totalRefund,
                d.couponDiscount,
                d.amount,
            ])
        })

        return { sheetHeader, data }
    }

    async handleOnGetClick(mode) {
        const { analysisStore, commonStore } = this.props
        const { token } = commonStore
        const { range } = this.state
        const start = range[0].startOf('day').format()
        const end = range[1].endOf('day').format()
        this.setState({ isMakingPDF: true })
        await analysisStore.listStoreSummary(token, start, end)
        if (mode === 'pdf') {
            const doc = await this.getPdf()
            doc.download(`shopper_sales_summary_${range[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${range[1].endOf('day').format(DATE_ONLY_FORMAT)}.pdf`)
            this.setState({ isMakingPDF: false })
        }
        if (mode === 'csv') {
            const { data, sheetHeader } = this.getXlsxData()
            import('~/lib/xlsxHelper').then(xlsxHelper => {
                xlsxHelper.exportData(sheetHeader, data, `shopper_sales_summary_${range[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${range[1].endOf('day').format(DATE_ONLY_FORMAT)}.xlsx`)
                this.setState({ isMakingPDF: false })
            })
        }
    }

    render() {
        const { intl } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.salesReport })}</title>
                </Helmet>
                <RowWrapper>
                    <div style={{ marginRight: 16 }}>{`${intl.formatMessage({ ...messages.shopSummary })}: `}</div>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={this.state.range}
                        style={{ marginRight: 16 }}
                        onChange={(date) => this.setState({ range: date })}
                        disabledDate={(date) => {
                            return date.diff(moment().startOf('day'), 'day') > 0
                        }} />
                    <Button type='primary' onClick={() => this.handleOnGetClick('pdf')}>PDF</Button>
                    <Button style={{ marginLeft: 8 }} type='primary' onClick={() => this.handleOnGetClick('csv')}>CSV</Button>
                </RowWrapper>

                <Tabs type="card">
                    <Tabs.TabPane tab={intl.formatMessage({ ...messages.shopperDelivery })} key="1">
                        <RestaurantStoreListTable />
                    </Tabs.TabPane>
                    <Tabs.TabPane tab={intl.formatMessage({ ...messages.retail })} key="2">
                        <RetailShopListTable />
                    </Tabs.TabPane>
                </Tabs>
            </Container>
        )
    }
}

export default withRouter(injectIntl(SalesReportPage))