import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_ONLY_FORMAT, TIME_FORMAT, DATE_FORMAT } from '~/constants/format'
import styled from 'styled-components'
import messages from '~/messages'
import moment from 'moment'
import { Table, Button, message, Modal, Divider, Popconfirm, Checkbox, Row } from 'antd'
import { pageSizeOptions } from '~/constants/options'
import CreatePospalModal from '~/components/createPospalModal'
import EditPospalModal from '~/components/editPospalModal'
import { LIMIT } from '~/constants/common'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

@inject('commonStore', 'pospalStore') @observer
class PospalListModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pospalDetailVisible: false,
            createPospalVisible: false,
            editPospalVisible: false,
            pageSize: LIMIT,
            selectedPospalId: undefined,
            sortedInfo: undefined
        }
        this.formRef = React.createRef()
    }

    async handleOnEditClick(record) {
        this.setState({ editPospalVisible: true, selectedPospalId: record._id })
    }

    async handleOnRemoveClick(record) {
        const { pospalStore, commonStore, intl, shop } = this.props
        const { token } = commonStore
        const { currentPage, pageSize } = this.state
        pospalStore.removePospalBranch(token, record._id)
            .then(() => {
                message.success(intl.formatMessage({ ...messages.deletePospalBranchSuccess }))
                pospalStore.listPospalBranch(token, shop._id, pageSize, pageSize * (currentPage - 1))
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.deletePospalBranchFailure }))
            })
    }

    handleOnPospalCreate(values, reset) {
        const { pospalStore, commonStore, intl, shop } = this.props
        const { token } = commonStore
        const { currentPage, pageSize } = this.state
        pospalStore.createPospalBranch(token, shop._id, values)
            .then(() => {
                reset()
                this.setState({ createPospalVisible: false })
                message.success(intl.formatMessage({ ...messages.createPospalBranchSuccess }))
                pospalStore.listPospalBranch(token, shop._id, pageSize, pageSize * (currentPage - 1))
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createPospalBranchFailure }))
            })
    }

    handleOnPospalUpdate(values, reset) {
        const { pospalStore, commonStore, intl, shop } = this.props
        const { token } = commonStore
        pospalStore.updatePospalBranch(token, this.state.selectedPospalId, shop._id, values)
            .then(() => {
                reset()
                this.setState({ editPospalVisible: false, selectedPospalId: undefined })
                message.success(intl.formatMessage({ ...messages.editPospalBranchSuccess }))
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.editPospalBranchFailure }))
            })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { pospalStore, commonStore, product } = this.props
        const { token } = commonStore
        const { pageSize } = pagination
        const {
            timeMin,
            timeMax,
            status
        } = this.state.filterValues
        pospalStore.listPospal(token, product._id, pageSize, pageSize * (page - 1),
            sortField,
            timeMin ? timeMin.format() : undefined,
            timeMax ? timeMax.format() : undefined,
            status
        )
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }


    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
            },
            {
                title: 'App ID',
                dataIndex: 'appId',
                key: 'appId',
            },
            {
                title: 'App Key',
                dataIndex: 'appKey',
                key: 'appKey',
            },
            {
                title: 'Base URL',
                dataIndex: 'baseURL',
                key: 'baseURL',
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            <Divider type='vertical'/>
                            <Popconfirm
                                title={intl.formatMessage({ ...messages.deleteReminder })}
                                onConfirm={() => this.handleOnRemoveClick(record)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                }
            }
        ]
    }

    render() {
        const { intl, visible, pospalStore, shop } = this.props
        const { currentPage, pageSize, selectedPospalId } = this.state
        var selectedPospalIndex
        if (selectedPospalId) {
            const index = pospalStore.branches.findIndex(r => r._id === selectedPospalId)
            selectedPospalIndex = index
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                footer={null}
                title={intl.formatMessage({ ...messages.pospalBranch })}
                onCancel={() => this.props.onClose()}>
                <ActionWrapper>
                    <Button type='primary' onClick={() => this.setState({ createPospalVisible: true })}>
                        {intl.formatMessage({ ...messages.createPospalBranch })}
                    </Button>
                </ActionWrapper>

                <Table
                    columns={this.renderTableColumn()}
                    dataSource={toJS(pospalStore.branches)}
                    pagination={
                        {
                            showSizeChanger: true,
                            defaultPageSize: LIMIT,
                            pageSizeOptions: pageSizeOptions,
                            showQuickJumper: true,
                            current: +currentPage,
                            pageSize: pageSize,
                            total: pospalStore.count,
                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total })}
                        }
                    }
                    scroll={{ x: 1100 }}
                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                    loading={pospalStore.isSubmitting}
                    rowKey={record => record._id}
                />
                {/* <PospalTicketListModal
                    branch={toJS(pospalStore.branches[selectedPospalIndex])}
                    visible={this.state.pospalDetailVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    shop={shop}
                    error={pospalStore.error}
                    onClose={() => this.setState({ pospalDetailVisible: false, selectedPospalId: undefined })} /> */}
                <CreatePospalModal
                    visible={this.state.createPospalVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    shop={shop}
                    error={pospalStore.error}
                    onSubmit={(values, reset) => this.handleOnPospalCreate(values, reset)}
                    onClose={() => this.setState({ createPospalVisible: false })} />
                <EditPospalModal
                    pospal={toJS(pospalStore.branches[selectedPospalIndex])}
                    visible={this.state.editPospalVisible}
                    isSubmitting={pospalStore.isSubmitting}
                    shop={shop}
                    error={pospalStore.error}
                    onSubmit={(values, reset) => this.handleOnPospalUpdate(values, reset)}
                    onClose={() => this.setState({ editPospalVisible: false, selectedPospalId: undefined })} />
            </Modal>
        )
    }
}

export default injectIntl(PospalListModal)