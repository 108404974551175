import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Input, Select, InputNumber, Spin, Button } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { statusOptions } from '~/constants/options'
import validator from '~/lib/validator'
import MemberListModal from '~/components/memberListModal'
import { LIMIT } from '~/constants/common'
import { hasError, getErrorMessage } from '~/lib/errorMessages'

class CreatePospalMemberForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            memberListVisible: false,
            selectedShopperMember: undefined,
            isUnlinked: false
        }
        this.memberListContainer = React.createRef()
    }

    handleSubmit() {
        const { isSubmitting, initial } = this.props
        const { selectedShopperMember, isUnlinked } = this.state
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    if (selectedShopperMember) {
                        values.shopperUserId = selectedShopperMember._id
                    } else if (isUnlinked) {
                        values.shopperUserId = undefined
                    } else if (initial && initial.user) {
                        values.shopperUserId = initial.user._id
                    }
                    this.props.onSubmit(values, () => { this.resetForm() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
        this.setState({ selectedShopperMember: undefined, isUnlinked: false })
        this.memberListContainer.getWrappedInstance().wrappedInstance.resetModal()
    }

    parseMemberNumber(number) {
        const { shop } = this.props
        const index = number.indexOf(shop.initial)
        if (index > -1) {
            return number.substr(shop.initial.length)
        } else {
            return number
        }
    }

    handleOnLink(selectedKey, selectedMember) {
        this.setState({ selectedShopperMember: selectedMember, memberListVisible: false, isUnlinked: false })
    }
    
    async handleOnSelectUserClick() {
        const { userStore, commonStore, shop } = this.props
        const { token } = commonStore
        await userStore.listMember(token, shop._id, LIMIT, 0)
        this.setState({ memberListVisible: true })
    }

    handleOnResetClick() {
        const { initial } = this.props
        this.setState({ selectedShopperMember: initial ? initial.user : undefined, isUnlinked: false })
    }

    handleOnUnlinkClick() {
        this.setState({ selectedShopperMember: undefined, isUnlinked: true })
    }

    renderShopperUser(user) {
        const { intl } = this.props
        return (
            <div>
                <div>{`${intl.formatMessage({...messages.username})}: ${user.username}`}</div>
                <div>{`${intl.formatMessage({...messages.email})}: ${user.email}`}</div>
                <Button onClick={() => this.handleOnSelectUserClick()}>
                    {intl.formatMessage({...messages.changeShopperMember})}
                </Button>
                <Button onClick={() => this.handleOnUnlinkClick()}>
                    {intl.formatMessage({...messages.unlink})}
                </Button>
                <Button onClick={() => this.handleOnResetClick()}>
                    {intl.formatMessage({...messages.reset})}
                </Button>
            </div>
        )
    }

    render() {
        const { isSubmitting, intl, initial, shop, error, userCategories } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.id })}>
                    {
                        initial
                        ? initial.number
                        : getFieldDecorator('number', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            // initialValue: initial ? this.parseMemberNumber(initial.number) : undefined
                        })(
                            <Input
                                addonBefore={shop.initial}
                                placeholder={intl.formatMessage({ ...messages.id })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.phoneNumber })}
                    validateStatus={hasError(error, 1002) ? 'error' : null}
                    help={getErrorMessage(intl, error, 1002)}>
                    {
                        getFieldDecorator('phone', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => validator.validatePhoneNumber(rule, value, callback, intl) }
                            ],
                            initialValue: initial ? initial.phone : undefined
                        })(
                            <Input
                                addonBefore='+852'
                                placeholder={intl.formatMessage({ ...messages.phoneNumber })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.email })}>
                    {
                        getFieldDecorator('email', {
                            rules: [
                                { validator: (rule, value, callback) => validator.validateEmail(rule, value, callback, intl) }
                            ],
                            initialValue: initial ? initial.email : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.email })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.memberTier })}>
                    {
                        getFieldDecorator('categoryName', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.categoryName : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.memberTier })}
                                disabled={isSubmitting} >
                                    { userCategories.map((c, i) => {
                                        return <Select.Option key={i} value={c}>{c}</Select.Option>
                                    })}
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.credit })}>
                    {
                        initial
                        ? initial.point
                        : getFieldDecorator('point', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.point : undefined
                        })(
                            <InputNumber
                                min={0}
                                placeholder={intl.formatMessage({ ...messages.credit })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.memberDiscount })}>
                    {
                        getFieldDecorator('discount', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.discount : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `${value}%`}
                                placeholder={intl.formatMessage({ ...messages.memberDiscount })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.balance })}>
                    {
                        initial
                        ? initial.balance
                        : getFieldDecorator('balance', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.balance : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `$ ${value}`}
                                placeholder={intl.formatMessage({ ...messages.balance })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                {
                    initial
                    ? (
                        <Form.Item
                            label={intl.formatMessage({ ...messages.status })}>
                            {
                                getFieldDecorator('enable', {
                                    rules: [
                                        { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                                    ],
                                    initialValue: initial ? initial.enable : undefined
                                })(
                                    <Select
                                        placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                        disabled={isSubmitting}>
                                            <Select.Option value={1}>{intl.formatMessage({ ...messages.active })}</Select.Option>
                                            <Select.Option value={0}>{intl.formatMessage({ ...messages.inactive })}</Select.Option>
                                    </Select>
                                )
                            }
                        </Form.Item>
                    )
                    : null
                }
                {
                    initial
                    ? (
                        <Form.Item
                            label={intl.formatMessage({ ...messages.linkShopperMember })}
                            validateStatus={hasError(error, 14003) ? 'error' : null}
                            help={getErrorMessage(intl, error, 14003)}>
                            {
                                ((this.state.selectedShopperMember || initial.user) && !this.state.isUnlinked)
                                    ? (
                                        this.renderShopperUser(
                                            this.state.selectedShopperMember ? this.state.selectedShopperMember : initial.user
                                        )
                                    )
                                    : (
                                        <span>
                                            <Button onClick={() => this.handleOnSelectUserClick()}>
                                                {intl.formatMessage({...messages.selectShopperMember})}
                                            </Button>
                                            <Button onClick={() => this.handleOnResetClick()}>
                                                {intl.formatMessage({...messages.reset})}
                                            </Button>
                                        </span>
                                    )
                            }
                        </Form.Item>
                    )
                    : null
                }
                <MemberListModal
                    visible={this.state.memberListVisible}
                    isSubmitting={isSubmitting}
                    onLink={(selectedKey, selectedMember) => this.handleOnLink(selectedKey, selectedMember)}
                    onClose={() => this.setState({ memberListVisible: false })}
                    ref={(inst) => this.memberListContainer = inst} />
            </Form>
        )
    }
}

export default injectIntl(
    inject('commonStore', 'userStore')(
        observer(Form.create()(CreatePospalMemberForm))
    )
)