import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import CreateMallPromotionCodeForm from '~/components/forms/createMallPromotionCodeForm'

class CreateMallPromotionCodeModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, code, error } = this.props
        if (visible) {
            return (
                <Modal
                    visible={visible}
                    mask
                    maskClosable={false}
                    centered
                    width={960}
                    title={code ? intl.formatMessage({ ...messages.editPromotionCode }) : intl.formatMessage({ ...messages.createPromotionCode })}
                    okText={intl.formatMessage({ ...messages.confirm })}
                    cancelText={intl.formatMessage({ ...messages.cancel })}
                    onOk={() => this.formRef.handleSubmit()}
                    onCancel={() => {
                        this.formRef.resetForm()
                        this.props.onClose()
                    }}>
                    {
                        visible
                            ? (
                                <CreateMallPromotionCodeForm
                                    initial={code}
                                    isSubmitting={isSubmitting}
                                    error={error}
                                    onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                                    wrappedComponentRef={(inst) => this.formRef = inst} />
                            )
                            : null
                    }
                </Modal>
            )
        } else {
            return null
        }
    }
}

export default injectIntl(CreateMallPromotionCodeModal)