import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Modal, Select, Spin, Avatar } from 'antd'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import _ from 'lodash'

@inject('commonStore', 'shopStore') @observer
class ShopSelectModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    debounceShopSearch = _.debounce((search) => {
        const { shopStore, commonStore } = this.props
        const { token } = commonStore
        if (search) {
            shopStore.searchShop(token, search)
        }
    }, 250)

    handleOnShopSearch(value) {
        this.debounceShopSearch(value)
    }

    handleOnChange(value) {
        const { shopStore, match, location } = this.props 
        console.log(match)
        console.log(location)
        const index = shopStore.searchedShops.findIndex(s => s._id === value)
        const tempArray = location.pathname.substring(1).split('/')
        shopStore.currentShop = shopStore.searchedShops[index]
        switch (tempArray[0]) {
            case 'shopManagement':
            case 'chainManagement':
                break
            case 'shop':
                this.props.history.push(`/`)
                break
            default:
                break
        }
        this.props.onClose()
    }

    render() {
        const { intl, visible, shopStore, isSubmitting } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.shopName })}
                // okText={intl.formatMessage({ ...messages.confirm })}
                // cancelText={intl.formatMessage({ ...messages.cancel })}
                // onOk={() => this.formRef.handleSubmit()}
                footer={false}
                onCancel={() => {
                    this.props.onClose()
                }}>

                <Select
                    style={{width: '100%'}}
                    showSearch 
                    notFoundContent={shopStore.isSubmitting ? <Spin size="small" /> : null}
                    placeholder={intl.formatMessage({ ...messages.shopName })}
                    defaultActiveFirstOption={false}
                    showArrow={false}
                    filterOption={false}
                    onChange={value => this.handleOnChange(value)}
                    onSearch={value => this.handleOnShopSearch(value)}>
                    {
                        shopStore.searchedShops.map((s) => {
                            return (
                                <Select.Option key={s._id}> <Avatar size="small" src={s.logo} /> {`${s.name} (${s.email})`}</Select.Option>
                            )
                        })
                    }
                </Select>
            </Modal>
        )
    }
}

export default withRouter(injectIntl(ShopSelectModal))