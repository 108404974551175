import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Dropdown, Button, message, Icon, Tree, Menu, Modal } from 'antd'
import messages from '~/messages'
import { Helmet } from 'react-helmet'
import CreateCategoryModal from '~/components/createCategoryModal'
import EditCategoryModal from '~/components/editCategoryModal'
import { hasError, getErrorMessage } from '~/lib/errorMessages'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const TableWrapper = styled.div`
    background-color: white;
`

@inject('commonStore', 'categoryStore', 'shopStore', 'deliveryTimeSlotStore') @observer
class CategoryManagementPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createCategoryVisible: false,
            editCategoryVisible: false,
            selectedCategory: undefined,
            expandedKeys: [],
            autoExpandParent: false,
        }
    }

    async componentDidMount() {
        const { categoryStore, commonStore, shopStore, match } = this.props
        const { token } = commonStore
        await shopStore.getShop(token, match.params._shopId)
        const { currentShop } = shopStore
        await categoryStore.listCategory(token, currentShop._id)
    }

    handleOnCategoryCreate(values, reset) {
        const { categoryStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { selectedCategory } = this.state
        categoryStore.createCategory(token, currentShop._id, selectedCategory ? selectedCategory._id : undefined, values.name, values.status, values.weekdays, values.priority)
            .then(() => {
                reset()
                this.setState({ createCategoryVisible: false, selectedCategory: undefined })
                message.success(intl.formatMessage({ ...messages.createCategorySuccess }))
                categoryStore.listCategory(token, currentShop._id)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createCategoryFailure }))
            })
    }

    handleOnCategoryUpdate(values, reset) {
        const { categoryStore, commonStore, intl, shopStore } = this.props
        const { selectedCategory } = this.state
        const { token } = commonStore
        const { currentShop } = shopStore
        categoryStore.updateCategory(token, currentShop._id, selectedCategory._id, values.name, values.status, values.weekdays, values.priority)
            .then(() => {
                reset()
                this.setState({ editCategoryVisible: false, selectedCategory: undefined })
                message.success(intl.formatMessage({ ...messages.updateCategorySuccess }))
                categoryStore.listCategory(token, currentShop._id)
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateCategoryFailure }))
            })
    }

    async handleOnCategoryDelete(record) {
        const { categoryStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        try {
            await categoryStore.deleteCategory(token, currentShop._id, record._id)
            message.success(intl.formatMessage({ ...messages.deleteCategorySuccess }))
            categoryStore.listCategory(token, currentShop._id)
        } catch (e) {
            if (e && hasError(e, 3005)) {
                Modal.confirm({
                    title: intl.formatMessage({ ...messages.deleteCategoryFailure }),
                    content: getErrorMessage(intl, e, 3005),
                    cancelText: intl.formatMessage({ ...messages.cancel }),
                    onCancel: () => {
                    },
                })
            } else {
                message.error(intl.formatMessage({ ...messages.deleteCategoryFailure }))
            }
        }
    }

    async handleOnCreateClick(record) {
        const { commonStore, shopStore, deliveryTimeSlotStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        if (currentShop.type === 'RESTAURANT_SHOPPER') {
            await deliveryTimeSlotStore.listDeliveryTimeSlot(token, currentShop._id)
        }
        this.setState({ createCategoryVisible: true, selectedCategory: record })
    }

    async handleOnEditClick(record) {
        const { commonStore, categoryStore, shopStore, deliveryTimeSlotStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        await categoryStore.getCategory(token, currentShop._id, record._id)
        if (currentShop.type === 'RESTAURANT_SHOPPER') {
            await deliveryTimeSlotStore.listDeliveryTimeSlot(token, currentShop._id)
        }
        this.setState({ editCategoryVisible: true, selectedCategory: record })
    }

    handleOnDeleteClick(record) {
        const { intl } = this.props
        Modal.confirm({
            title: intl.formatMessage({ ...messages.deleteReminder }),
            content: intl.formatMessage({ ...messages.deleteCategoryReminder }),
            okText: intl.formatMessage({ ...messages.yes }),
            okType: 'danger',
            cancelText: intl.formatMessage({ ...messages.no }),
            onOk: () => {
                this.handleOnCategoryDelete(record)
            },
            onCancel: () => {
            },
        })
    }

    renderActionDropdown(item, isCreateSubcategoryDisable) {
        const { intl, shopStore } = this.props
        const { currentShop } = shopStore
        if (isCreateSubcategoryDisable) {
            return (
                <Dropdown overlay={
                    <Menu>
                        <Menu.Item>
                            <a onClick={() => this.handleOnEditClick(item)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                        </Menu.Item>
                        <Menu.Item>
                            <a onClick={() => this.handleOnDeleteClick(item)}>
                                {intl.formatMessage({ ...messages.delete })}
                            </a>
                        </Menu.Item>
                    </Menu>
                }>
                    <a>
                        {intl.formatMessage({ ...messages.actions })} <Icon type="down" />
                    </a>
                </Dropdown>
            )
        }
        return (
            <Dropdown overlay={
                <Menu>
                    {
                        currentShop.type !== 'RESTAURANT_SHOPPER'
                            ? (
                                <Menu.Item>
                                    <a onClick={() => this.handleOnCreateClick(item)}>
                                        {intl.formatMessage({ ...messages.createSubcategory })}
                                    </a>
                                </Menu.Item>
                            )
                            : null
                    }
                    <Menu.Item>
                        <a onClick={() => this.handleOnEditClick(item)}>
                            {intl.formatMessage({ ...messages.edit })}
                        </a>
                    </Menu.Item>
                    <Menu.Item>
                        <a onClick={() => this.handleOnDeleteClick(item)}>
                            {intl.formatMessage({ ...messages.delete })}
                        </a>
                    </Menu.Item>
                </Menu>
            }>
                <a>
                    {intl.formatMessage({ ...messages.actions })} <Icon type="down" />
                </a>
            </Dropdown>
        )
    }

    renderTreeNodes(data, level) {
        const newLevel = level + 1
        const { intl } = this.props
        return data.map(item => {
            var title
            if (item.subcategories) {
                title = item.status === 'INACTIVE'
                    ? <span style={{ color: 'red' }}>
                        {`${item.name} (${intl.formatMessage({ ...messages.priority })}: ${item.priority ? item.priority : 0}) (${intl.formatMessage({ ...messages.inactive })}, ${intl.formatMessage({ ...messages.subcategoryCount })}: ${item.subcategories ? item.subcategories.length : 0})`}
                        {this.renderActionDropdown(item, newLevel === 3)}
                    </span>
                    : <span>
                        {`${item.name} (${intl.formatMessage({ ...messages.priority })}: ${item.priority ? item.priority : 0}) (${intl.formatMessage({ ...messages.subcategoryCount })}: ${item.subcategories ? item.subcategories.length : 0})`}
                        {this.renderActionDropdown(item, newLevel === 3)}
                    </span>
                return (
                    <Tree.TreeNode
                        title={title}
                        key={item._id}
                        dataRef={item}
                        selectable={false}>
                        {this.renderTreeNodes(item.subcategories, newLevel)}
                    </Tree.TreeNode>
                )
            }

            title = item.status === 'INACTIVE'
                ? <span style={item.status === 'INACTIVE' ? { color: 'red' } : {}}>{`${item.name} (${intl.formatMessage({ ...messages.priority })}: ${item.priority ? item.priority : 0}) (${intl.formatMessage({ ...messages.inactive })})`} {this.renderActionDropdown(item, newLevel === 3)}</span>
                : <span>{`${item.name} (${intl.formatMessage({ ...messages.priority })}: ${item.priority ? item.priority : 0})`} {this.renderActionDropdown(item, newLevel === 3)}</span>
            return (
                <Tree.TreeNode
                    title={title}
                    key={item._id}
                    dataRef={item}
                    selectable={false} />
            )
        })
    }

    render() {
        const { categoryStore, intl, deliveryTimeSlotStore } = this.props
        const { selectedCategory } = this.state
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.categoryManagement })}</title>
                </Helmet>
                <ActionWrapper>
                    <Button type='primary' onClick={() => this.handleOnCreateClick(undefined)}>
                        {intl.formatMessage({ ...messages.addNew })}
                    </Button>
                </ActionWrapper>
                <ActionWrapper>
                    <div>{intl.formatMessage({ ...messages.categoryManagementSuggestionText })}</div>
                </ActionWrapper>
                <TableWrapper>
                    <Tree
                        showLine
                        onExpand={(expandedKeys) => this.setState({ expandedKeys, autoExpandParent: false })}
                        expandedKeys={this.state.expandedKeys}
                        autoExpandParent={this.state.autoExpandParent}>
                        {this.renderTreeNodes(toJS(categoryStore.categories), 0)}
                    </Tree>
                </TableWrapper>
                <CreateCategoryModal
                    visible={this.state.createCategoryVisible}
                    isSubmitting={categoryStore.isSubmitting}
                    error={categoryStore.error}
                    onSubmit={(values, reset) => this.handleOnCategoryCreate(values, reset)}
                    onClose={() => this.setState({ createCategoryVisible: false, selectedCategory: undefined })} />
                <EditCategoryModal
                    category={selectedCategory ? toJS(categoryStore.currentCategory) : undefined}
                    visible={this.state.editCategoryVisible}
                    isSubmitting={categoryStore.isSubmitting}
                    error={categoryStore.error}
                    onSubmit={(values, reset) => this.handleOnCategoryUpdate(values, reset)}
                    onClose={() => this.setState({ editCategoryVisible: false, selectedCategory: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(CategoryManagementPage))