import React from 'react'
import { Form } from 'antd'
import { injectIntl } from 'react-intl'
import FoodOptionSelectTable from '~/components/forms/updateManyProductFoodOptionForm/foodOptionSelectTable'


class UpdateManyProductFoodOptionForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Form.Item>
                    {
                        getFieldDecorator('options', {
                            initial: []
                        })(
                            <FoodOptionSelectTable />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(UpdateManyProductFoodOptionForm))
