import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_FORMAT } from '~/constants/format'
import styled from 'styled-components'
import messages from '~/messages'
import moment from 'moment'
import { Table, Button, message, Modal, DatePicker, Icon, Checkbox, Row, Divider, Popconfirm, Tag } from 'antd'
import { statusOptions, pageSizeOptions } from '~/constants/options'
import CreateReservationModal from '~/components/createReservationModal'
import CreateReservationGroupModal from '~/components/createReservationGroupModal'
import EditReservationModal from '~/components/editReservationModal'
import update from 'immutability-helper'
import { LIMIT } from '~/constants/common'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

@inject('commonStore', 'reservationStore', 'shopStore') @observer
class ReservationManagementModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            createReservationVisible: false,
            createReservationGroupVisible: false,
            editReservationVisible: false,
            pageSize: LIMIT,
            filterValues: {
                timeMin: undefined,
                timeMax: undefined,
                status: ['ACTIVE'],
            },
            selectedReservationId: undefined,
            sortedInfo: undefined,
            selectedKeys: []
        }
        this.formRef = React.createRef()
    }

    handleOnEditClick(record) {
        this.setState({ editReservationVisible: true, selectedReservationId: record._id })
    }

    handleOnReservationCreate(values, reset) {
        const { reservationStore, commonStore, intl, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        reservationStore.createReservation(token, currentShop._id, product._id, values)
            .then(() => {
                reset()
                this.setState({ createReservationVisible: false })
                message.success(intl.formatMessage({ ...messages.createReservationSuccess }))
                reservationStore.listReservation(token, currentShop._id, product._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.timeMin ? filterValues.timeMin.format() : undefined,
                    filterValues.timeMax ? filterValues.timeMax.format() : undefined,
                    filterValues.status
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createReservationFailure }))
            })
    }

    handleOnReservationGroupCreate(values, reset) {
        const { reservationStore, commonStore, intl, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        reservationStore.createReservationGroup(token, currentShop._id, product._id, values)
            .then(() => {
                reset()
                this.setState({ createReservationGroupVisible: false })
                message.success(intl.formatMessage({ ...messages.createReservationGroupSuccess }))
                reservationStore.listReservation(token, currentShop._id, product._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.timeMin ? filterValues.timeMin.format() : undefined,
                    filterValues.timeMax ? filterValues.timeMax.format() : undefined,
                    filterValues.status
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.createReservationGroupFailure }))
            })

    }

    handleOnReservationUpdate(values, reset) {
        const { reservationStore, commonStore, intl, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        reservationStore.updateReservation(token, currentShop._id, product._id, this.state.selectedReservationId, values)
            .then(() => {
                reset()
                this.setState({ editReservationVisible: false, selectedReservationId: undefined })
                message.success(intl.formatMessage({ ...messages.updateReservationSuccess }))
                reservationStore.listReservation(token, currentShop._id, product._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    filterValues.timeMin ? filterValues.timeMin.format() : undefined,
                    filterValues.timeMax ? filterValues.timeMax.format() : undefined,
                    filterValues.status
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateReservationFailure }))
            })
    }

    async handleOnReservationDelete(record) {
        const { reservationStore, commonStore, intl, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { currentPage, pageSize, filterValues, sortedInfo } = this.state
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        try {
            await reservationStore.deleteReservation(token, currentShop._id, product._id, record._id)
            message.success(intl.formatMessage({ ...messages.deleteReservationSuccess }))
            await reservationStore.listReservation(token, currentShop._id, product._id, pageSize, pageSize * (currentPage - 1),
                sortField,
                filterValues.timeMin ? filterValues.timeMin.format() : undefined,
                filterValues.timeMax ? filterValues.timeMax.format() : undefined,
                filterValues.status
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.deleteReservationFailure }))
        }
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { reservationStore, commonStore, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { pageSize } = pagination
        const {
            timeMin,
            timeMax,
            status
        } = this.state.filterValues
        reservationStore.listReservation(token, currentShop._id, product._id, pageSize, pageSize * (page - 1),
            sortField,
            timeMin ? timeMin.format() : undefined,
            timeMax ? timeMax.format() : undefined,
            status
        )
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }


    handleOnSelectedRowsChange(selectedRowKeys, selectedRows) {
        this.setState({ selectedKeys: selectedRowKeys })
    }

    handleOnInactive() {
        const { reservationStore, commonStore, intl, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { selectedKeys, pageSize, currentPage, sortedInfo } = this.state
        var sortField = undefined
        if (sortedInfo) {
            const { order } = sortedInfo
            sortField = order ? (order === 'ascend' ? `${sortedInfo.columnKey}` : `-${sortedInfo.columnKey}`) : null
        }
        const {
            timeMin,
            timeMax,
            status
        } = this.state.filterValues
        reservationStore.inactivateReservations(token, currentShop._id, selectedKeys)
            .then(() => {
                this.setState({ selectedKeys: [] })
                message.success(intl.formatMessage({ ...messages.inactiveReservationSuccess }))
                reservationStore.listReservation(token, currentShop._id, product._id, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    timeMin ? timeMin.format() : undefined,
                    timeMax ? timeMax.format() : undefined,
                    status
                )
            })
            .catch(e => {
                message.error(intl.formatMessage({ ...messages.inactiveReservationFailure }))
            })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'time':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'timeMin': { $set: undefined }, 'timeMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                timeMin: undefined,
                timeMax: undefined,
                status: [],
            }
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'time':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.timeMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'timeMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.timeMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'timeMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />
    })

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.time }),
                dataIndex: 'time',
                key: 'time',
                fixed: 'left',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'time' && sortedInfo.order,
                render: (date) => (
                    <span>
                        {moment(date).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('time')
            },
            {
                title: intl.formatMessage({ ...messages.duration }),
                dataIndex: 'duration',
                key: 'duration',
                fixed: 'left',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'duration' && sortedInfo.order,
                render: (duration) => (
                    intl.formatMessage({ ...messages.durationMinute }, { duration: duration })
                )
            },
            {
                title: intl.formatMessage({ ...messages.userCountMin }),
                dataIndex: 'userCountMin',
                key: 'userCountMin',
                width: 130,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'userCountMin' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.userCountMax }),
                dataIndex: 'userCountMax',
                key: 'userCountMax',
                width: 130,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'userCountMax' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'status' && sortedInfo.order,
                render: (text, record) => {
                    const index = statusOptions.findIndex(c => c.value===text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                )
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 100,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                            <Divider type='vertical' />
                            <Popconfirm
                                title={intl.formatMessage({ ...messages.deleteReminder })}
                                onConfirm={() => this.handleOnReservationDelete(record)}
                                okText={intl.formatMessage({ ...messages.yes })}
                                cancelText={intl.formatMessage({ ...messages.no })}>
                                <a>
                                    {intl.formatMessage({ ...messages.delete })}
                                </a>
                            </Popconfirm>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'timeMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.timeMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'timeMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.timeMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { intl, visible, isSubmitting, reservationStore } = this.props
        const { currentPage, pageSize, selectedReservationId } = this.state
        var selectedReservationIndex
        if (selectedReservationId) {
            const index = reservationStore.reservations.findIndex(r => r._id === selectedReservationId)
            selectedReservationIndex = index
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                footer={null}
                title={intl.formatMessage({ ...messages.reservationManagement })}
                onCancel={() => this.props.onClose()}>
                <ActionWrapper>
                    <Button type='primary' onClick={() => this.setState({ createReservationVisible: true })}>
                        {intl.formatMessage({ ...messages.createReservation })}
                    </Button>
                    <Button type='primary' onClick={() => this.setState({ createReservationGroupVisible: true })}>
                        {intl.formatMessage({ ...messages.createReservationGroup })}
                    </Button>
                    <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                        {intl.formatMessage({ ...messages.resetAllSearch })}
                    </Button>
                    <Button type="primary" onClick={() => this.handleOnInactive()} disabled={this.state.selectedKeys.length === 0}>
                        {intl.formatMessage({ ...messages.inactiveWithCount }, { count: this.state.selectedKeys ? this.state.selectedKeys.length : 0 })}
                    </Button>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={toJS(reservationStore.reservations)}
                    pagination={
                        {
                            showSizeChanger: true,
                            defaultPageSize: LIMIT,
                            pageSizeOptions: pageSizeOptions,
                            showQuickJumper: true,
                            current: +currentPage,
                            pageSize: pageSize,
                            total: reservationStore.count,
                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                        }
                    }
                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                    loading={isSubmitting}
                    rowKey={record => record._id}
                    rowSelection={{
                        fixed: true,
                        hideDefaultSelections: true,
                        selectedRowKeys: this.state.selectedKeys,
                        onChange: (selectedRowKeys, selectedRows) => this.handleOnSelectedRowsChange(selectedRowKeys, selectedRows)
                    }}
                />
                <CreateReservationModal
                    visible={this.state.createReservationVisible}
                    isSubmitting={reservationStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnReservationCreate(values, reset)}
                    onClose={() => this.setState({ createReservationVisible: false })} />
                <CreateReservationGroupModal
                    visible={this.state.createReservationGroupVisible}
                    isSubmitting={reservationStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnReservationGroupCreate(values, reset)}
                    onClose={() => this.setState({ createReservationGroupVisible: false })} />
                <EditReservationModal
                    reservation={toJS(reservationStore.reservations[selectedReservationIndex])}
                    visible={this.state.editReservationVisible}
                    isSubmitting={reservationStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnReservationUpdate(values, reset)}
                    onClose={() => this.setState({ editReservationVisible: false, selectedReservationId: undefined })} />
            </Modal>
        )
    }
}

export default injectIntl(ReservationManagementModal)