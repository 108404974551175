import React from 'react'
import { injectIntl } from 'react-intl'
import styled from 'styled-components'
import { Card, Radio, DatePicker } from 'antd'
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
} from "bizcharts"
import messages from '~/messages'
import moment from 'moment'
import { analysisPeriodModeOptions } from '~/constants/options'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
`

const Title = styled.h3`

`

class FoodOrderSummaryPerShopBarChart extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectedRange: [moment().startOf('month'), moment().endOf('month')],
            deliveryMethod: ['DELIVERY', 'SELF_PICK_UP']
        }
    }

    renderDeliveryMethodButton() {
        const { intl } = this.props
        const { deliveryMethod } = this.state
        var value
        if (deliveryMethod.includes('DELIVERY') && deliveryMethod.includes('SELF_PICK_UP')) {
            value = 'ALL'
        } else {
            value = deliveryMethod[0]
        }
        return (
            <Radio.Group onChange={(e) => this.handleOnDeliveryMethodChange(e.target.value)} defaultValue="ALL" value={value} key={0} style={{ marginRight: 16 }}>
                <Radio.Button key={'ALL'} value={'ALL'}>{intl.formatMessage({ ...messages.all })}</Radio.Button>
                <Radio.Button key={'DELIVERY'} value={'DELIVERY'}>{intl.formatMessage({ ...messages.delivery })}</Radio.Button>
                <Radio.Button key={'SELF_PICK_UP'} value={'SELF_PICK_UP'}>{intl.formatMessage({ ...messages.selfPickUp })}</Radio.Button>
            </Radio.Group>
        )
    }

    handleOnRangeChange(ranges) {
        this.setState({ selectedRange: ranges })
        const startDate = ranges[0].clone().startOf('day')
        const endDate = ranges[1].clone().endOf('day')
        const { deliveryMethod } = this.state
        this.props.onFetchData(startDate, endDate, deliveryMethod)
    }

    async handleOnDeliveryMethodChange(value) {
        const { selectedRange } = this.state
        const startDate = selectedRange[0].clone().startOf('day')
        const endDate = selectedRange[1].clone().endOf('day')
        var deliveryMethod
        if (value === 'ALL') {
            deliveryMethod = ['DELIVERY', 'SELF_PICK_UP']
        } else {
            deliveryMethod = [value]
        }
        this.setState({ deliveryMethod })
        this.props.onFetchData(startDate, endDate, deliveryMethod)
    }

    renderRangePicker() {
        return (
            <DatePicker.RangePicker
                allowClear={false}
                value={this.state.selectedRange}
                onChange={(range) => this.handleOnRangeChange(range)}
            />
        )
    }

    render() {
        const { countData, amountData, intl } = this.props
        return (
            <Card title={
                <ActionWrapper>
                    <ActionLeftWrapper><div>{intl.formatMessage({ ...messages.shopFoodOrderSales })}</div></ActionLeftWrapper>
                    {this.renderDeliveryMethodButton()}
                    {this.renderRangePicker()}
                </ActionWrapper>
            }
                style={{ marginTop: 16 }}>
                <TitleWrapper><Title>{intl.formatMessage({ ...messages.orderCount })}</Title></TitleWrapper>
                <Chart height={300} data={countData} forceFit>
                    <Axis name="name" />
                    <Axis name={intl.formatMessage({ ...messages.orderCount })} />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom type="interval" position={`name*${intl.formatMessage({ ...messages.orderCount })}`} color={'#F4470F'} />
                </Chart>

                <TitleWrapper><Title>{intl.formatMessage({ ...messages.orderTotalAmount })}</Title></TitleWrapper>
                <Chart height={300} data={amountData} forceFit>
                    <Axis name="name" />
                    <Axis name={intl.formatMessage({ ...messages.orderTotalAmount })} />
                    <Tooltip
                        crosshairs={{
                            type: "y"
                        }}
                    />
                    <Geom type="interval" position={`name*${intl.formatMessage({ ...messages.orderTotalAmount })}`} color={'#F4470F'} />
                </Chart>
            </Card >
        )
    }

}

export default injectIntl(FoodOrderSummaryPerShopBarChart)