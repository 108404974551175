import React from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { Layout } from 'antd'
import styled from 'styled-components'
import AppBar from '~/components/appBar'
import Footer from '~/components/footer'
import Sider from '~/components/sider'
import AppIcon from '~/components/appIcon'
import { withRouter } from 'react-router-dom'
import messages from '~/messages'

const TitleWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 64px;
`

const Title = styled.div`
    color: white;
`

@inject('authStore', 'commonStore') @observer
class PageLayout extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            width: 0,
            collapsed: false,
        }
    }

    componentDidMount() {
        this.updateWindowDimensions()
        window.addEventListener('resize', () => this.updateWindowDimensions())
    }

    componentWillUnmount() {
        window.removeEventListener('resize', () => this.updateWindowDimensions())
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth })
    }

    handleOnLogout() {
        this.props.authStore.logout()
            .then(() => {
                this.props.history.push('/login')
            })
    }

    render() {
        const { children, pathname, commonStore, title, intl } = this.props
        const { username } = commonStore
        const isMobile = this.state.width <= 1224

        const childrenWithProps = React.Children.map(children, child =>
            React.cloneElement(child, { isMobile: isMobile })
        )

        return (
            <Layout style={{ minHeight: '100vh', minWidth: '960px' }}>
                <Layout.Sider
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={(collapsed) => this.setState({ collapsed })}>
                    <TitleWrapper>
                        <AppIcon />
                        {
                            !this.state.collapsed 
                                ? <Title>{intl.formatMessage({ ...messages.companyName })}</Title>
                                : null
                        }
                    </TitleWrapper>
                    <Sider isMobile={isMobile} pathname={pathname} />
                </Layout.Sider>
                <Layout>
                    <Layout.Header style={{ padding: 0, height: 64 }}>
                        <AppBar
                            isSiderCollapsed={this.state.collapsed}
                            pathname={pathname}
                            username={username}
                            onMenuFoldClick={() => this.setState({ collapsed: !this.state.collapsed })}
                            onLogout={() => this.handleOnLogout()} />
                    </Layout.Header>
                    <Layout.Content style={{ padding: 16 }}>
                        {childrenWithProps}
                    </Layout.Content>
                    <Layout.Footer style={{ padding: 0 }}>
                        <Footer />
                    </Layout.Footer>
                </Layout>
            </Layout>
        )
    }
}

export default withRouter(injectIntl(PageLayout))