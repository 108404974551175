import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { Modal, Select, Spin, Avatar } from 'antd'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import _ from 'lodash'

@inject('commonStore', 'shopStore') @observer
class ShopSelectModal extends Component {

    debounceShopSearch = _.debounce((search) => {
        const { shopStore, commonStore } = this.props
        const { token } = commonStore
        if (search) {
            shopStore.searchShop(token, search)
        }
    }, 250)

    handleOnShopSearch(value) {
        this.debounceShopSearch(value)
    }

    handleOnChange(value) {
        const { shopStore, match, onChange } = this.props
        onChange(value)
    }

    render() {
        const { intl, value, shopStore, isSubmitting } = this.props
        return (
            <Select
                style={{ width: '100%' }}
                showSearch
                notFoundContent={shopStore.isSubmitting ? <Spin size="small" /> : null}
                placeholder={intl.formatMessage({ ...messages.shopName })}
                defaultActiveFirstOption={false}
                showArrow={false}
                filterOption={false}
                value={value}
                onChange={value => this.handleOnChange(value)}
                onSearch={value => this.handleOnShopSearch(value)}>
                {
                    shopStore.searchedShops.map((s) => {
                        return (
                            <Select.Option key={s._id}> <Avatar size="small" src={s.logo} /> {`${s.name} (${s.email})`}</Select.Option>
                        )
                    })
                }
            </Select>
        )
    }
}

export default withRouter(injectIntl(ShopSelectModal))