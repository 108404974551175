import React, { Component } from 'react'
import { Route, Redirect } from 'react-router-dom'

function isTokenExpired() {
    const tokenExpAt = localStorage.getItem('tokenExpAt')
    const expiresIn = Date.parse(tokenExpAt) - Date.now() // in milliseconds
    return expiresIn < 0
}

function checking() {
    return localStorage.getItem('token') != null
        && !isTokenExpired()
        && (localStorage.getItem('role') === 'ADMIN' || localStorage.getItem('role') === 'SHOPPER_STAFF')
}

export default function PrivateRoute({ component: Component, isAdmin, ...rest }) {
    return (
        <Route
            {...rest}
            render={(props) =>
                checking()
                    ? <Component {...props} />
                    : <Redirect to={{
                        pathname: '/login',
                        state: { from: props.location }
                    }} />
            }
        />
    )
}