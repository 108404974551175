import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/lib/imageCompressor'

class RestaurantStore {
    @observable isSubmitting = false
    @observable error = null
    @observable stores = []
    @observable count = 0
    @observable activeStores = []
    @observable areAllStoreStopped = false
    
    createStore = flow(function* (token, shop, values) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('name', values.name)
        formData.append('address', values.address)
        formData.append('coordinate[]', values.coordinate.lng)
        formData.append('coordinate[]', values.coordinate.lat)
        formData.append('phoneNumber', values.phoneNumber)
        formData.append('description', values.description.toHTML())
        const { businessHours } = values
        formData.append('businessHours', JSON.stringify({
            Sunday: (businessHours[0].fromTime && businessHours[0].toTime) ? [businessHours[0].fromTime, businessHours[0].toTime] : [],
            Monday: (businessHours[1].fromTime && businessHours[1].toTime) ? [businessHours[1].fromTime, businessHours[1].toTime] : [],
            Tuesday: (businessHours[2].fromTime && businessHours[2].toTime) ? [businessHours[2].fromTime, businessHours[2].toTime] : [],
            Wednesday: (businessHours[3].fromTime && businessHours[3].toTime) ? [businessHours[3].fromTime, businessHours[3].toTime] : [],
            Thursday: (businessHours[4].fromTime && businessHours[4].toTime) ? [businessHours[4].fromTime, businessHours[4].toTime] : [],
            Friday: (businessHours[5].fromTime && businessHours[5].toTime) ? [businessHours[5].fromTime, businessHours[5].toTime] : [],
            Saturday: (businessHours[6].fromTime && businessHours[6].toTime) ? [businessHours[6].fromTime, businessHours[6].toTime] : [],
        }))
        formData.append('deliverRadius', values.deliverRadius)
        values.deliveryMethods.forEach(d => {
            formData.append('deliveryMethods[]', d)
        })
        formData.append('kitchenPrintEnabled', values.kitchenPrintEnabled)
        formData.append('deliveryCarrier', values.deliveryCarrier)
        formData.append('status', values.status)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        try {
            yield api.createStore(token, shop, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listStore = flow(function* (token, shop, limit, skip, sort, name, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listStore(token, shop, limit, skip, sort, name, status)
            const { stores, count } = response.data
            this.stores = stores
            this.count = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateStore = flow(function* (token, shop, storeId, values) {
        this.isSubmitting = true
        const formData = new FormData()
        formData.append('name', values.name)
        formData.append('address', values.address)
        formData.append('coordinate[]', values.coordinate.lng)
        formData.append('coordinate[]', values.coordinate.lat)
        formData.append('phoneNumber', values.phoneNumber)
        formData.append('description', values.description.toHTML())
        const { businessHours } = values
        formData.append('businessHours', JSON.stringify({
            Sunday: (businessHours[0].fromTime && businessHours[0].toTime) ? [businessHours[0].fromTime, businessHours[0].toTime] : [],
            Monday: (businessHours[1].fromTime && businessHours[1].toTime) ? [businessHours[1].fromTime, businessHours[1].toTime] : [],
            Tuesday: (businessHours[2].fromTime && businessHours[2].toTime) ? [businessHours[2].fromTime, businessHours[2].toTime] : [],
            Wednesday: (businessHours[3].fromTime && businessHours[3].toTime) ? [businessHours[3].fromTime, businessHours[3].toTime] : [],
            Thursday: (businessHours[4].fromTime && businessHours[4].toTime) ? [businessHours[4].fromTime, businessHours[4].toTime] : [],
            Friday: (businessHours[5].fromTime && businessHours[5].toTime) ? [businessHours[5].fromTime, businessHours[5].toTime] : [],
            Saturday: (businessHours[6].fromTime && businessHours[6].toTime) ? [businessHours[6].fromTime, businessHours[6].toTime] : [],
        }))
        formData.append('deliverRadius', values.deliverRadius)
        values.deliveryMethods.forEach(d => {
            formData.append('deliveryMethods[]', d)
        })
        formData.append('kitchenPrintEnabled', values.kitchenPrintEnabled)
        formData.append('deliveryCarrier', values.deliveryCarrier)
        formData.append('status', values.status)
        for (const f of values.photos) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('photo', thumbnail)
            }
        }
        try {
            yield api.updateStore(token, shop, storeId, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listActiveStore = flow(function* (token, search) {
        this.isSubmitting = true
        try {
            const response = yield api.listActiveStore(token, search)
            this.activeStores = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve(response.data)
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
        }
    })

    listAllShopStore = flow(function* (token, limit, skip, sort, name, status) {
        this.isSubmitting = true
        try {
            const response = yield api.listAllShopStore(token, limit, skip, sort, name, status)
            const { stores, count } = response.data
            this.stores = stores
            this.count = count
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    checkStoreAreAllStopped = flow(function* (token) {
        this.isSubmitting = true
        try {
            const response = yield api.checkStoreAreAllStopped(token)
            this.areAllStoreStopped = response.data
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    stopAllStore = flow(function* (token) {
        this.isSubmitting = true
        try {
            yield api.stopAllStore(token)
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    resumeAllStore = flow(function* (token) {
        this.isSubmitting = true
        try {
            yield api.resumeAllStore(token)
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateStoreStatus = flow(function* (token, storeId, status) {
        console.log(storeId, status)
        this.isSubmitting = true
        try {
            yield api.updateStoreStatus(token, storeId, status)
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateStoreHidden = flow(function* (token, storeId, isHiddenInApp) {
        console.log(storeId, status)
        this.isSubmitting = true
        try {
            yield api.updateStoreHidden(token, storeId, isHiddenInApp)
            this.error = null
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })
}

export default new RestaurantStore()
