import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { Menu, Icon, Avatar } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { withRouter } from 'react-router-dom'
import ShopSelectModal from '~/components/shopSelectModal'

const SiderWrapper = styled(Menu)`
    min-height: 100vh;
`

@inject('commonStore', 'shopStore') @observer
class Sider extends Component {

    constructor(props) {
        super(props)
        this.state = {
            shopSelectVisible: false
        }
    }

    getShopSiderItems() {
        const { shopStore, commonStore } = this.props
        if (!shopStore.currentShop) {
            return []
        }
        var siderItems = [
            {
                key: 'staffManagement',
                value: 'staffManagement',
                text: messages.staffManagement,
                icon: 'team',
                route: 'staffManagement'
            },
            {
                key: 'mainPageAdvertisement',
                value: 'mainPageAdvertisement',
                text: messages.mainPageAdvertisement,
                icon: 'picture',
                route: 'mainPageAdvertisement'
            },
            {
                key: 'feedManagement',
                value: 'feedManagement',
                text: messages.feedManagement,
                icon: 'fire',
                route: 'feedManagement'
            },
            {
                key: 'memberList',
                value: 'memberList',
                text: messages.memberList,
                icon: 'user',
                route: 'memberList'
            },
            {
                key: 'memberCredit',
                value: 'memberCredit',
                text: messages.memberCredit,
                icon: 'red-envelope',
                route: 'memberCredit'
            }
        ]
        switch (shopStore.currentShop.type) {
            case 'RETAIL':
                return [
                    {
                        key: 'product',
                        value: 'product',
                        text: messages.productManagement,
                        icon: 'shopping-cart',
                        subMenu: [
                            { key: 'productList', value: 'productList', route: 'productList', text: messages.productList },
                            { key: 'categoryManagement', value: 'categoryManagement', route: 'categoryManagement', text: messages.categoryManagement },
                            {
                                key: 'productOption',
                                value: 'productOption',
                                route: 'productOption',
                                text: messages.optionManagement
                            },
                            {
                                key: 'productSubchoice',
                                value: 'productSubchoice',
                                route: 'productSubchoice',
                                text: messages.subchoiceManagement
                            },
                            { key: 'productStock', value: 'productStock', route: 'productStock', text: messages.productStock },
                        ]
                    },
                    {
                        key: 'couponManagement',
                        value: 'couponManagement',
                        text: messages.couponManagement,
                        icon: 'tags',
                        route: 'couponManagement'
                    },
                    ...(commonStore.role === 'ADMIN'
                        ? [{
                            key: 'orderManagement',
                            value: 'orderManagement',
                            text: messages.orderManagement,
                            icon: 'credit-card',
                            route: 'orderManagement'
                        }]
                        : []
                    ),
                    ...siderItems,
                    {
                        key: 'dataAnalysis',
                        value: 'dataAnalysis',
                        text: messages.dataAnalysis,
                        icon: 'pie-chart',
                        subMenu: [
                            { key: 'memberAnalysis', value: 'memberAnalysis', route: 'memberAnalysis', text: messages.memberAnalysis },
                            { key: 'memberBehaviorAnalysis', value: 'memberBehaviorAnalysis', route: 'memberBehaviorAnalysis', text: messages.memberBehaviorAnalysis },
                            { key: 'saleAnalysis', value: 'saleAnalysis', route: 'saleAnalysis', text: messages.saleAnalysis },
                        ]
                    },
                    ...(commonStore.role === 'ADMIN'
                        ? [{
                            key: 'notification',
                            value: 'notification',
                            text: messages.pushNotification,
                            icon: 'notification',
                            route: 'notification'
                        }]
                        : []
                    ),
                    {
                        key: 'pospal',
                        value: 'pospal',
                        text: messages.pospalManagement,
                        icon: 'bar-chart',
                        route: 'pospal',
                        subMenu: [
                            { key: 'pospalTickets', value: 'pospalTickets', route: 'pospalTickets', text: messages.pospalTickets },
                            { key: 'pospalTicketPrint', value: 'pospalTicketPrint', route: 'pospalTicketPrint', text: messages.pospalTicketPrint },
                            { key: 'pospalProduct', value: 'pospalProduct', route: 'pospalProduct', text: messages.pospalProduct },
                            { key: 'pospalCategory', value: 'pospalCategory', route: 'pospalCategory', text: messages.pospalCategory },
                            { key: 'pospalMember', value: 'pospalMember', route: 'pospalMember', text: messages.pospalMemberList },
                        ]
                    }
                ]
            case 'RESTAURANT':
                return [
                    {
                        key: 'offlineCoupon',
                        value: 'offlineCoupon',
                        text: messages.offlineCoupon,
                        icon: 'tags',
                        route: 'offlineCoupon'
                    },
                    {
                        key: 'onlineCoupon',
                        value: 'onlineCoupon',
                        text: messages.onlineCoupon,
                        icon: 'tags',
                        route: 'onlineCoupon'
                    },
                    ...(
                        commonStore.role === 'ADMIN'
                            ? [{
                                key: 'foodOrder',
                                value: 'foodOrder',
                                text: messages.orderManagement,
                                icon: 'credit-card',
                                route: 'foodOrder'
                            },
                            {
                                key: 'notification',
                                value: 'notification',
                                text: messages.pushNotification,
                                icon: 'notification',
                                route: 'notification'
                            }]
                            : []
                    ),
                    ...siderItems
                ]
            case 'RESTAURANT_SHOPPER':
                return [
                    {
                        key: 'product',
                        value: 'product',
                        text: messages.productManagement,
                        icon: 'shopping-cart',
                        subMenu: [
                            { key: 'productList', value: 'productList', route: 'productList', text: messages.productList },
                            { key: 'categoryManagement', value: 'categoryManagement', route: 'categoryManagement', text: messages.categoryManagement },
                            {
                                key: 'deliveryTimeSlot',
                                value: 'deliveryTimeSlot',
                                route: 'deliveryTimeSlot',
                                text: messages.deliveryTimeSlot
                            },
                            {
                                key: 'foodOption',
                                value: 'foodOption',
                                route: 'foodOption',
                                text: messages.optionManagement
                            },
                            {
                                key: 'foodSubchoice',
                                value: 'foodSubchoice',
                                route: 'foodSubchoice',
                                text: messages.subchoiceManagement
                            },
                        ]
                    },
                    {
                        key: 'couponManagement',
                        value: 'couponManagement',
                        text: messages.couponManagement,
                        icon: 'tags',
                        route: 'couponManagement'
                    },
                    ...(commonStore.role === 'ADMIN'
                        ? [{
                            key: 'foodOrder',
                            value: 'foodOrder',
                            text: messages.orderManagement,
                            icon: 'credit-card',
                            route: 'foodOrder'
                        }]
                        : []
                    ),
                    {
                        key: 'dataAnalysis',
                        value: 'dataAnalysis',
                        text: messages.dataAnalysis,
                        icon: 'pie-chart',
                        subMenu: [
                            { key: 'memberAnalysis', value: 'memberAnalysis', route: 'memberAnalysis', text: messages.memberAnalysis },
                            { key: 'memberFoodBehaviorAnalysis', value: 'memberFoodBehaviorAnalysis', route: 'memberFoodBehaviorAnalysis', text: messages.memberBehaviorAnalysis },
                            { key: 'foodSaleAnalysis', value: 'foodSaleAnalysis', route: 'foodSaleAnalysis', text: messages.saleAnalysis },
                        ]
                    },
                    ...siderItems,
                ]
            default:
                return []
        }
    }

    handleOnMenuItemClick(e) {
        switch (e.key) {
            case 'shopSelect':
                this.setState({ shopSelectVisible: true })
                break
            case 'home':
                this.props.history.push(`/`)
                break
            case 'shopManagement':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'chainManagement':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'restaurantStatusManagement':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'mallFoodOrder':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'mallOrder':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'mallMemberAnalysis':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'mallPromotionCode':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'shopperReport':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'salesReport':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'shopperBanner':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'mallNotification':
                this.props.history.push(`/${e.item.props.route}`)
                break
            case 'setting':
                this.props.history.push(`/${e.item.props.route}`)
                break
            default:
                const { shopStore } = this.props
                this.props.history.push(`/shop/${shopStore.currentShop._id}/${e.item.props.route}`)
                break
        }
    }

    renderShopMenuItems() {
        const { isMobile, intl } = this.props
        return this.getShopSiderItems().map((item, index) => {

            if (!item.subMenu) {
                return <Menu.Item key={item.key} route={item.route}><span><Icon type={item.icon} /><span>{intl.formatMessage({ ...item.text })}</span></span></Menu.Item>
            }

            const subMenuItems = item.subMenu.map((subMenuItem, subIndex) => {
                return <Menu.Item key={subMenuItem.key} route={subMenuItem.route}>{intl.formatMessage({ ...subMenuItem.text })}</Menu.Item>
            })
            return (
                <Menu.SubMenu key={item.key} title={<span><Icon type={item.icon} /><span>{intl.formatMessage({ ...item.text })}</span></span>}>
                    {subMenuItems}
                </Menu.SubMenu>
            )
        })
    }

    getSelectedKey() {
        if (this.props.location.pathname === '/') {
            return 'home'
        }
        const tempArray = this.props.location.pathname.substring(1).split('/')

        switch (tempArray[0]) {
            case 'shopManagement':
            case 'chainManagement':
            case 'restaurantStatusManagement':
            case 'mallFoodOrder':
            case 'mallOrder':
            case 'mallMemberAnalysis':
            case 'mallPromotionCode':
            case 'shopperReport':
            case 'salesReport':
            case 'shopperBanner':
            case 'mallNotification':
            case 'setting':
                return tempArray[0]
            case 'shop':
                return tempArray[2]
            default:
                return undefined
        }
    }

    getOpenKeys() {
        const tempArray = this.props.location.pathname.substring(1).split('/')
        const selectedPage = tempArray[0]
        if (selectedPage === 'shop') {
            switch (tempArray[2]) {
                case 'saleAnalysis':
                case 'memberAnalysis':
                case 'memberBehaviorAnalysis':
                case 'memberFoodBehaviorAnalysis':
                case 'foodSaleAnalysis':
                    return ['shopSelect', 'dataAnalysis']
                case 'productList':
                case 'categoryManagement':
                case 'productOption':
                case 'productSubchoice':
                case 'foodOption':
                case 'foodSubchoice':
                case 'deliveryTimeSlot':
                case 'productStock':
                    return ['shopSelect', 'product']
                case 'pospalTickets':
                case 'pospalTicketPrint':
                case 'pospalProduct':
                case 'pospalCategory':
                case 'pospalMember':
                    return ['shopSelect', 'pospal']
                default:
                    return ['shopSelect']
            }
        } else {
            return ['shopSelect']
        }
    }

    render() {
        const { intl, shopStore, commonStore } = this.props
        return (
            <SiderWrapper
                theme='dark'
                onClick={(e) => this.handleOnMenuItemClick(e)}
                style={{ maxWidth: 256 }}
                defaultSelectedKeys={[this.getSelectedKey()]}
                selectedKeys={[this.getSelectedKey()]}
                defaultOpenKeys={this.getOpenKeys()}
                mode="inline">
                <Menu.Item
                    key={'home'}
                    route={undefined}>
                    <span><Icon type={'home'} /><span>{intl.formatMessage({ ...messages.home })}</span></span>
                </Menu.Item>
                <Menu.Item
                    key={'shopManagement'}
                    route={'shopManagement'}>
                    <span><Icon type={'shop'} /><span>{intl.formatMessage({ ...messages.shopManagement })}</span></span>
                </Menu.Item>
                <Menu.Item
                    key={'chainManagement'}
                    route={'chainManagement'}>
                    <span><Icon type={'deployment-unit'} /><span>{intl.formatMessage({ ...messages.chainManagement })}</span></span>
                </Menu.Item>
                <Menu.Item
                    key={'restaurantStatusManagement'}
                    route={'restaurantStatusManagement'}>
                    <span><Icon type={'environment'} /><span>{intl.formatMessage({ ...messages.restaurantStatusManagement })}</span></span>
                </Menu.Item>
                <Menu.Item
                    key={'mallPromotionCode'}
                    route={'mallPromotionCode'}>
                    <span><Icon type={'tags'} /><span>{intl.formatMessage({ ...messages.promotionCode })}</span></span>
                </Menu.Item>
                <Menu.Item
                    key={'mallMemberAnalysis'}
                    route={'mallMemberAnalysis'}>
                    <span><Icon type={'user'} /><span>{intl.formatMessage({ ...messages.memberAnalysis })}</span></span>
                </Menu.Item>
                {
                    commonStore.role === 'ADMIN'
                        ? (
                            <Menu.Item
                                key={'mallFoodOrder'}
                                route={'mallFoodOrder'}>
                                <span><Icon type={'car'} /><span>{intl.formatMessage({ ...messages.foodOrder })}</span></span>
                            </Menu.Item>
                        )
                        : null
                }
                {
                    commonStore.role === 'ADMIN'
                        ? (
                            <Menu.Item
                                key={'mallOrder'}
                                route={'mallOrder'}>
                                <span><Icon type={'credit-card'} /><span>{intl.formatMessage({ ...messages.retailOrder })}</span></span>
                            </Menu.Item>
                        )
                        : null
                }
                {
                    commonStore.role === 'ADMIN'
                        ? (
                            <Menu.Item
                                key={'shopperReport'}
                                route={'shopperReport'}>
                                <span><Icon type={'bar-chart'} /><span>{intl.formatMessage({ ...messages.shopperReport })}</span></span>
                            </Menu.Item>
                        )
                        : null
                }
                {
                    commonStore.role === 'ADMIN'
                        ? (
                            <Menu.Item
                                key={'salesReport'}
                                route={'salesReport'}>
                                <span><Icon type={'area-chart'} /><span>{intl.formatMessage({ ...messages.salesReport })}</span></span>
                            </Menu.Item>
                        )
                        : null
                }
                <Menu.Item
                    key={'shopperBanner'}
                    route={'shopperBanner'}>
                    <span><Icon type={'picture'} /><span>{intl.formatMessage({ ...messages.shopperBanner })}</span></span>
                </Menu.Item>
                {
                    commonStore.role === 'ADMIN'
                        ? (
                            <Menu.Item
                                key={'mallNotification'}
                                route={'mallNotification'}>
                                <span><Icon type={'notification'} /><span>{intl.formatMessage({ ...messages.mallNotification })}</span></span>
                            </Menu.Item>
                        )
                        : null
                }
                <Menu.Item
                    key={'setting'}
                    route={'setting'}>
                    <span><Icon type={'setting'} /><span>{intl.formatMessage({ ...messages.setting })}</span></span>
                </Menu.Item>
                {
                    shopStore.currentShop
                        ? (
                            <Menu.SubMenu key={'shopSelect'} title={<span><Avatar size="small" src={shopStore.currentShop.logo} style={{ marginRight: 8 }} /><span>{shopStore.currentShop.name}</span></span>}>
                                {this.renderShopMenuItems()}
                            </Menu.SubMenu>
                        )
                        : (
                            <Menu.Item
                                key={'shopSelect'}>
                                <span><Icon type={'search'} /><span>{intl.formatMessage({ ...messages.selectShop })}</span></span>
                            </Menu.Item>
                        )
                }
                <ShopSelectModal
                    visible={this.state.shopSelectVisible}
                    onClose={() => this.setState({ shopSelectVisible: false })}
                />
            </SiderWrapper>
        )
    }
}

export default withRouter(injectIntl(Sider))
