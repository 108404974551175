import React from 'react'
import { Form, Input, Table, Select, Icon, TimePicker } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import update from 'immutability-helper'
import { getRandomId } from '~/lib/idGenerator'
import { weekOptions } from '~/constants/options'
import moment from 'moment'

class BusinessHourInput extends React.Component {

    handleOnFromTimeChange(day, fromTime) {
        console.log(fromTime)
        const { value, onChange } = this.props
        const data = [...value]
        const index = data.findIndex(v => v.day === day)
        data[index].fromTime = fromTime
        onChange(data)
    }

    handleOnToTimeChange(day, toTime) {
        console.log(toTime)
        const { value, onChange } = this.props
        const data = [...value]
        const index = data.findIndex(v => v.day === day)
        data[index].toTime = toTime
        onChange(data)
    }

    renderTableColumn() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({ ...messages.week }),
                dataIndex: 'day',
                key: 'day',
                render: (text, record) => {
                    const index = weekOptions.findIndex(o => o.value === text)
                    return index > -1 ? intl.formatMessage({ ...weekOptions[index].text }) : '-'
                },
            },
            {
                title: intl.formatMessage({ ...messages.fromTime }),
                dataIndex: 'fromTime',
                key: 'fromTime',
                render: (text, record, index) => {
                    return (
                        <TimePicker
                            value={record.fromTime ? moment(record.fromTime, 'HH:mm:ss') : undefined}
                            onChange={(time, timeString) => this.handleOnFromTimeChange(record.day, timeString)}
                            placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                        />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.toTime }),
                dataIndex: 'toTime',
                key: 'toTime',
                render: (text, record, index) => {
                    return (
                        <TimePicker
                            value={record.toTime ? moment(record.toTime, 'HH:mm:ss') : undefined}
                            onChange={(time, timeString) => this.handleOnToTimeChange(record.day, timeString)}
                            placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                        />
                    )
                },
            },
        ]
    }

    render() {
        const { value } = this.props
        return (
            <Table
                columns={this.renderTableColumn()}
                dataSource={value}
                rowKey={record => record.key}
                pagination={false} />
        )
    }
}

export default injectIntl(BusinessHourInput)