import { observable, flow } from 'mobx'
import api from '../api'

class NotificationStore {
    @observable isSubmitting = false
    @observable error = null
    @observable mallNotifications = []
    @observable mallNotificationCount = 0 
    
    createShopNotification = flow(function* (token, shop, title, body) {
        this.isSubmitting = true
        try {
            yield api.createShopNotification(token, shop, title, body)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    createMallNotification = flow(function* (token, title, body) {
        this.isSubmitting = true
        try {
            yield api.createMallNotification(token, title, body)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listMallNotification = flow(function* (token, limit, skip, sort, createdAtMin, createdAtMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listMallNotification(token, limit, skip, sort, createdAtMin, createdAtMax)
            const { notifications, count } = response.data
            this.mallNotifications = notifications
            this.mallNotificationCount = count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch(error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new NotificationStore()
