import React, { Component } from 'react'
import { Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import CreatePospalProductForm from '~/components/forms/createPospalProductForm'

class EditPospalProductModal extends Component {

    constructor(props) {
        super(props)
        this.formRef = React.createRef()
    }

    render() {
        const { intl, visible, isSubmitting, product, categories, error, optionGroups } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={intl.formatMessage({ ...messages.editProduct })}
                okText={intl.formatMessage({...messages.confirm})}
                cancelText={intl.formatMessage({...messages.cancel})}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                <CreatePospalProductForm
                    initial={product}
                    categories={categories}
                    // optionGroups={optionGroups}
                    isSubmitting={isSubmitting}
                    error={error}
                    onSubmit={(values, reset) => this.props.onSubmit(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst}/>
            </Modal>
        )
    }
}

export default injectIntl(EditPospalProductModal)