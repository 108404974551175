import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/lib/imageCompressor'

class LocationStore {
    @observable isSubmitting = false
    @observable error = null
    @observable predictions = []
    @observable googleMapAddressDetail = undefined

    listGoogleMapAddress = flow(function* (input) {
        this.isSubmitting = true
        try {
            const response = yield api.listGoogleMapAddress(input)
            this.predictions = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCode: error.response.data.errorCode, message: error.response.data.message }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
    
    getGoogleMapAddressDetail = flow(function* (placeId) {
        this.isSubmitting = true
        try {
            const response = yield api.getGoogleMapAddressDetail(placeId)
            this.googleMapAddressDetail = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCode: error.response.data.errorCode, message: error.response.data.message }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

}

export default new LocationStore()
