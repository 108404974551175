import React from 'react'
import { Form, Input } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { hasError, getErrorMessage } from '~/lib/errorMessages'
import validator from '~/lib/validator'

class CreatePospalForm extends React.Component {

    handleSubmit() {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values, () => { this.props.form.resetFields() })
            }
        })
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { isSubmitting, intl, initial, shop, error } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 6 },
            wrapperCol: { span: 14 },
        }
        console.log(error)
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.shop })}>
                    {shop ? shop.name : undefined}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}
                    validateStatus={hasError(error, 13006) ? 'error' : null}
                    help={getErrorMessage(intl, error, 13006)}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label='App ID'
                    validateStatus={hasError(error, 13003) ? 'error' : null}
                    help={getErrorMessage(intl, error, 13003)}>
                    {
                        getFieldDecorator('appId', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.appId : undefined
                        })(
                            <Input
                                placeholder='App ID'
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label='App Key'
                    validateStatus={hasError(error, 13005) ? 'error' : null}
                    help={getErrorMessage(intl, error, 13005)}>
                    {
                        getFieldDecorator('appKey', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.appKey : undefined
                        })(
                            <Input
                                placeholder='App Key'
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label='Base URL'>
                    {
                        getFieldDecorator('baseURL', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => validator.validateUrl(rule, value, callback, intl) }
                            ],
                            initialValue: initial ? initial.baseURL : undefined
                        })(
                            <Input
                                placeholder='Base URL'
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreatePospalForm))