import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, Input, Icon, Checkbox, Row, DatePicker, Tag, Tabs, Dropdown, Menu, message } from 'antd'
import { LIMIT } from '~/constants/common'
import { DATE_FORMAT, DATE_ONLY_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import queryString from 'query-string'
import { statusOptions, pageSizeOptions } from '~/constants/options'
import update from 'immutability-helper'
import pdfMake from "pdfmake/build/pdfmake"
import fontLoader from '~/lib/fontLoader'
import DownloadProgressModal from '~/components/downloadProgressModal'


const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    background-color: white;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`
const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

const TRANSACTION_FEE_PERCENTAGE = process.env.REACT_APP_TRANSACTION_FEE_PERCENTAGE
const RETAIL_SHOPPER_LOGISTICS_FEE_PERCENTAGE = process.env.REACT_APP_RETAIL_SHOPPER_LOGISTICS_FEE_PERCENTAGE
const RETAIL_CUSTOM_LOGISTICS_FEE_PERCENTAGE = process.env.REACT_APP_RETAIL_CUSTOM_LOGISTICS_FEE_PERCENTAGE

@inject('commonStore', 'shopStore', 'analysisStore') @observer
class RetailShopListTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT,
            filterValues: {
                name: undefined,
                email: undefined,
                status: [],
                type: ['RETAIL'],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortedInfo: undefined,
            range: [moment(), moment()],
            selectedRowKeys: [],
            selectedShops: [],
            downloadProgressVisible: false,
            downloadedCount: 0
        }
    }

    async componentDidMount() {
        const { shopStore, commonStore, location } = this.props
        const { token } = commonStore
        const { pageSize, sortedInfo, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await shopStore.listShop(token, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.name,
            filterValues.email,
            filterValues.status,
            filterValues.type,
            filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
            filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
        )
        this.setState({ currentPage })
    }


    getPdf(shop) {
        return new Promise((resolve, reject) => {
            const { intl, analysisStore } = this.props
            const { range } = this.state
            const { retailReport } = analysisStore
            var img = new Image()
            img.crossOrigin = 'anonymous'
            img.onload = function () {
                var canvas = document.createElement('CANVAS')
                const ctx = canvas.getContext('2d')
                canvas.height = img.height
                canvas.width = img.width
                ctx.drawImage(img, 0, 0)
                const dataURL = canvas.toDataURL('image/png')
                const { platformFee, transactionFee, totalRevenue, totalCount, subtotal, dates, couponDiscount, refundTotal } = toJS(retailReport)
                const tableData = dates.map(({ _id, dailyTotalAmount, dailyOrderCount }, index) => {
                    return [
                        moment(_id).format(DATE_ONLY_FORMAT),
                        { text: `${dailyOrderCount}` },
                        { text: `HK$${dailyTotalAmount}` }
                    ]
                })

                const definition = {
                    content: [
                        { image: dataURL, width: 50, height: 50, alignment: 'center' },
                        { text: 'Shopper', style: 'header', alignment: 'center' },
                        { text: intl.formatMessage({ ...messages.onlineSalesReport }), style: 'header', alignment: 'center' },
                        { text: ' ' },
                        { text: intl.formatMessage({ ...messages.to }) },
                        { text: `${shop.name}` },
                        { text: ' ' },
                        { text: `${intl.formatMessage({ ...messages.date })}: ${range[0].startOf('day').format(DATE_ONLY_FORMAT)} - ${range[1].endOf('day').format(DATE_ONLY_FORMAT)}` },
                        { text: `${intl.formatMessage({ ...messages.currency })}: ${intl.formatMessage({ ...messages.hkd })}` },
                        { text: `${intl.formatMessage({ ...messages.orderCount })}: ${totalCount}` },
                        { text: ' ' },
                        {
                            table: {
                                headerRows: 1,
                                widths: ['*', 100, 100],
                                body: [
                                    [
                                        { text: intl.formatMessage({ ...messages.date }) },
                                        { text: intl.formatMessage({ ...messages.orderCount }) },
                                        { text: intl.formatMessage({ ...messages.totalAmount }) }
                                    ],
                                    ...tableData,
                                    [
                                        { text: `${intl.formatMessage({ ...messages.subtotal })}:`, alignment: 'right' },
                                        `${totalCount}`,
                                        `HK$${subtotal}`
                                    ]
                                ]
                            },
                        },
                        { text: ' ' },
                        {
                            table: {
                                headerRows: 1,
                                widths: ['*', 200],
                                body: [
                                    [
                                        { text: intl.formatMessage({ ...messages.subtotal }), alignment: 'right' },
                                        `HK$${subtotal}`,
                                    ],
                                    [
                                        {
                                            text: `${intl.formatMessage({ ...messages.transactionFee })}(${TRANSACTION_FEE_PERCENTAGE}%)`,
                                            alignment: 'right'
                                        },
                                        { text: `HK$${transactionFee}`, color: 'red' },
                                    ],
                                    [
                                        {
                                            text: `${intl.formatMessage({ ...messages.shopperPlatformFee })}(${shop.isCustomLogistics ? RETAIL_CUSTOM_LOGISTICS_FEE_PERCENTAGE : RETAIL_SHOPPER_LOGISTICS_FEE_PERCENTAGE}%)`,
                                            alignment: 'right'
                                        },
                                        { text: `HK$${platformFee}`, color: 'red' },
                                    ],
                                    [
                                        {
                                            text: intl.formatMessage({ ...messages.amountReceived }),
                                            alignment: 'right'
                                        },
                                        { text: `HK$${totalRevenue}`, color: 'green' },
                                    ]
                                ]
                            }
                        },
                        { text: ' ' },
                        { text: `${intl.formatMessage({ ...messages.refundAmount })}: $${refundTotal}`, color: 'red' },
                        { text: `${intl.formatMessage({ ...messages.couponDiscount })}: $${couponDiscount}`, color: 'red' },
                        { text: `${intl.formatMessage({ ...messages.amountReceivedAfterRefund })}: $${totalRevenue - refundTotal - couponDiscount}`, color: 'green' },
                    ],
                    defaultStyle: {
                        font: 'msyh'
                    }
                }

                fontLoader.load()
                    .then(() => {
                        const doc = pdfMake.createPdf(definition)
                        canvas = null
                        resolve(doc)
                    })
            }
            img.src = `${process.env.PUBLIC_URL}/favicon.ico`
        })
    }

    async handleOnGetClick() {
        const { analysisStore, commonStore, shop } = this.props
        const { token } = commonStore
        const { selectedShops, range } = this.state
        const start = range[0].startOf('day').format()
        const end = range[1].endOf('day').format()
        this.setState({ isMakingPDF: true, downloadProgressVisible: true })
        let count = 0
        for (const shop of selectedShops) {
            await analysisStore.getRetailReport(token, shop._id, start, end)
            const doc = await this.getPdf(shop)
            doc.download(`${shop.name}_report_${range[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${range[1].endOf('day').format(DATE_ONLY_FORMAT)}.pdf`)
            count = count + 1
            this.setState({ downloadedCount: count })
        }
        this.setState({ isMakingPDF: false })
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnRowSelect(selectedRowKeys, selectedRows) {
        const { shopStore } = this.props
        const shops = toJS(shopStore.shops)
        this.setState({ selectedRowKeys: selectedRowKeys })
        const filteredShops = _.unionBy(this.state.selectedShops, shops, '_id').filter(p => {
            return selectedRowKeys.includes(p._id)
        })
        this.setState({ selectedShops: filteredShops })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { shopStore, commonStore } = this.props
        const { token } = commonStore
        const { pageSize } = pagination
        const {
            name,
            email,
            status,
            type,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        shopStore.listShop(token, pageSize, pageSize * (page - 1),
            sortField,
            name,
            email,
            status,
            type,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined
        )
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'name':
            case 'email':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'type':
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                name: undefined,
                email: undefined,
                status: [],
                type: ['RETAIL'],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }


    handlePreviewCancel() {
        this.setState({ previewVisible: false, previewImage: undefined })
    }

    handlePreview(url) {
        this.setState({
            previewImage: url,
            previewVisible: true,
        })
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'name':
            case 'email':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {statusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'name' || dataIndex === 'email')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { commonStore } = this.props
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.name }),
                dataIndex: 'name',
                key: 'name',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'name' && sortedInfo.order,
                ...this.getColumnSearchProps('name')
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text) => {
                    const index = statusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...statusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.shopLogo }),
                dataIndex: 'logo',
                key: 'logo',
                width: 150,
                render: (text, record) => {
                    return (
                        <img src={record.logo} height={50} width={50} style={{ objectFit: 'contain' }} />
                    )
                },
            },
            {
                title: intl.formatMessage({ ...messages.email }),
                dataIndex: 'email',
                key: 'email',
                width: 200,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'email' && sortedInfo.order,
                ...this.getColumnSearchProps('email')
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'name':
                        if (filterValues[key]) {
                            tags.push(<Tag key={key}>{`${intl.formatMessage({ ...messages.name })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'email':
                        if (filterValues[key]) {
                            tags.push(<Tag key={key}>{`${intl.formatMessage({ ...messages.email })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = statusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag key={key}>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag key={key}>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag key={key}>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { shopStore, intl } = this.props
        const { currentPage, pageSize } = this.state
        return (
            <Container>
                <RowWrapper>
                    <div style={{ marginRight: 16 }}>{`${intl.formatMessage({ ...messages.date })}: `}</div>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={this.state.range}
                        style={{ marginRight: 16 }}
                        onChange={(date) => this.setState({ range: date })}
                        disabledDate={(date) => {
                            return date.diff(moment().startOf('day'), 'day') > 0
                        }} />
                    <Button type='primary' disabled={this.state.selectedRowKeys.length < 1} onClick={() => this.handleOnGetClick()}>{intl.formatMessage({ ...messages.download })}</Button>
                </RowWrapper>
                {
                    this.state.selectedRowKeys.length > 0
                        ? <ActionWrapper><div>{intl.formatMessage({ ...messages.selectedShopCount }, { count: this.state.selectedRowKeys.length })}</div></ActionWrapper>
                        : null
                }
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(shopStore.shops)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: shopStore.count,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        scroll={{ x: 1000 }}
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={
                            shopStore.isSubmitting
                        }
                        rowSelection={{
                            fixed: true,
                            selectedRowKeys: this.state.selectedRowKeys,
                            onChange: (selectedRowKeys, selectedRows) => this.handleOnRowSelect(selectedRowKeys, selectedRows)
                        }}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <DownloadProgressModal
                    visible={this.state.downloadProgressVisible}
                    totalCount={this.state.selectedRowKeys.length}
                    downloadedCount={this.state.downloadedCount}
                    onClose={() => this.setState({ downloadProgressVisible: false, downloadedCount: 0 })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(RetailShopListTable))