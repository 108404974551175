import { observable, flow } from 'mobx'
import api from '../api'

function getSubcategories(arr, parent) {
    var out = []
    for (var i in arr) {
        if (arr[i].parent == parent) {
            var subcategories = getSubcategories(arr, arr[i]._id)

            if (subcategories.length) {
                arr[i].subcategories = subcategories
            }
            out.push(arr[i])
        }
    }
    return out
}

class CategoryStore {
    @observable isSubmitting = false
    @observable error = null
    @observable categories = []
    @observable rawCategories = []
    @observable currentCategory = undefined

    createCategory = flow(function* (token, shop, parent, name, status, weekdays, priority) {
        this.isSubmitting = true
        try {
            yield api.createCategory(token, shop, parent, name, status, weekdays, priority)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listCategory = flow(function* (token, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listCategory(token, shop)
            const rawCategories = response.data
            this.rawCategories = rawCategories
            this.categories = getSubcategories(rawCategories, undefined)
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    updateCategory = flow(function* (token, shop, id, name, status, weekdays, priority) {
        this.isSubmitting = true
        try {
            yield api.updateCategory(token, shop, id, name, status, weekdays, priority)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getCategory = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            const response = yield api.getCategory(token, shop, id)
            this.currentCategory = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    deleteCategory = flow(function* (token, shop, id) {
        this.isSubmitting = true
        try {
            yield api.deleteCategory(token, shop, id)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject(this.error)
        }
    })

    updateManyProductCategory = flow(function* (token, shop, category, productIds) {
        this.isSubmitting = true
        try {
            yield api.updateManyProductCategory(token, shop, category, productIds)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new CategoryStore()
