import React from 'react'
import { Form, Icon, Input, Button, Checkbox } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import validator from '~/lib/validator'

const SubmitButton = styled(Button)`
    margin-right: 16px;
`

class ResetPasswordForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            confirmDirty: false
        }
    }

    handleSubmit(e) {
        e.preventDefault()
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    const { password, confirmPassword } = values
                    if (password===confirmPassword) {
                        this.props.onSubmit(values)
                    }
                }
            })
        }
    }

    handleConfirmBlur(e) {
        const value = e.target.value
        this.setState({ confirmDirty: this.state.confirmDirty || !!value })
    }

    validateToNextPassword(rule, value, callback) {
        const form = this.props.form
        if (value && this.state.confirmDirty) {
            form.validateFields(['confirmPassword'], { force: true })
        }
        callback()
    }

    compareToFirstPassword(rule, value, callback) {
        const { form, intl } = this.props
        if (value && value !== form.getFieldValue('password')) {
            callback(intl.formatMessage({...messages.confirmPasswordError}))
        } else {
            callback()
        }
    }

    render() {
        const { isSubmitting, intl } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form onSubmit={(e) => this.handleSubmit(e)}>
                <Form.Item
                    label={intl.formatMessage({...messages.newPassword})}>
                    {
                        getFieldDecorator('password', {
                            rules: [
                                { required: true, message: intl.formatMessage({...messages.pleaseInputPassword}) },
                                { min: 4, message: intl.formatMessage({...messages.passwordLengthError}) },
                                { validator: (rule, value, callback) => this.validateToNextPassword(rule, value, callback) }
                            ],
                        })(
                            <Input 
                                type="password"
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                placeholder={intl.formatMessage({...messages.newPassword})}
                                disabled={isSubmitting}/>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({...messages.confirmPassword})}>
                    {
                        getFieldDecorator('confirmPassword', {
                            rules: [
                                { required: true, message: intl.formatMessage({...messages.pleaseConfirmPassword}) },
                                { validator: (rule, value, callback) => this.compareToFirstPassword(rule, value, callback) }
                            ],
                        })(
                            <Input
                                prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />}
                                type="password"
                                placeholder={intl.formatMessage({...messages.confirmPassword})}
                                onBlur={(e) => this.handleConfirmBlur(e)}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    <SubmitButton type="primary" htmlType="submit" loading={isSubmitting}>
                        {intl.formatMessage({...messages.submit})}
                    </SubmitButton>
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(ResetPasswordForm))