import React from 'react'
import { inject, observer } from 'mobx-react'
import { Form, Input, Table, Modal, Icon, Button, InputNumber } from 'antd'
import styled from 'styled-components'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import update from 'immutability-helper'
import { getRandomId } from '~/lib/idGenerator'
import { statusOptions, pageSizeOptions } from '~/constants/options'
import { LIMIT } from '~/constants/common'

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

@inject('commonStore', 'productStore', 'shopStore') @observer
class StockTable extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            pageSize: LIMIT
        }
    }

    handleOnValueChange(index, newValue) {
        var oldStocks = this.props.value
        oldStocks[index] = { ...oldStocks[index], ...newValue }
        this.props.onChange(oldStocks)
    }


    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { pageSize } = pagination
        const { productStore, commonStore, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        productStore.listProductStock(token, currentShop._id, product._id, pageSize, pageSize * (page - 1), sortField)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    renderTableColumn() {
        const { intl, value, options } = this.props
        const optionColumns = options.map((o, j) => {
            return {
                title: o.fieldName,
                key: o._id,
                render: (text, record, index) => {
                    const productOptionId = o._id
                    const choiceIndex = record.choices.findIndex(ch => ch.productOption === productOptionId)
                    return choiceIndex > -1 ? record.choices[choiceIndex].name : null
                }
            }
        })
        const cols = [
            {
                title: intl.formatMessage({ ...messages.priceAdjustment }),
                dataIndex: 'priceAdjustment',
                key: 'priceAdjustment',
                width: 100,
                render: (text, record, index) => {
                    return (
                        <InputNumber
                            value={record.priceAdjustment}
                            onChange={(value) => this.handleOnValueChange(index, { priceAdjustment: value })}
                            formatter={value => `$ ${value}`}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.stock }),
                dataIndex: 'stock',
                key: 'stock',
                width: 100,
                render: (text, record, index) => {
                    return (
                        <InputNumber
                            value={record.stock}
                            min={0}
                            onChange={(value) => this.handleOnValueChange(index, { stock: value })}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })} />
                    )
                }
            },
            {
                title: intl.formatMessage({ ...messages.cost }),
                dataIndex: 'cost',
                key: 'cost',
                width: 100,
                render: (text, record, index) => {
                    return (
                        <InputNumber
                            value={record.cost}
                            min={0}
                            onChange={(value) => this.handleOnValueChange(index, { cost: value })}
                            formatter={value => `$ ${value}`}
                            placeholder={intl.formatMessage({ ...messages.pleaseInput })} />
                    )
                }
            },
        ]

        return optionColumns.concat(cols)
    }

    sortName(aName, bName) {
        if (aName < bName) {
            return -1
        }
        if (aName > bName) {
            return 1
        }
        return 0
    }

    getSortedStocks() {
        const { value, options } = this.props
        console.log(value)
        var result = []
        if (options.length > 0) {
            result = value.sort((a, b) => {
                var v = 0
                for (var i = 0; i < options.length; i++) {
                    const aName = a.choices[i].name
                    const bName = b.choices[i].name
                    const r = this.sortName(aName, bName)
                    if (r !== 0) {
                        v = r
                        break
                    }
                }
                return v
            })
        }
        return result
    }

    render() {
        const { productStore, intl, value } = this.props 
        const { currentPage, pageSize } = this.state
        return (
            <div>
                <Table
                    columns={this.renderTableColumn()}
                    dataSource={value}
                    rowKey={record => record.key}
                    onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                    pagination={
                        {
                            showSizeChanger: true,
                            defaultPageSize: LIMIT,
                            pageSizeOptions: pageSizeOptions,
                            showQuickJumper: true,
                            current: +currentPage,
                            pageSize: pageSize,
                            total: productStore.productStockCount,
                            showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                        }
                    } />
            </div>
        )
    }
}

export default injectIntl(Form.create()(StockTable))