import React from 'react'
import { Form, Input, Select, InputNumber } from 'antd'
import { injectIntl } from 'react-intl'
import { inject, observer } from 'mobx-react'
import messages from '~/messages'
import { statusOptions } from '~/constants/options'
import { hasError, getErrorMessage } from '~/lib/errorMessages'
import AvailabilityTable from '~/components/forms/createCategoryForm/availabilityTable'

class CreateCategoryForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    const { availability } = values
                    var newValues = {}
                    if (availability) {
                        const weekdays = {
                            Sunday: [],
                            Monday: [],
                            Tuesday: [],
                            Wednesday: [],
                            Thursday: [],
                            Friday: [],
                            Saturday: []
                        }
                        availability.forEach(a => {
                            if (a.hasSunday) {
                                weekdays.Sunday.push(a._id)
                            }
                            if (a.hasMonday) {
                                weekdays.Monday.push(a._id)
                            }
                            if (a.hasTuesday) {
                                weekdays.Tuesday.push(a._id)
                            }
                            if (a.hasWednesday) {
                                weekdays.Wednesday.push(a._id)
                            }
                            if (a.hasThursday) {
                                weekdays.Thursday.push(a._id)
                            }
                            if (a.hasFriday) {
                                weekdays.Friday.push(a._id)
                            }
                            if (a.hasSaturday) {
                                weekdays.Saturday.push(a._id)
                            }
                        })
                        newValues = { name: values.name, priority: values.priority, status: values.status, weekdays }
                    } else {
                        newValues = values
                    }
                    this.props.onSubmit(newValues, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    getInitialAvailability(initial) {
        const { deliveryTimeSlotStore } = this.props
        if (!initial) {
            return deliveryTimeSlotStore.deliveryTimeSlots.map(t => {
                return {
                    _id: t._id,
                    name: t.name,
                    startTime: t.startTime,
                    endTime: t.endTime,
                    hasSunday: false,
                    hasMonday: false,
                    hasTuesday: false,
                    hasWednesday: false,
                    hasThursday: false,
                    hasFriday: false,
                    hasSaturday: false
                }
            })
        }
        return initial.availability
    }

    render() {
        const { isSubmitting, intl, initial, error, shopStore, deliveryTimeSlotStore } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}
                    validateStatus={hasError(error, 3003) ? 'error' : null}
                    help={getErrorMessage(intl, error, 3003)}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}>
                    {
                        getFieldDecorator('priority', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.priority : 1
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.priority })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                {
                    shopStore.currentShop.type === 'RESTAURANT_SHOPPER'
                        ? (
                            <Form.Item
                                error={deliveryTimeSlotStore.deliveryTimeSlots.length === 0 ? intl.formatMessage({ ...messages.pleaseCreateDeliveryTimeSlot }) : undefined}
                                label={intl.formatMessage({ ...messages.deliveryTimeSlot })}>
                                {
                                    getFieldDecorator('availability', {
                                        initialValue: this.getInitialAvailability(initial)
                                    })(
                                        <AvailabilityTable />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
            </Form>
        )
    }
}


export default injectIntl(
    inject('commonStore', 'shopStore', 'deliveryTimeSlotStore')(
        observer(Form.create()(CreateCategoryForm))
    )
)