import React from 'react'
import { Form, Input, Select, Radio, InputNumber, DatePicker, Spin, Checkbox, Avatar } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { statusOptions, mallPromotionCodeTypeOptions, mallPromotionCodeCouponTypeOptions } from '~/constants/options'
import validator from '~/lib/validator'
import moment from 'moment'
import { hasError, getErrorMessage } from '~/lib/errorMessages'
import _ from 'lodash'
import { inject, observer } from 'mobx-react'

class CreateMallPromotionCodeForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchedStores: []
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    validateMinPriceRequired(rule, value, callback) {
        const { form, intl } = this.props
        const { getFieldValue } = form
        const discount = getFieldValue('discount')
        const discountType = getFieldValue('discountType')
        var hasError = false
        if (discountType === 'FIXED') {
            if (discount > value) {
                hasError = true
                callback(intl.formatMessage({ ...messages.minPriceRequiredMustNotLessThanDiscount }))
            }
        }
        if (!hasError) {
            callback()
        }
    }

    validateDiscount(rule, value, callback) {
        const { form, intl } = this.props
        const { getFieldValue } = form
        const minPriceRequired = getFieldValue('minPriceRequired')
        const discountType = getFieldValue('discountType')
        var hasError = false
        if (discountType === 'FIXED') {
            if (value > minPriceRequired) {
                hasError = true
                callback(intl.formatMessage({ ...messages.minPriceRequiredMustNotLessThanDiscount }))
            }
        }
        if (discountType === 'RATE') {
            if (value < 0 || value > 100) {
                hasError = true
                callback(intl.formatMessage({ ...messages.minPriceRequiredMustNotLessThanDiscount }))
            }
        }
        if (!hasError) {
            callback()
        }
    }

    debounceStoreSearch = _.debounce(async (search) => {
        const { restaurantStore, commonStore } = this.props
        const { token } = commonStore
        if (search) {
            const activeStores = await restaurantStore.listActiveStore(token, search)
            this.setState({ searchedStores: activeStores })
        }
    }, 250)

    handleOnStoreSearch(value) {
        this.debounceStoreSearch(value)
    }


    getStoreSelectOptions() {
        const { initial } = this.props
        if (initial && initial.stores) {
            const data = [...this.state.searchedStores, ...initial.stores]
            const storeOptions = _.uniqBy(data, (store) => store._id)
            return storeOptions.map((s, index) => {
                return (
                    <Select.Option key={s._id}> <Avatar size="small" src={s.photo} /> {`${s.name} (${s.address})`}</Select.Option>
                )
            })
        } else {
            return this.state.searchedStores.map((s, index) => {
                return (
                    <Select.Option key={s._id}> <Avatar size="small" src={s.photo} /> {`${s.name} (${s.address})`}</Select.Option>
                )
            })
        }
    }

    render() {
        const { isSubmitting, intl, initial, error, restaurantStore } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.promotionCode })}
                    validateStatus={hasError(error, 42005) ? 'error' : null}
                    help={getErrorMessage(intl, error, 42005)}>
                    {
                        getFieldDecorator('code', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => validator.validatePromotionCode(rule, value, callback, intl) },
                            ],
                            normalize: (value, prevValue, allValues) => {
                                const alphanumericRegex = new RegExp("^[a-zA-Z0-9]*$")
                                if (!alphanumericRegex.test(value)) {
                                    return prevValue
                                }
                                if (value) {
                                    return value.toUpperCase()
                                } 
                                return value
                            },
                            initialValue: initial ? initial.code : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.promotionCode })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.usedFor })}>
                    {
                        getFieldDecorator('couponType', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) },
                            ],
                            initialValue: initial ? initial.couponType : undefined
                        })(
                            <Radio.Group>
                                {
                                    mallPromotionCodeCouponTypeOptions.map((item, index) => {
                                        return <Radio value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Radio>
                                    })
                                }
                            </Radio.Group>
                        )
                    }
                </Form.Item>
                {
                    getFieldValue('couponType') === 'DELIVERY' || getFieldValue('couponType') === 'SELF_PICK_UP'
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.specifiedRestaurant })}>
                                {
                                    getFieldDecorator('stores', {
                                        initialValue: initial && initial.stores ? initial.stores.map(s => s._id) : undefined
                                    })(
                                        <Select
                                            style={{ width: '100%' }}
                                            mode='multiple'
                                            showSearch
                                            notFoundContent={restaurantStore.isSubmitting ? <Spin size="small" /> : null}
                                            placeholder={intl.formatMessage({ ...messages.specifiedRestaurant })}
                                            defaultActiveFirstOption={false}
                                            showArrow={false}
                                            filterOption={false}
                                            onSearch={value => this.handleOnStoreSearch(value)}>
                                            {
                                                this.getStoreSelectOptions()
                                            }
                                        </Select>
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                {

                    (getFieldValue('couponType') === 'DELIVERY' || getFieldValue('couponType') === 'SELF_PICK_UP')
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.isOnStoreAccount })}>
                                {
                                    getFieldDecorator('isOnStoreAccount', {
                                        valuePropName: 'checked',
                                        initialValue: initial && initial.stores ? initial.isOnStoreAccount : false,
                                    })(
                                        <Checkbox />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.minPriceRequired })}>
                    {
                        getFieldDecorator('minPriceRequired', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                            ],
                            initialValue: initial ? initial.minPriceRequired : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `$ ${value}`}
                                placeholder={intl.formatMessage({ ...messages.minPriceRequired })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.type })}>
                    {
                        getFieldDecorator('discountType', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) },
                            ],
                            initialValue: initial ? initial.discountType : 'FIXED'
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    mallPromotionCodeTypeOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.discount })}>
                    {
                        getFieldDecorator('discount', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => this.validateDiscount(rule, value, callback) },
                            ],
                            initialValue: initial ? initial.discount : undefined
                        })(
                            getFieldValue('discountType') === 'FIXED'
                                ? (
                                    <InputNumber
                                        disabled={isSubmitting}
                                        min={0}
                                        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={value => value.replace(/\$\s?|(,*)/g, '')}
                                        placeholder={intl.formatMessage({ ...messages.discount })} />
                                )
                                : (
                                    <InputNumber
                                        disabled={isSubmitting}
                                        min={0}
                                        max={100}
                                        formatter={(value) => `${value} %`}
                                        parser={value => value.replace('%', '')}
                                        placeholder={intl.formatMessage({ ...messages.discount })} />
                                )
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.maxUsagePerUser })}>
                    {
                        getFieldDecorator('maxUsagePerUser', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.maxUsagePerUser : undefined
                        })(
                            <InputNumber
                                min={1}
                                formatter={value => intl.formatMessage({ ...messages.frequencyUnit }, { count: value })}
                                placeholder={intl.formatMessage({ ...messages.maxUsagePerUser })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.endDate })}>
                    {
                        getFieldDecorator('endDate', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? moment(initial.endDate) : undefined
                        })(
                            <DatePicker
                                showTime
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.endDate })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.remark })}>
                    {
                        getFieldDecorator('remark', {
                            initialValue: initial ? initial.remark : undefined
                        })(
                            <Input
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.remark })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(
    inject('commonStore', 'restaurantStore')(
        observer(Form.create()(CreateMallPromotionCodeForm))
    )
)