import React from 'react'
import { Form, Select, InputNumber, DatePicker, Checkbox, Button } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { statusOptions, weekOptions } from '~/constants/options'
import ReservationOptionTable from '~/components/forms/createReservationForm/reservationOptionTable'
import TimePickerTable from '~/components/forms/createReservationGroupForm/timePickerTable'
import moment from 'moment'

class CreateReservationGroupForm extends React.Component {

    constructor(props) {
        super(props)
        this.optionTable = React.createRef()
        this.timePickerTable = React.createRef()
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.optionTable.submit()
            this.timePickerTable.submit()
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.resetForm() })
                }
            })
        }
    }
    
    handleOnSelectAllClick() {
        this.props.form.setFieldsValue({ 'week': weekOptions.map(w => w.value) })
    }

    handleOnUnselectAllClick() {

        this.props.form.setFieldsValue({ 'week': [] })
    }


    resetForm() {
        this.props.form.resetFields()
        this.optionTable.reset()
        this.timePickerTable.reset()
    }

    validateTimeSlots(rule, value, callback) {
        const { intl } = this.props
        if (value.length > 0) {
            callback()
        } else {
            callback(intl.formatMessage({ ...messages.pleaseInput }))
        }
    }

    validateOptions(rule, value, callback) {
        const { intl } = this.props
        if (value.length > 0) {
            const isValid = !value.some((option, index) => {
                if (!option.name || !Number.isInteger(option.price) || !Number.isInteger(option.stock) || !Number.isInteger(option.cost) || !option.status) {
                    callback(intl.formatMessage({ ...messages.pleaseInput }))
                    return true
                }
                if (option.price > 0 && option.price < 5) {
                    callback(intl.formatMessage({ ...messages.priceErrorReminder }))
                    return true
                }
                return false
            })
            if (isValid) {
                callback()
            }
        } else {
            callback(intl.formatMessage({ ...messages.pleaseInput }))
        }
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.userCountMin })}>
                    {
                        getFieldDecorator('userCountMin', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                        })(
                            <InputNumber
                                style={{ width: 150 }}
                                min={0}
                                placeholder={intl.formatMessage({ ...messages.userCountMin })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.userCountMax })}>
                    {
                        getFieldDecorator('userCountMax', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                        })(
                            <InputNumber
                                min={0}
                                style={{ width: 150 }}
                                placeholder={intl.formatMessage({ ...messages.userCountMax })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.period })}>
                    {
                        getFieldDecorator('period', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                        })(
                            <DatePicker.RangePicker
                                format={"YYYY-MM-DD"}
                                disabled={isSubmitting}
                                placeholder={[intl.formatMessage({ ...messages.startTime }), intl.formatMessage({ ...messages.endTime })]}
                            />
                        )
                    }
                </Form.Item>

                <Form.Item
                    label={intl.formatMessage({ ...messages.week })}>
                    {
                        getFieldDecorator('week', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: []
                        })(
                            <Checkbox.Group options={
                                weekOptions.map(d => {
                                    return {
                                        value: d.value,
                                        label: intl.formatMessage({ ...d.text })
                                    }
                                })
                            } />
                        )
                    }
                    {
                        getFieldValue('week').length >= 7
                            ? (
                                <Button onClick={() => this.handleOnUnselectAllClick()}>
                                    {intl.formatMessage({ ...messages.unselectAll })}
                                </Button>
                            )
                            : (
                                <Button onClick={() => this.handleOnSelectAllClick()}>
                                    {intl.formatMessage({ ...messages.selectAll })}
                                </Button>
                            )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.time })}>
                    {
                        getFieldDecorator('timeSlots', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => this.validateTimeSlots(rule, value, callback) }
                            ],
                        })(
                            <TimePickerTable
                                reservationId={initial ? initial._id : undefined}
                                initialValue={undefined}
                                wrappedComponentRef={(inst) => this.timePickerTable = inst} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.duration })}>
                    {
                        getFieldDecorator('duration', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                        })(
                            <InputNumber
                                min={0}
                                placeholder={intl.formatMessage({ ...messages.duration })}
                                formatter={value => intl.formatMessage({ ...messages.durationMinute }, { duration: value })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.reservationOption })}>
                    {
                        getFieldDecorator('options', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => this.validateOptions(rule, value, callback) }
                            ],
                        })(
                            <ReservationOptionTable
                                reservationId={initial ? initial._id : undefined}
                                initialValue={initial ? initial.options : undefined}
                                wrappedComponentRef={(inst) => this.optionTable = inst} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateReservationGroupForm))