import React from 'react'
import { Form, Input, Select, Table, List, Checkbox, Modal } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { orderStatusOptions, deliveryMethodOptions } from '~/constants/options'
import { LIMIT } from '~/constants/common'
import moment from 'moment'
import { DATE_FORMAT } from '~/constants/format'

class EditOrderForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    renderProductOptions(record) {
        if (record.choices && record.choices.length > 0) {
            return (
                <div>
                    {
                        record.choices.map((c, i) => {
                            const index = record.detailChoices.findIndex(d => d.choice === c)
                            if (index > -1) {
                                return <div key={i}>{`${c} (${record.detailChoices[index].subchoices.join(', ')})`}</div>
                            } 
                            return <div key={i}>{c}</div>
                        })
                    }
                </div>
            )
        }
        return <div>-</div>
    }

    renderProductTable() {
        const { intl, initial } = this.props
        if (initial) {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.productList })}>
                    <Table
                        columns={[
                            {
                                title: intl.formatMessage({ ...messages.productId }),
                                dataIndex: 'product.productId',
                                key: 'productId',
                            },
                            {
                                title: intl.formatMessage({ ...messages.name }),
                                dataIndex: 'product.name',
                                key: 'name',
                            },
                            {
                                title: intl.formatMessage({ ...messages.quantity }),
                                dataIndex: 'quantity',
                                key: 'quantity',
                            },
                            {
                                title: intl.formatMessage({ ...messages.price }),
                                dataIndex: 'price',
                                key: 'price',
                                render: (text) => {
                                    return `$${text}`
                                }
                            },
                        ]}
                        dataSource={initial.products}
                        pagination={false}
                        defaultExpandAllRows={true}
                        expandedRowRender={record => this.renderProductOptions(record)}
                    />
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderReservationOptionTable(record) {
        const { intl } = this.props
        const { reservation, option, quantity } = record
        const { options } = reservation

        const index = options.findIndex(o => o._id === option)
        if (index > -1) {
            return (
                <div>
                    <div>{`${intl.formatMessage({ ...messages.optionName })}: ${options[index].name}`}</div>
                    <div>{`${intl.formatMessage({ ...messages.quantity })}: ${quantity}`}</div>
                </div>
            )
        } else {
            return <div>-</div>
        }
    }

    renderReservation() {
        const { intl, initial } = this.props
        if (initial) {
            const reservationTable = (
                <Table
                    columns={[
                        {
                            title: intl.formatMessage({ ...messages.name }),
                            dataIndex: 'reservation.product.name',
                            key: 'name',
                        },
                        {
                            title: intl.formatMessage({ ...messages.time }),
                            dataIndex: 'reservation.time',
                            key: 'time',
                            render: (text, record) => {
                                return moment(record.reservation.time).format(DATE_FORMAT)
                            }
                        },
                        {
                            title: intl.formatMessage({ ...messages.duration }),
                            dataIndex: 'reservation.duration',
                            key: 'duration',
                            render: (text, record) => {
                                return intl.formatMessage({ ...messages.durationMinute }, { duration: record.reservation.duration })
                            }
                        },
                        {
                            title: intl.formatMessage({ ...messages.userCountMax }),
                            dataIndex: 'reservation.userCountMax',
                            key: 'userCountMax',
                        },
                        {
                            title: intl.formatMessage({ ...messages.userCountMin }),
                            dataIndex: 'reservation.userCountMin',
                            key: 'userCountMin',
                        },
                        {
                            title: intl.formatMessage({ ...messages.price }),
                            dataIndex: 'price',
                            key: 'price',
                            render: (text) => {
                                return `$${text}`
                            }
                        },
                    ]}
                    dataSource={initial.reservations}
                    pagination={false}
                    defaultExpandAllRows={true}
                    expandedRowRender={(record) => this.renderReservationOptionTable(record)}
                />
            )
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.reservationDetail })}>
                    {reservationTable}
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderUserCoupon() {
        const { intl, initial } = this.props
        if (initial) {
            const couponTable = (
                <Table
                    columns={[
                        {
                            title: intl.formatMessage({ ...messages.couponUsed }),
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: intl.formatMessage({ ...messages.discount }),
                            dataIndex: 'discount',
                            key: 'discount',
                            render: (text) => {
                                return `$${text}`
                            }
                        },
                    ]}
                    dataSource={initial.userCoupons}
                    pagination={false}
                />
            )
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.couponUsed })}>
                    {couponTable}
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderContact() {
        const { intl, initial } = this.props
        if (!initial) {
            return null
        }
        const { contact } = initial
        if (contact) {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.contactDetail })}>
                    <div>{contact.name} </div>
                    <div>{contact.phoneNumber} </div>
                    <div>{contact.room} </div>
                    <div>{contact.street} </div>
                    <div>{contact.district} </div>
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderDeliveryMethod() {
        const { intl, initial } = this.props
        if (!initial) {
            return null
        }
        const { deliveryMethod } = initial
        const index = deliveryMethodOptions.findIndex(d => d.value === deliveryMethod)
        if (index > -1) {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.deliveryMethod })}>
                    {intl.formatMessage({ ...deliveryMethodOptions[index].text })}
                </Form.Item>
            )
        } else {
            return null
        }
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        console.log(initial)
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.orderId })}>
                    {initial ? initial.orderId : undefined}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.createdAt })}>
                    {initial ? moment(initial.createdAt).format(DATE_FORMAT) : undefined}
                </Form.Item>
                {
                    initial && initial.stripeTransactionId
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.transactionId })}>
                                {initial ? initial.stripeTransactionId : undefined}
                            </Form.Item>
                        )
                        : null
                }
                {this.renderProductTable()}
                {this.renderReservation()}
                {
                    initial && initial.userCoupons.length > 0
                        ? this.renderUserCoupon()
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.isCustomLogistics })}>
                    {initial && initial.isCustomLogistics ? intl.formatMessage({ ...messages.yes }) : intl.formatMessage({ ...messages.no })}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.shippingFee })}>
                    {initial && initial.shippingFee ? `$${initial.shippingFee}` : '-'}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.totalPrice })}>
                    {initial ? `$${initial.price}` : undefined}
                </Form.Item>
                {
                    initial && initial.stripeRefundId
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.refundId })}>
                                {initial ? initial.stripeRefundId : undefined}
                            </Form.Item>
                        )
                        : null
                }
                {
                    initial && initial.stripeRefundId
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.refundAmount })}>
                                {`$${initial.refundAmount}`}
                            </Form.Item>
                        )
                        : null
                }
                {
                    initial && initial.stripeRefundId
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.refundReason })}>
                                {initial.refundReason || '-'}
                            </Form.Item>
                        )
                        : null
                }
                {this.renderContact()}
                {this.renderDeliveryMethod()}
                {
                    initial && initial.pickUpStore
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.pickUpStore })}>
                                {initial ? initial.pickUpStore : undefined}
                            </Form.Item>
                        )
                        : null
                }
                {
                    initial && initial.deliveryMethod === 'SFEXPRESS' && initial.status === 'CONFIRMED'
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.trackingNumber })}>
                                {
                                    getFieldDecorator('trackingNumber', {
                                        initialValue: initial ? initial.trackingNumber : undefined
                                    })(
                                        <Input
                                            placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                            disabled={isSubmitting} />
                                    )
                                }
                            </Form.Item>
                        )
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    orderStatusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(EditOrderForm))