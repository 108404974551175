import Joi from 'joi'
import {
    EMAIL_REGEX,
    USERNAME_REGEX,
    NAME_REGEX,
    PASSWORD_REGEX
} from '../constants/regex'
import {
    LONGITUDE_MAX,
    LONGITUDE_MIN,
    LATITUDE_MAX,
    LATITUDE_MIN,
    YEARBUILD_MAX,
    YEARBUILD_MIN,
} from '../constants/common'
import messages from '../messages'

const validateEmail = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.string().email({ minDomainAtoms: 2 }))
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.emailFormatError}))
        })
}

const validateUsername = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.string().alphanum())
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.usernameFormatError}))
        })
}

const validateName = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.string())
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.nameFormatError}))
        })
}

const validateYearBuild = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.number().integer().min(YEARBUILD_MIN).max(YEARBUILD_MAX))
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.yearBuildRangeError}))
        })
}

const validateLongitude = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.number().min(LONGITUDE_MIN).max(LONGITUDE_MAX))
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.longitudeRangeError}))
        })
}

const validateLatitude = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.number().min(LATITUDE_MIN).max(LATITUDE_MAX))
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.latitudeRangeError}))
        })
}

const validateImages = function (rule, value, callback, intl) {
    value.forEach((file, index) => {
        if (!(file.type === 'image/jpeg' || file.type === 'image/png')) {
            callback(intl.formatMessage({ ...messages.imageFormatError }))
        } else {
            callback()
        }
    })
}

const validateInitial = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.string().alphanum().min(3).max(7))
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.initialError}))
        })
}

const validateUrl = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.string().uri())
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.urlError}))
        })
}

const validateExcel = function (rule, value, callback, intl) {
    value.forEach((file, index) => {
        const extension = file.name.split('.').pop().toLowerCase()
        if (extension !== 'xlsx') {
            callback(intl.formatMessage({ ...messages.excelFormatError }))
        } else {
            callback()
        }
    })
}

const validateChoices = function (rule, value, callback, intl) {
    var hasMissingValue = false
    value.forEach(v => {
        if (!v.name) {
            hasMissingValue = true
        }
    })
    if (hasMissingValue) {
        callback(intl.formatMessage({ ...messages.pleaseInput }))
    } else {
        callback()
    }
}

const validatePromotionCode = function (rule, value, callback, intl) {
    Joi.validate(value, Joi.string().alphanum())
        .then(() => callback())
        .catch((error) => {
            console.log(error)
            callback(intl.formatMessage({...messages.codeMustBeAlphanumeric}))
        })
}

export default {
    validateEmail,
    validateUsername,
    validateName,
    validateYearBuild,
    validateLongitude,
    validateLatitude,
    validateImages,
    validateInitial,
    validateUrl,
    validateExcel,
    validateChoices,
    validatePromotionCode
}