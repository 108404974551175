import React from 'react'
import { injectIntl } from 'react-intl'
import { Card, Table, message } from 'antd'
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
} from "bizcharts";
import messages from '~/messages'

class MostAmountMemberChart extends React.Component {

    renderTableColumns() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({...messages.rank}),
                key: 'rank',
                render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                title: intl.formatMessage({...messages.username}),
                key: 'username',
                render: (text, record, index) => {
                    console.log(record)
                    return record.user.username
                }
            },
            {
                title: intl.formatMessage({...messages.email}),
                key: 'email',
                render: (text, record, index) => {
                    console.log(record)
                    return record.user.email
                }
            },
            {
                title: intl.formatMessage({...messages.totalAmount}),
                key: 'totalAmount',
                render: (text, record, index) => {
                    return `$${record.totalAmount}`
                }
            },
        ]
    }

    render() {
        const { data, intl } = this.props
        return (
            <Card title={intl.formatMessage({...messages.mostAmountMember})}>
                <Table columns={this.renderTableColumns()} dataSource={data} pagination={false}/>
            </Card>
        )
    }

}

export default injectIntl(MostAmountMemberChart)