import React from 'react'
import styled from 'styled-components'
import { Form, Input, Select, Table, List, InputNumber, Divider } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { foodOrderStatusOptions, foodDeliveryMethodOptions } from '~/constants/options'
import { LIMIT } from '~/constants/common'
import moment from 'moment'
import { DATE_FORMAT } from '~/constants/format'
import FoodOrderTrackingModal from '~/components/foodOrderTrackingModal'

const DishesWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
`

const DishRowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

const DishName = styled.div`
    flex-grow: 1;
`

const DishPrice = styled.div`

`

class FoodOrderRefundForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    renderDishes() {
        const { intl, initial } = this.props
        if (initial) {
            var subtotal = initial.dishes.reduce((ad, d) => {
                return ad + (d.mainPrice * d.count) + d.sideDishes.reduce((_as, s) => _as + s.sidePrice * d.count, 0)
            }, 0)
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.food })}>
                    <DishesWrapper>
                        {
                            initial.dishes.map((d, i) => {
                                return (
                                    <DishesWrapper key={i}>
                                        <DishRowWrapper key={0}>
                                            <DishName>{`${d.dishname} X ${d.count} `}</DishName>
                                            <DishPrice>{`$${d.mainPrice * d.count}`}</DishPrice>
                                        </DishRowWrapper>
                                        {
                                            d.sideDishes.map((s, j) => {
                                                return (
                                                    <DishRowWrapper key={j + 1}>
                                                        <DishName>{` - ${s.dishname} X ${d.count}`}</DishName>
                                                        <DishPrice>{`$${s.sidePrice * d.count}`}</DishPrice>
                                                    </DishRowWrapper>
                                                )
                                            })
                                        }
                                        {
                                            d.remark
                                                ? <DishRowWrapper>{`${intl.formatMessage({ ...messages.remark })}: ${d.remark}`}</DishRowWrapper>
                                                : null
                                        }
                                        <Divider />
                                    </DishesWrapper>

                                )
                            })
                        }
                        <DishesWrapper key={initial.dishes.length}>
                            <DishRowWrapper key={0}>
                                <DishName></DishName>
                                <DishPrice>{`$${subtotal}`}</DishPrice>
                            </DishRowWrapper>
                        </DishesWrapper>
                    </DishesWrapper>
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderUserCoupon() {
        const { intl, initial } = this.props
        if (initial) {
            const couponTable = (
                <Table
                    columns={[
                        {
                            title: intl.formatMessage({ ...messages.couponUsed }),
                            dataIndex: 'name',
                            key: 'name',
                        },
                        {
                            title: intl.formatMessage({ ...messages.discount }),
                            dataIndex: 'discount',
                            key: 'discount',
                            render: (text) => {
                                return `$${text}`
                            }
                        },
                    ]}
                    dataSource={initial.userCoupons}
                    pagination={false}
                />
            )
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.couponUsed })}>
                    {couponTable}
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderContact() {
        const { intl, initial } = this.props
        if (!initial) {
            return null
        }
        const { contact } = initial
        if (contact) {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.contactDetail })}>
                    <div>{contact.name} </div>
                    <div>{contact.phoneNumber} </div>
                    <div>{contact.room} </div>
                    <div>{contact.street} </div>
                    <div>{contact.district} </div>
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderDeliveryFee() {
        const { intl, initial } = this.props
        if (!initial) {
            return null
        }
        const { deliveryMethod, status } = initial
        if (deliveryMethod === 'DELIVERY') {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.deliveryFee })}>
                    {initial ? `$${initial.deliveryFee}` : undefined}
                </Form.Item>
            )
        } else {
            return '-'
        }
    }

    renderSelfPickUpDiscount() {
        const { intl, initial } = this.props
        if (!initial) {
            return null
        }
        const { deliveryMethod, selfPickUpDiscount } = initial
        if (deliveryMethod === 'SELF_PICK_UP' && selfPickUpDiscount) {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.selfPickUpDiscount })}>
                    {`$${selfPickUpDiscount} (15% off)`}
                </Form.Item>
            )
        } else {
            return null
        }
    }

    renderPromotionCodeDiscount() {
        const { intl, initial } = this.props
        if (!initial) {
            return null
        }
        const { redeemedMallPromotionCode, promotionCodeDiscount } = initial
        if (redeemedMallPromotionCode && promotionCodeDiscount) {
            const { promotionCode, discountType, discount } = redeemedMallPromotionCode
            if (discountType === 'RATE') {
                return (
                    <Form.Item
                        label={intl.formatMessage({ ...messages.promotionCodeDiscount })}>
                        {`$${promotionCodeDiscount} [${promotionCode.code} (${100 - discount}% off)]`}
                    </Form.Item>
                )
            }
            if (discountType === 'FIXED') {
                return (
                    <Form.Item
                        label={intl.formatMessage({ ...messages.promotionCodeDiscount })}>
                        {`$${promotionCodeDiscount} [${promotionCode.code} (-$${discount})]`}
                    </Form.Item>
                )
            }
            return null
        } else {
            return null
        }
    }

    renderDeliveryMethod() {
        const { intl, initial } = this.props
        if (!initial) {
            return null
        }
        const { deliveryMethod, status } = initial
        const index = foodDeliveryMethodOptions.findIndex(d => d.value === deliveryMethod)
        if (index > -1) {
            return (
                <Form.Item
                    label={intl.formatMessage({ ...messages.deliveryMethod })}>
                    {intl.formatMessage({ ...foodDeliveryMethodOptions[index].text })}
                </Form.Item>
            )
        } else {
            return null
        }
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        const statusIndex = foodOrderStatusOptions.findIndex(o => o.value === initial.status)
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.orderId })}>
                    {initial ? initial.orderId : undefined}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.createdAt })}>
                    {initial ? moment(initial.createdAt).format(DATE_FORMAT) : undefined}
                </Form.Item>
                {
                    initial && initial.stripeTransactionId
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.transactionId })}>
                                {initial ? initial.stripeTransactionId : undefined}
                            </Form.Item>
                        )
                        : null
                }
                {this.renderDishes()}
                {
                    initial && initial.userCoupons.length > 0
                        ? this.renderUserCoupon()
                        : null
                }
                {this.renderPromotionCodeDiscount()}
                {this.renderDeliveryFee()}
                {this.renderSelfPickUpDiscount()}
                <Form.Item
                    label={intl.formatMessage({ ...messages.totalPrice })}>
                    {initial ? `$${initial.totalPrice}` : undefined}
                </Form.Item>
                {this.renderContact()}
                {this.renderDeliveryMethod()}
                {
                    initial && initial.pickUpStore
                        ? (
                            <Form.Item
                                label={intl.formatMessage({ ...messages.pickUpStore })}>
                                {initial ? initial.pickUpStore : undefined}
                            </Form.Item>
                        )
                        : null
                }
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {statusIndex > -1 ? intl.formatMessage({ ...foodOrderStatusOptions[statusIndex].text }) : '-'}
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.refundAmount })}>
                    {
                        getFieldDecorator('amount', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial.totalPrice
                        })(
                            <InputNumber
                                min={0}
                                max={initial.totalPrice}
                                formatter={value => `$${value}`}
                                placeholder={intl.formatMessage({ ...messages.refundAmount })}
                                disabled={isSubmitting}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.reason })}>
                    {
                        getFieldDecorator('reason', {
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.reason })}
                                disabled={isSubmitting}
                            />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(FoodOrderRefundForm))