import { observable, flow } from 'mobx'
import api from '../api'
import { makeThumbnail, makeOriginal } from '~/lib/imageCompressor'
import commonStore from './commonStore'

class ShopStore {
    @observable isSubmitting = false
    @observable error = null
    @observable currentShop = undefined
    @observable shops = []
    @observable searchedShops = []
    @observable count = 0
    @observable chains = []
    @observable chainCount = 0

    createShop = flow(function* (token, values) {
        const formData = new FormData()
        formData.append('type', values.type)
        formData.append('name', values.name)
        formData.append('email', values.email)
        formData.append('address', values.address)
        formData.append('initial', values.initial.toUpperCase())
        formData.append('phoneNumber', values.phoneNumber)
        formData.append('website', values.website)
        formData.append('description', values.description.toHTML())
        formData.append('terms', values.terms.toHTML())
        formData.append('status', values.status)
        values.stores.forEach(s => formData.append('stores[]', s.address))
        if (values.deliveryMethods) {
            values.deliveryMethods.forEach(m => formData.append('deliveryMethods[]', m))
        }
        if (values.type === 'RETAIL') {
            formData.append('isCustomLogistics', values.isCustomLogistics)
        }
        if (values.freeCustomShippingPrice) {
            formData.append('freeCustomShippingPrice', values.freeCustomShippingPrice)
        }
        if (values.nonfreeCustomShippingFee) {
            formData.append('nonfreeCustomShippingFee', values.nonfreeCustomShippingFee)
        }
        if (values.appleAppStoreLink) {
            formData.append('appleAppStoreLink', values.appleAppStoreLink)
        }
        if (values.googlePlayStoreLink) {
            formData.append('googlePlayStoreLink', values.googlePlayStoreLink)
        }
        if (values.domainName) {
            formData.append('domainName', values.domainName)
        }
        if (values.mallDomainName) {
            formData.append('mallDomainName', values.mallDomainName)
        }
        if (values.facebookAppId) {
            formData.append('facebookAppId', values.facebookAppId)
        }
        if (values.websiteThemeColor) {
            formData.append('websiteThemeColor', values.websiteThemeColor)
        }
        if (values.fcm) {
            formData.append('fcm', values.fcm)
        }
        if (values.scheme) {
            formData.append('scheme', values.scheme)
        }
        if (values.clonedShop) {
            formData.append('clonedShop', values.clonedShop)
        }
        for (const f of values.logo) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeThumbnail(originFileObj)
                formData.append('logo', thumbnail)
            }
        }
        for (const f of values.feedCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('feedCover', thumbnail)
            }
        }
        for (const f of values.searchCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('searchCover', thumbnail)
            }
        }
        for (const f of values.couponCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('couponCover', thumbnail)
            }
        }
        for (const f of values.loginLogo) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('loginLogo', thumbnail)
            }
        }
        for (const f of values.loginBackground) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('loginBackground', thumbnail)
            }
        }
        this.isSubmitting = true
        try {
            yield api.createShop(token, formData)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listShop = flow(function* (token, limit, skip, sort, name, email, status, type, createdAtMin, createdAtMax) {
        this.isSubmitting = true
        try {
            const response = yield api.listShop(token, limit, skip, sort, name, email, status, type, createdAtMin, createdAtMax)
            const { shops, count } = response.data
            this.shops = shops
            this.count = count
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    editShop = flow(function* (token, id, values) {
        console.log(values)
        const formData = new FormData()
        formData.append('type', values.type)
        formData.append('name', values.name)
        formData.append('email', values.email)
        formData.append('address', values.address)
        formData.append('phoneNumber', values.phoneNumber)
        formData.append('website', values.website)
        formData.append('description', values.description.toHTML())
        formData.append('terms', values.terms.toHTML())
        formData.append('status', values.status)
        values.stores.forEach(s => formData.append('stores[]', s.address))

        if (values.deliveryMethods) {
            values.deliveryMethods.forEach(m => formData.append('deliveryMethods[]', m))
        }
        if (values.type === 'RETAIL') {
            formData.append('isCustomLogistics', values.isCustomLogistics)
        }
        if (values.freeCustomShippingPrice) {
            formData.append('freeCustomShippingPrice', values.freeCustomShippingPrice)
        }
        if (values.nonfreeCustomShippingFee) {
            formData.append('nonfreeCustomShippingFee', values.nonfreeCustomShippingFee)
        }
        if (values.appleAppStoreLink) {
            formData.append('appleAppStoreLink', values.appleAppStoreLink)
        }
        if (values.googlePlayStoreLink) {
            formData.append('googlePlayStoreLink', values.googlePlayStoreLink)
        }
        if (values.domainName) {
            formData.append('domainName', values.domainName)
        }
        if (values.mallDomainName) {
            formData.append('mallDomainName', values.mallDomainName)
        }
        if (values.facebookAppId) {
            formData.append('facebookAppId', values.facebookAppId)
        }
        if (values.websiteThemeColor) {
            formData.append('websiteThemeColor', values.websiteThemeColor)
        }
        if (values.fcm) {
            formData.append('fcm', values.fcm)
        }
        if (values.scheme) {
            formData.append('scheme', values.scheme)
        }
        for (const f of values.logo) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeThumbnail(originFileObj)
                formData.append('logo', thumbnail)
            }
        }
        for (const f of values.feedCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('feedCover', thumbnail)
            }
        }
        for (const f of values.searchCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('searchCover', thumbnail)
            }
        }
        for (const f of values.couponCover) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('couponCover', thumbnail)
            }
        }
        for (const f of values.loginLogo) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('loginLogo', thumbnail)
            }
        }
        for (const f of values.loginBackground) {
            const { originFileObj } = f
            if (originFileObj) {
                const thumbnail = yield makeOriginal(originFileObj)
                formData.append('loginBackground', thumbnail)
            }
        }
        this.isSubmitting = true
        try {
            const response = yield api.editShop(token, id, formData)
            const index = this.shops.findIndex(s => s._id === id)
            this.shops[index] = response.data
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    getShop = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            const response = yield api.getShop(token, id)
            this.currentShop = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })


    searchShop = flow(function* (token, query) {
        this.isSubmitting = true
        try {
            const response = yield api.searchShop(token, query)
            this.searchedShops = response.data
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    deleteShop = flow(function* (token, id) {
        this.isSubmitting = true
        try {
            yield api.deleteShop(token, id)
            this.error = null
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
        }
        this.isSubmitting = false
    })

    createChain = flow(function* (token, name, shops, rate) {
        this.isSubmitting = true
        try {
            yield api.createChain(token, name, shops, rate)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    editChain = flow(function* (token, id, name, shops, rate) {
        this.isSubmitting = true
        try {
            const response = yield api.updateChain(token, id, name, shops, rate)
            console.log(response)
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })

    listChain = flow(function* (token, limit, skip, sort, name, shop) {
        this.isSubmitting = true
        try {
            const response = yield api.listChain(token, limit, skip, sort, name, shop)
            this.chains = response.data.chains
            this.chainCount = response.data.count
            this.error = null
            this.isSubmitting = false
            return Promise.resolve()
        } catch (error) {
            console.log(error)
            this.error = { errorCodes: error.response.data.errorCodes, messages: error.response.data.messages }
            this.isSubmitting = false
            return Promise.reject()
        }
    })
}

export default new ShopStore()
