import React from 'react'
import { Form } from 'antd'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import OptionSelectTable from '~/components/forms/addProductOptionForm/optionSelectTable'


class AddProductOptionForm extends React.Component {

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    render() {
        const { initial, product } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Form.Item>
                    {
                        getFieldDecorator('options', {
                            initialValue: initial
                        })(
                            <OptionSelectTable
                                productId={product ? product._id : undefined} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(
    inject('commonStore', 'optionStore')(
        observer(Form.create()(AddProductOptionForm))
    )
)