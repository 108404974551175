import React, { Component } from 'react'
import { Modal, Tag, Input } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'

class DeleteShopModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            deletingShopName: undefined
        }
    }

    render() {
        const { intl, visible, isSubmitting, shop } = this.props
        if (!shop) {
            return null
        }
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width={960}
                title={intl.formatMessage({ ...messages.deleteReminder })}
                okText={intl.formatMessage({ ...messages.confirm })}
                cancelText={intl.formatMessage({ ...messages.cancel })}
                okButtonProps={{
                    disabled: this.state.deletingShopName !== shop.name
                }}
                onOk={() => {
                    if (this.state.deletingShopName === shop.name) {
                        this.props.onSubmit(shop._id)
                    }
                }}
                onCancel={() => this.props.onClose()}>
                <div style={{ marginBottom: 16 }}>{intl.formatMessage({ ...messages.pleaseInputShopNameAgain })}<Tag>{shop.name}</Tag> :</div>
                <Input
                    value={this.state.deletingShopName}
                    onChange={(e) => {
                        this.setState({ deletingShopName: e.target.value })
                    }} />
            </Modal>
        )
    }
}

export default injectIntl(DeleteShopModal)