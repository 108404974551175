import React from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { Table, Button, message, Input, Icon, Checkbox, Row, DatePicker, Tag, Divider } from 'antd'
import { LIMIT } from '~/constants/common'
import { DATE_FORMAT } from '~/constants/format'
import messages from '~/messages'
import moment from 'moment'
import queryString from 'query-string'
import { Helmet } from 'react-helmet'
import FoodOrderDetailModal from '~/components/foodOrderDetailModal'
import FoodOrderRefundModal from '~/components/foodOrderRefundModal'
import FoodOrderCompensateModal from '~/components/foodOrderCompensateModal'
import { foodOrderStatusOptions, foodDeliveryMethodOptions, pageSizeOptions, deliveryStatusOptions } from '~/constants/options'
import { getStatus } from '~/lib/foodOrderDeliveryStatusHelper'
import update from 'immutability-helper'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
`

const TableWrapper = styled.div`
    background-color: white;
`

const EllipsisText = styled.div`
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis;
`

const ImageWrapper = styled.a`
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
`

@inject('commonStore', 'orderStore', 'analysisStore') @observer
class MallFoodOrderPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            currentPage: 1,
            orderDetailVisible: false,
            orderRefundVisible: false,
            orderCompensateVisible: false,
            pageSize: LIMIT,
            filterValues: {
                orderId: undefined,
                deliveryMethod: [],
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortedInfo: undefined,
            selectedOrderId: undefined,
            previewVisible: false,
            previewImages: [],
            mode: 'TODAY',
            range: undefined
        }
        this.formRef = React.createRef()
    }

    async componentDidMount() {
        const { orderStore, commonStore, analysisStore, location } = this.props
        const { token } = commonStore
        const { pageSize, sortedInfo, filterValues } = this.state
        const params = queryString.parse(location.search)
        const currentPage = params.page || 1
        const sortField = sortedInfo && sortedInfo.order ? (sortedInfo.order === 'ascend' ? `${sortedInfo.sorter.columnKey}` : `-${sortedInfo.sorter.columnKey}`) : null
        await orderStore.listFoodOrder(token, undefined, pageSize, pageSize * (currentPage - 1),
            sortField,
            filterValues.orderId,
            filterValues.deliveryMethod,
            filterValues.status,
            filterValues.createdAtMin ? filterValues.createdAtMin.format() : undefined,
            filterValues.createdAtMax ? filterValues.createdAtMax.format() : undefined
        )
        this.setState({ currentPage })
    }

    handleOnOrderUpdate(values, reset) {
        const { orderStore, commonStore, intl } = this.props
        const { selectedOrderId } = this.state
        const { token } = commonStore
        orderStore.updateFoodOrder(token, undefined, selectedOrderId, values.status)
            .then(() => {
                reset()
                this.setState({ orderDetailVisible: false, selectedOrderId: undefined })
                message.success(intl.formatMessage({ ...messages.updateOrderSuccess }))
                const { pageSize, currentPage, sortField } = this.state
                const {
                    orderId,
                    deliveryMethod,
                    status,
                    createdAtMin,
                    createdAtMax
                } = this.state.filterValues
                orderStore.listFoodOrder(token, undefined, pageSize, pageSize * (currentPage - 1),
                    sortField,
                    orderId,
                    deliveryMethod,
                    status,
                    createdAtMin ? createdAtMin.format() : undefined,
                    createdAtMax ? createdAtMax.format() : undefined
                )
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateOrderFailure }))
            })
    }

    async handleOnOrderRefund(values, reset) {
        const { intl, orderStore, commonStore } = this.props
        const { selectedOrderId, pageSize, currentPage, sortField } = this.state
        const { token } = commonStore
        try {
            await orderStore.refundFoodOrder(token, selectedOrderId, values.amount, values.reason)
            this.setState({ orderRefundVisible: false, selectedOrderId: undefined })
            reset()
            message.success(intl.formatMessage({ ...messages.updateOrderSuccess }))
            const {
                orderId,
                deliveryMethod,
                status,
                createdAtMin,
                createdAtMax
            } = this.state.filterValues
            orderStore.listFoodOrder(token, undefined, pageSize, pageSize * (currentPage - 1),
                sortField,
                orderId,
                deliveryMethod,
                status,
                createdAtMin ? createdAtMin.format() : undefined,
                createdAtMax ? createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.updateOrderFailure }))
        }
    }

    async handleOnOrderCompensate(values, reset) {
        const { intl, orderStore, commonStore } = this.props
        const { selectedOrderId, pageSize, currentPage, sortField } = this.state
        const { token } = commonStore
        try {
            await orderStore.compensateFoodOrder(token, selectedOrderId, values.amount, values.reason)
            this.setState({ orderCompensateVisible: false, selectedOrderId: undefined })
            reset()
            message.success(intl.formatMessage({ ...messages.compensateSuccess }))
            const {
                orderId,
                deliveryMethod,
                status,
                createdAtMin,
                createdAtMax
            } = this.state.filterValues
            orderStore.listFoodOrder(token, undefined, pageSize, pageSize * (currentPage - 1),
                sortField,
                orderId,
                deliveryMethod,
                status,
                createdAtMin ? createdAtMin.format() : undefined,
                createdAtMax ? createdAtMax.format() : undefined
            )
        } catch (e) {
            message.error(intl.formatMessage({ ...messages.compensateFailure }))
        }
    }

    async handleOnRefundClick(record) {
        const { orderStore, commonStore } = this.props
        const { token } = commonStore
        await orderStore.getFoodOrder(token, undefined, record._id)
        this.setState({ orderRefundVisible: true, selectedOrderId: record._id })
    }

    async handleOnCompensateClick(record) {
        const { orderStore, commonStore } = this.props
        const { token } = commonStore
        await orderStore.getFoodOrder(token, undefined, record._id)
        this.setState({ orderCompensateVisible: true, selectedOrderId: record._id })
    }

    handleOnFilterApply(values) {
        this.setState({ filterValues: values, sortedInfo: null }, () => {
            this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
        })
    }

    handleOnTableChange(pagination, filters, sorter) {
        const { order } = sorter
        const sortField = order ? (order === 'ascend' ? `${sorter.columnKey}` : `-${sorter.columnKey}`) : null
        const page = pagination.current
        const { orderStore, commonStore } = this.props
        const { token } = commonStore
        const { pageSize } = pagination
        const {
            orderId,
            deliveryMethod,
            status,
            createdAtMin,
            createdAtMax
        } = this.state.filterValues
        orderStore.listFoodOrder(token, undefined, pageSize, pageSize * (page - 1),
            sortField,
            orderId,
            deliveryMethod,
            status,
            createdAtMin ? createdAtMin.format() : undefined,
            createdAtMax ? createdAtMax.format() : undefined
        )
        const href = `/mallFoodOrder?page=${page}`
        this.props.history.replace(href)
        this.setState({
            currentPage: page,
            pageSize: pageSize,
            sortedInfo: sorter
        })
    }

    async handleOnEditClick(record) {
        const { orderStore, commonStore } = this.props
        const { token } = commonStore
        await orderStore.getFoodOrder(token, undefined, record._id)
        this.setState({ orderDetailVisible: true, selectedOrderId: record._id })
    }

    handleSearch(confirm) {
        confirm()
        this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {})
    }

    handleReset(dataIndex, clearFilters) {
        clearFilters()
        switch (dataIndex) {
            case 'orderId':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'deliveryMethod':
            case 'status':
                this.setState({
                    filterValues: update(this.state.filterValues, { [dataIndex]: { $set: [] } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            case 'createdAt':
                this.setState({
                    filterValues: update(this.state.filterValues, { 'createdAtMin': { $set: undefined }, 'createdAtMax': { $set: undefined } })
                }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
                break
            default:
                break
        }
    }

    handleOnResetAllClick() {
        this.setState({
            filterValues: {
                orderId: undefined,
                deliveryMethod: [],
                status: [],
                createdAtMin: undefined,
                createdAtMax: undefined
            },
            sortField: undefined
        }, () => this.handleOnTableChange({ current: 1, pageSize: this.state.pageSize }, null, {}))
    }

    renderFilterDropdownInput(dataIndex) {
        switch (dataIndex) {
            case 'orderId':
                return (
                    <Input
                        ref={node => { this.searchInput = node }}
                        placeholder={this.props.intl.formatMessage({ ...messages.pleaseInput })}
                        value={this.state.filterValues[dataIndex]}
                        onChange={e => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: e.target.value } })
                            this.setState({
                                filterValues
                            })
                        }}
                        style={{ width: 100, marginBottom: 8, display: 'block' }}
                    />
                )
            case 'deliveryMethod':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {foodDeliveryMethodOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'status':
                return (
                    <Checkbox.Group
                        value={this.state.filterValues[dataIndex]}
                        onChange={value => {
                            const filterValues = update(this.state.filterValues, { [dataIndex]: { $set: value } })
                            this.setState({ filterValues })
                        }}
                        style={{ marginBottom: 8, display: 'block' }}>
                        {foodOrderStatusOptions.map((g, i) => { return <Row key={i}><Checkbox value={g.value}>{this.props.intl.formatMessage({ ...g.text })}</Checkbox></Row> })}
                    </Checkbox.Group>
                )
            case 'createdAt':
                return (
                    <div>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMin}
                                placeholder={this.props.intl.formatMessage({ ...messages.startTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMin': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                        <Row>
                            <DatePicker
                                style={{ marginBottom: 9 }}
                                showTime
                                format={DATE_FORMAT}
                                value={this.state.filterValues.createdAtMax}
                                placeholder={this.props.intl.formatMessage({ ...messages.endTime })}
                                onChange={value => {
                                    const filterValues = update(this.state.filterValues, { 'createdAtMax': { $set: value } })
                                    this.setState({ filterValues })
                                }}
                            />
                        </Row>
                    </div>
                )
            default:
                return null
        }
    }

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
            return (
                <div style={{ padding: 8 }}>
                    {this.renderFilterDropdownInput(dataIndex)}
                    <Row type='flex' justify='space-between'>
                        <a
                            onClick={() => this.handleSearch(confirm)}>
                            {this.props.intl.formatMessage({ ...messages.search })}
                        </a>
                        <a
                            onClick={() => this.handleReset(dataIndex, clearFilters)}>
                            {this.props.intl.formatMessage({ ...messages.reset })}
                        </a>
                    </Row>
                </div>
            )
        },
        filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilterDropdownVisibleChange: (visible) => {
            if (visible && (dataIndex === 'orderId')) {
                setTimeout(() => this.searchInput.select())
            }
        },
    })

    renderTableColumn() {
        const { intl } = this.props
        const { sortedInfo } = this.state
        return [
            {
                title: intl.formatMessage({ ...messages.orderId }),
                dataIndex: 'orderId',
                key: 'orderId',
                fixed: 'left',
                width: 150,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'orderId' && sortedInfo.order,
                ...this.getColumnSearchProps('orderId')
            },
            {
                title: intl.formatMessage({ ...messages.shopName }),
                dataIndex: 'shop.name',
                key: 'shopName',
                width: 150,
            },
            {
                title: intl.formatMessage({ ...messages.totalPrice }),
                dataIndex: 'totalPrice',
                key: 'totalPrice',
                width: 100,
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'totalPrice' && sortedInfo.order,
            },
            {
                title: intl.formatMessage({ ...messages.refund }),
                dataIndex: 'stripeRefundId',
                key: 'stripeRefundId',
                width: 120,
                render: (text, record) => {
                    return record.stripeRefundId
                        ? `${intl.formatMessage({ ...messages.refunded })}: $${record.refundAmount}`
                        : (
                            <a onClick={() => this.handleOnRefundClick(record)}>
                                {intl.formatMessage({ ...messages.refund })}
                            </a>
                        )
                }
            },
            {
                title: intl.formatMessage({ ...messages.deliveryMethod }),
                dataIndex: 'deliveryMethod',
                key: 'deliveryMethod',
                width: 100,
                render: (text, record) => {
                    const index = foodDeliveryMethodOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...foodDeliveryMethodOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('deliveryMethod')
            },
            {
                title: intl.formatMessage({ ...messages.compensate }),
                dataIndex: 'compensationStripeRefundId',
                key: 'compensationStripeRefundId',
                width: 120,
                render: (text, record) => {
                    return record.compensationStripeRefundId
                        ? `${intl.formatMessage({ ...messages.compensated })}: $${record.compensationAmount}`
                        : (
                            <a onClick={() => this.handleOnCompensateClick(record)}>
                                {intl.formatMessage({ ...messages.compensate })}
                            </a>
                        )
                }
            },
            {
                title: intl.formatMessage({ ...messages.status }),
                dataIndex: 'status',
                key: 'status',
                width: 100,
                render: (text, record) => {
                    const index = foodOrderStatusOptions.findIndex(c => c.value === text)
                    return index > -1 ? intl.formatMessage({ ...foodOrderStatusOptions[index].text }) : null
                },
                ...this.getColumnSearchProps('status')
            },
            {
                title: intl.formatMessage({ ...messages.logisticStatus }),
                dataIndex: 'zeekStatus',
                key: 'zeekStatus',
                width: 130,
                render: (text, record) => {
                    if (record.deliveryMethod === 'SELF_PICK_UP') {
                        return '-'
                    }
                    if (!record.zeekStatus && record.deliveryMethod === 'DELIVERY') {
                        return intl.formatMessage({ ...messages.waitingForFoodDeliveryManAcceptingOrder })
                    }
                    const status = getStatus(record.zeekStatus.orderStatus)
                    const index = deliveryStatusOptions.findIndex(c => c.value === status)
                    if (index > -1 && deliveryStatusOptions[index].value === 'COMPLETE' && record.zeekStatus.timestamp) {
                        const minuteSpent = moment(record.zeekStatus.timestamp * 1000).diff(moment(record.createdAt), 'minute')
                        return `${intl.formatMessage({ ...deliveryStatusOptions[index].text })} (${intl.formatMessage({ ...messages.durationMinute }, { duration: minuteSpent })})`
                    }
                    return index > -1 ? intl.formatMessage({ ...deliveryStatusOptions[index].text }) : '-'
                }
            },
            {
                title: intl.formatMessage({ ...messages.createdAt }),
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                sortOrder: sortedInfo && sortedInfo.columnKey === 'createdAt' && sortedInfo.order,
                render: (createdAt) => (
                    <span>
                        {moment(createdAt).format(DATE_FORMAT)}
                    </span>
                ),
                ...this.getColumnSearchProps('createdAt')
            },
            {
                title: intl.formatMessage({ ...messages.actions }),
                key: 'actions',
                fixed: 'right',
                width: 70,
                render: (text, record) => {
                    return (
                        <span>
                            <a onClick={() => this.handleOnEditClick(record)}>
                                {intl.formatMessage({ ...messages.edit })}
                            </a>
                        </span>
                    )
                }
            }
        ]
    }

    renderFilterTags() {
        const { intl } = this.props
        const { filterValues } = this.state
        const tags = []
        for (var key in filterValues) {
            if (filterValues.hasOwnProperty(key)) {
                switch (key) {
                    case 'orderId':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.orderId })}: ${filterValues[key]}`}</Tag>)
                        }
                        break
                    case 'deliveryMethod':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = foodDeliveryMethodOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.deliveryMethod })}: ${text}`}</Tag>)
                        }
                        break
                    case 'status':
                        if (filterValues[key].length > 0) {
                            const value = filterValues[key]
                            const filteredOptions = foodOrderStatusOptions.filter(s => { return value.includes(s.value) })
                            const text = filteredOptions.map(o => {
                                return intl.formatMessage({ ...o.text })
                            }).join(', ')
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.status })}: ${text}`}</Tag>)
                        }
                        break
                    case 'createdAtMin':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMin })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    case 'createdAtMax':
                        if (filterValues[key]) {
                            tags.push(<Tag>{`${intl.formatMessage({ ...messages.createdAtMax })}: ${moment(filterValues[key]).format(DATE_FORMAT)}`}</Tag>)
                        }
                        break
                    default:
                        break
                }
            }
        }
        return tags
    }

    render() {
        const { orderStore, intl } = this.props
        const { currentPage, pageSize, selectedOrderId } = this.state
        var selectedOrderIndex
        if (selectedOrderId) {
            const index = orderStore.foodOrders.findIndex(o => o._id === selectedOrderId)
            selectedOrderIndex = index
        }
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.foodOrder })}</title>
                </Helmet>
                <ActionWrapper>
                    <ActionLeftWrapper>
                        <Button type="primary" onClick={() => this.handleOnResetAllClick()}>
                            {intl.formatMessage({ ...messages.resetAllSearch })}
                        </Button>
                    </ActionLeftWrapper>
                </ActionWrapper>
                <ActionWrapper>
                    {this.renderFilterTags()}
                </ActionWrapper>
                <TableWrapper>
                    <Table
                        columns={this.renderTableColumn()}
                        dataSource={toJS(orderStore.foodOrders)}
                        pagination={
                            {
                                showSizeChanger: true,
                                defaultPageSize: LIMIT,
                                pageSizeOptions: pageSizeOptions,
                                showQuickJumper: true,
                                current: +currentPage,
                                pageSize: pageSize,
                                total: orderStore.foodOrderCount,
                                showTotal: (total) => { return intl.formatMessage({ ...messages.showTotalDisplayText }, { total }) }
                            }
                        }
                        scroll={{ x: 1100 }}
                        onChange={(pagination, filters, sorter) => this.handleOnTableChange(pagination, filters, sorter)}
                        loading={orderStore.isSubmitting}
                        rowKey={record => record._id}
                    />
                </TableWrapper>
                <FoodOrderDetailModal
                    order={toJS(orderStore.foodOrders[selectedOrderIndex])}
                    visible={this.state.orderDetailVisible}
                    isSubmitting={orderStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnOrderUpdate(values, reset)}
                    onClose={() => this.setState({ orderDetailVisible: false, selectedOrderId: undefined })} />
                <FoodOrderRefundModal
                    order={toJS(orderStore.foodOrders[selectedOrderIndex])}
                    visible={this.state.orderRefundVisible}
                    isSubmitting={orderStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnOrderRefund(values, reset)}
                    onClose={() => this.setState({ orderRefundVisible: false, selectedOrderId: undefined })} />
                <FoodOrderCompensateModal
                    order={toJS(orderStore.foodOrders[selectedOrderIndex])}
                    visible={this.state.orderCompensateVisible}
                    isSubmitting={orderStore.isSubmitting}
                    onSubmit={(values, reset) => this.handleOnOrderCompensate(values, reset)}
                    onClose={() => this.setState({ orderCompensateVisible: false, selectedOrderId: undefined })} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(MallFoodOrderPage))