import React, { Component } from 'react'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Modal, Button, DatePicker } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { DATE_ONLY_FORMAT } from '~/constants/format'
import moment from 'moment'
import pdfMake from "pdfmake/build/pdfmake"
import fontLoader from '~/lib/fontLoader'

const TRANSACTION_FEE_PERCENTAGE = process.env.REACT_APP_TRANSACTION_FEE_PERCENTAGE
const DELIVERY_PLATFORM_FEE_PERCENTAGE = process.env.REACT_APP_DELIVERY_PLATFORM_FEE_PERCENTAGE
const SELF_PICK_UP_PLATFORM_FEE_PERCENTAGE = process.env.REACT_APP_SELF_PICK_UP_PLATFORM_FEE_PERCENTAGE
const DELIVERY_FEE = process.env.REACT_APP_DELIVERY_FEE

@inject('commonStore', 'analysisStore') @observer
class StoreReportModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
            isMakingPDF: false,
            range: [moment(), moment()]
        }
    }


    getPdf(callback) {
        const { shop, store, intl, analysisStore } = this.props
        const { range } = this.state
        const { storeReport } = analysisStore
        var img = new Image()
        img.crossOrigin = 'anonymous'
        img.onload = function () {
            var canvas = document.createElement('CANVAS')
            const ctx = canvas.getContext('2d')
            canvas.height = img.height
            canvas.width = img.width
            ctx.drawImage(img, 0, 0)
            const dataURL = canvas.toDataURL('image/png')
            const { platformFee, transactionFee, revenue, sum, totalDeliveryFee, deliveryCount, selfPickUpCount, dates, refundTotal, couponDiscount } = toJS(storeReport)
            const tableData = dates.map(({ _id, deliveryFee, deliveryOrderCount, deliveryAmount, selfPickUpOrderCount, selfPickUpAmount, dailyTotalAmount }, index) => {
                return [
                    moment(_id).format(DATE_ONLY_FORMAT),
                    { text: `${deliveryOrderCount}` },
                    { text: `HK$${deliveryAmount}` },
                    { text: `${selfPickUpOrderCount}` },
                    { text: `HK$${selfPickUpAmount}` },
                    { text: `HK$${dailyTotalAmount}` }
                ]
            })

            const definition = {
                content: [
                    { image: dataURL, width: 50, height: 50, alignment: 'center' },
                    { text: 'Shopper', style: 'header', alignment: 'center' },
                    { text: intl.formatMessage({ ...messages.onlineSalesReport }), style: 'header', alignment: 'center' },
                    { text: ' ' },
                    { text: intl.formatMessage({ ...messages.to }) },
                    { text: `${shop.name} (${store.name})` },
                    { text: store.address },
                    { text: ' ' },
                    { text: `${intl.formatMessage({ ...messages.date })}: ${range[0].startOf('day').format(DATE_ONLY_FORMAT)} - ${range[1].endOf('day').format(DATE_ONLY_FORMAT)}` },
                    { text: `${intl.formatMessage({ ...messages.currency })}: ${intl.formatMessage({ ...messages.hkd })}` },
                    { text: `${intl.formatMessage({ ...messages.orderCount })}: ${deliveryCount + selfPickUpCount}` },
                    { text: ' ' },
                    {
                        table: {
                            headerRows: 1,
                            widths: ['*', 70, 70, 70, 70, 70],
                            body: [
                                [
                                    { text: intl.formatMessage({ ...messages.date }) },
                                    { text: intl.formatMessage({ ...messages.deliveryOrderCount }) },
                                    { text: intl.formatMessage({ ...messages.deliveryRevenue }) },
                                    { text: intl.formatMessage({ ...messages.selfPickUpOrderCount }) },
                                    { text: intl.formatMessage({ ...messages.selfPickUpRevenue }) },
                                    { text: intl.formatMessage({ ...messages.totalRevenue }) }
                                ],
                                ...tableData,
                                [
                                    { text: `${intl.formatMessage({ ...messages.subtotal })}:`, alignment: 'right' },
                                    `${deliveryCount}`,
                                    `HK$${sum.delivery}`,
                                    `${selfPickUpCount}`,
                                    `HK$${sum.selfPickUp}`,
                                    `HK$${sum.total}`
                                ]
                            ]
                        },
                    },
                    { text: ' ' },
                    {
                        table: {
                            headerRows: 1,
                            widths: ['*', 70, 70, 70],
                            body: [
                                [
                                    { text: '' },
                                    { text: intl.formatMessage({ ...messages.deliveryRevenue }) },
                                    { text: intl.formatMessage({ ...messages.selfPickUpRevenue }) },
                                    { text: intl.formatMessage({ ...messages.totalRevenue }) }
                                ],
                                [
                                    { text: `${intl.formatMessage({ ...messages.subtotal })}:`, alignment: 'right' },
                                    `HK$${sum.delivery}`,
                                    `HK$${sum.selfPickUp}`,
                                    `HK$${sum.total}`
                                ],
                                // [
                                //     {
                                //         text: `${intl.formatMessage({ ...messages.deliveryFee })}(${intl.formatMessage({ ...messages.deliveryFeePerOrder }, { deliveryFee: DELIVERY_FEE })})`,
                                //         alignment: 'right'
                                //     },
                                //     { text: `HK$${totalDeliveryFee}`, color: 'red' },
                                //     { text: `-`, color: 'red' },
                                //     { text: `HK$${totalDeliveryFee}`, color: 'red' },
                                // ],
                                [
                                    {
                                        text: `${intl.formatMessage({ ...messages.shopperPlatformFee })}(${intl.formatMessage({ ...messages.delivery })}:${DELIVERY_PLATFORM_FEE_PERCENTAGE}%, ${intl.formatMessage({ ...messages.selfPickUp })}:${SELF_PICK_UP_PLATFORM_FEE_PERCENTAGE}%)`,
                                        alignment: 'right'
                                    },
                                    { text: `HK$${platformFee.delivery}`, color: 'red' },
                                    { text: `HK$${platformFee.selfPickUp}`, color: 'red' },
                                    { text: `HK$${platformFee.total}`, color: 'red' },
                                ],
                                [
                                    {
                                        text: `${intl.formatMessage({ ...messages.transactionFee })}(${TRANSACTION_FEE_PERCENTAGE}%)`,
                                        alignment: 'right'
                                    },
                                    { text: `HK$${transactionFee.delivery}`, color: 'red' },
                                    { text: `HK$${transactionFee.selfPickUp}`, color: 'red' },
                                    { text: `HK$${transactionFee.total}`, color: 'red' },
                                ],
                                [
                                    { 
                                        text: intl.formatMessage({ ...messages.amountReceived }),
                                        alignment: 'right'
                                    },
                                    { text: `HK$${revenue.delivery}`, color: 'green' },
                                    { text: `HK$${revenue.selfPickUp}`, color: 'green' },
                                    { text: `HK$${revenue.total}`, color: 'green' },
                                ]
                            ]
                        }
                    },
                    { text: ' ' },
                    { text: `${intl.formatMessage({ ...messages.refundAmount })}: $${refundTotal}`, color: 'red' },
                    { text: `${intl.formatMessage({ ...messages.couponDiscount })}: $${couponDiscount}`, color: 'red' },
                    { text: `${intl.formatMessage({ ...messages.amountReceivedAfterRefund })}: $${revenue.total - refundTotal - couponDiscount}`, color: 'green' },
                ],
                defaultStyle: {
                    font: 'msyh'
                }
            }

            fontLoader.load()
                .then(() => {
                    const doc = pdfMake.createPdf(definition)
                    callback(doc)

                    canvas = null
                })
        }
        img.src = `${process.env.PUBLIC_URL}/favicon.ico`
    }

    async handleOnGetClick() {
        const { analysisStore, commonStore, shop, store } = this.props
        const { token } = commonStore
        const { range } = this.state
        const start = range[0].startOf('day').format()
        const end = range[1].endOf('day').format()
        await analysisStore.getStoreReport(token, shop._id, store._id, start, end)
        this.setState({ isMakingPDF: true })
        this.getPdf((doc) => {
            doc.download(`${shop.name}_report_${range[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${range[1].endOf('day').format(DATE_ONLY_FORMAT)}.pdf`)
            this.setState({ isMakingPDF: false })
        })
    }

    render() {
        const { intl, visible, isSubmitting, shop, store } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.salesReport })}
                onCancel={() => this.props.onClose()}
                footer={[
                    <Button onClick={() => this.props.onClose()}>{intl.formatMessage({ ...messages.cancel })}</Button>
                ]}>
                <DatePicker.RangePicker
                    allowClear={false}
                    value={this.state.range}
                    onChange={(dates) => this.setState({ range: dates })}
                    disabledDate={(current) => {
                        return current && current > moment().endOf('day')
                    }} />
                <Button loading={this.state.isMakingPDF} onClick={() => this.handleOnGetClick()}>{intl.formatMessage({ ...messages.downloadReport })}</Button>
            </Modal>
        )
    }
}

export default injectIntl(StoreReportModal)