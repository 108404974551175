import React from 'react'
import { Checkbox, Form, Input, Select, InputNumber, Icon, Radio, Upload, Modal } from 'antd'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { statusOptions, deliveryCarrierOptions } from '~/constants/options'
import RichTextEditor from '~/components/richTextEditor'
import BraftEditor from 'braft-editor'
import moment from 'moment'
import BusinessHourInput from '~/components/forms/createStoreForm/businessHourInput'
import AddressInput from '~/components/forms/createStoreForm/addressInput'
import DeliveryMethodsCheckbox from '~/components/forms/createStoreForm/deliveryMethodsCheckbox'
import { weekOptions } from '~/constants/options'
import _ from 'lodash'

class CreateStoreForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            previewVisible: false,
            previewImage: ''
        }
    }

    handleSubmit() {
        this.props.form.validateFields((err, values) => {
            if (!err) {
                this.props.onSubmit(values, () => { this.props.form.resetFields() })
            }
        })
    }

    resetForm() {
        this.props.form.resetFields()
    }

    handlePreviewCancel() {
        this.setState({ previewVisible: false })
    }

    handlePreview(file) {
        this.setState({
            previewImage: file.url || file.thumbUrl,
            previewVisible: true,
        })
    }

    getBusinessHourInitialValue({ Sunday, Monday, Tuesday, Wednesday, Thursday, Friday, Saturday }) {
        var result = []
        result.push({
            day: '0',
            fromTime: Sunday ? Sunday[0] : undefined,
            toTime: Sunday ? Sunday[1] : undefined
        })
        result.push({
            day: '1',
            fromTime: Monday ? Monday[0] : undefined,
            toTime: Monday ? Monday[1] : undefined
        })
        result.push({
            day: '2',
            fromTime: Tuesday ? Tuesday[0] : undefined,
            toTime: Tuesday ? Tuesday[1] : undefined
        })
        result.push({
            day: '3',
            fromTime: Wednesday ? Wednesday[0] : undefined,
            toTime: Wednesday ? Wednesday[1] : undefined
        })
        result.push({
            day: '4',
            fromTime: Thursday ? Thursday[0] : undefined,
            toTime: Thursday ? Thursday[1] : undefined
        })
        result.push({
            day: '5',
            fromTime: Friday ? Friday[0] : undefined,
            toTime: Friday ? Friday[1] : undefined
        })
        result.push({
            day: '6',
            fromTime: Saturday ? Saturday[0] : undefined,
            toTime: Saturday ? Saturday[1] : undefined
        })
        return result
    }

    normFile(e) {
        if (Array.isArray(e)) {
            return e
        }
        return e && e.fileList
    }

    renderImageUploaderArea() {
        const { intl, isSubmitting, initial } = this.props
        const { getFieldDecorator, getFieldValue } = this.props.form
        const uploadButton = (
            <div>
                <Icon type="plus" />
                <div className="ant-upload-text">{intl.formatMessage({ ...messages.uploadPhoto })}</div>
            </div>
        )
        return (
            <Form.Item
                label={intl.formatMessage({ ...messages.uploadPhoto })}>
                {getFieldDecorator(`photos`, {
                    rules: [
                        { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                    ],
                    valuePropName: 'fileList',
                    getValueFromEvent: (e) => this.normFile(e),
                    initialValue: (initial && initial.photo) ? [{
                        uid: '1',
                        name: 'xxx.png',
                        status: 'done',
                        url: initial.photo,
                    }] : []
                })(
                    <Upload
                        listType="picture-card"
                        onPreview={(file) => this.handlePreview(file)}
                        disabled={isSubmitting}
                        beforeUpload={(file) => {
                            return false
                        }}
                    >
                        {getFieldValue('photos').length >= 1 ? null : uploadButton}
                    </Upload>
                )}
            </Form.Item>
        )
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        const formItemLayout = {
            labelCol: { span: 4 },
            wrapperCol: { span: 20 },
        }
        return (
            <Form {...formItemLayout}>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('name', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.name : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.name })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.address })}>
                    {
                        getFieldDecorator('address', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.address : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.address })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.coordinate })}>
                    {
                        getFieldDecorator('coordinate', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: {
                                // address: initial ? initial.address : undefined,
                                lng: initial ? initial.coordinate[0] : undefined,
                                lat: initial ? initial.coordinate[1] : undefined
                            }
                        })(
                            <AddressInput
                                disabled={isSubmitting}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.phoneNumber })}>
                    {
                        getFieldDecorator('phoneNumber', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.phoneNumber : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.phoneNumber })}
                                disabled={isSubmitting}
                                addonBefore='+852' />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.storeDescription })}>
                    {
                        getFieldDecorator('description', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? BraftEditor.createEditorState(initial.description) : undefined
                        })(
                            <RichTextEditor
                                disabled={isSubmitting}
                                placeholder={intl.formatMessage({ ...messages.description })}
                            />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.deliverRadius })}>
                    {
                        getFieldDecorator('deliverRadius', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.deliverRadius : undefined
                        })(
                            <InputNumber
                                min={0}
                                formatter={value => `${value}  ${intl.formatMessage({ ...messages.meters })}`}
                                placeholder={intl.formatMessage({ ...messages.deliverRadius })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.businessHour })}>
                    {
                        getFieldDecorator('businessHours', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: this.getBusinessHourInitialValue(initial ? initial.businessHours : {})
                        })(
                            <BusinessHourInput
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.foodDeliveryMethods })}>
                    {
                        getFieldDecorator('deliveryMethods', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.deliveryMethods : []
                        })(
                            <DeliveryMethodsCheckbox />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.kitchenPrintingEnable })}>
                    {
                        getFieldDecorator('kitchenPrintEnabled', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            valuePropName: 'checked',
                            initialValue: initial && initial.kitchenPrintEnabled ? initial.kitchenPrintEnabled : false
                        })(
                            <Checkbox />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.deliveryCarrier })}>
                    {
                        getFieldDecorator('deliveryCarrier', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.deliveryCarrier : 'C4V'
                        })(
                            <Radio.Group>
                                {
                                    deliveryCarrierOptions.map(d => <Radio key={d.value} value={d.value}>{d.text}</Radio>)
                                }
                            </Radio.Group>
                        )
                    }
                </Form.Item>
                {this.renderImageUploaderArea()}
                <Form.Item
                    label={intl.formatMessage({ ...messages.status })}>
                    {
                        getFieldDecorator('status', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseSelect }) }
                            ],
                            initialValue: initial ? initial.status : undefined
                        })(
                            <Select
                                placeholder={intl.formatMessage({ ...messages.pleaseSelect })}
                                disabled={isSubmitting}>
                                {
                                    statusOptions.map((item, index) => {
                                        return <Select.Option value={item.value} key={index}>{intl.formatMessage({ ...item.text })}</Select.Option>
                                    })
                                }
                            </Select>
                        )
                    }
                </Form.Item>

                <Modal visible={this.state.previewVisible} footer={null} onCancel={() => this.handlePreviewCancel()}>
                    <img alt="example" style={{ width: '100%' }} src={this.state.previewImage} />
                </Modal>
            </Form>
        )
    }
}

export default injectIntl(Form.create()(CreateStoreForm))