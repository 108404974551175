const getMidPoint = (lat1, lng1, lat2, lng2) => {
    //-- Longitude difference
    var dLng = (lng2 - lng1) * Math.PI / 180;

    //-- Convert to radians
    lat1 = lat1 * Math.PI / 180;
    lat2 = lat2 * Math.PI / 180;
    lng1 = lng1 * Math.PI / 180;

    var bX = Math.cos(lat2) * Math.cos(dLng);
    var bY = Math.cos(lat2) * Math.sin(dLng);
    var lat3 = Math.atan2(Math.sin(lat1) + Math.sin(lat2), Math.sqrt((Math.cos(lat1) + bX) * (Math.cos(lat1) + bX) + bY * bY));
    var lng3 = lng1 + Math.atan2(bY, Math.cos(lat1) + bX);

    //-- Return result
    return [lat3 * (180 / Math.PI), lng3 * (180 / Math.PI)];
}

export {
    getMidPoint
}