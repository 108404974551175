import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import { message } from 'antd'
import { Helmet } from 'react-helmet'
import CreateNotificationForm from '~/components/forms/createNotificationForm'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
`

@inject('commonStore', 'notificationStore', 'shopStore') @observer
class NotificationPage extends React.Component {

    async componentDidMount() {
        const { commonStore, shopStore, match } = this.props
        const { token } = commonStore
        await shopStore.getShop(token, match.params._shopId)
    }

    async handleOnNotificationCreate(values) {
        const { notificationStore, commonStore, intl, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const { title, body } = values
        notificationStore.createShopNotification(token, currentShop._id, title, body)
            .then(() => {
                message.success(intl.formatMessage({ ...messages.pushNotificationSuccess }))
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.pushNotificationFailure }))
            })
    }

    render() {
        const { intl, notificationStore } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.pushNotification })}</title>
                </Helmet>
                <CreateNotificationForm
                    isSubmitting={notificationStore.isSubmitting}
                    onSubmit={(values) => this.handleOnNotificationCreate(values)} />
            </Container>
        )
    }
}

export default withRouter(injectIntl(NotificationPage))