import React from 'react'
import { Button } from 'antd'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import LocationPickerModal from '~/components/locationPickerModal'

class AddressInput extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            locationVisible: false
        }
    }

    handleOnLocationUpdate({ lat, lng }) {
        this.props.onChange({
            lat,
            lng
        })
        this.setState({ locationVisible: false })
    }

    render() {
        const { value, intl } = this.props
        return (
            <span>
                <Button onClick={() => this.setState({ locationVisible: true })}>{intl.formatMessage({...messages.pickCoordinate})}</Button>
                <div>{`Lng:${value.lng}, Lat:${value.lat}`}</div>
                <LocationPickerModal
                    coordinate={{ lng: value.lng, lat: value.lat }}
                    visible={this.state.locationVisible}
                    onClose={() => this.setState({ locationVisible: false })}
                    onLocationUpdate={(values) => this.handleOnLocationUpdate(values)}
                />
            </span>
        )
    }
}

export default injectIntl(AddressInput)