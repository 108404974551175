import React from 'react'
import { Form, InputNumber, Input } from 'antd'
import { toJS } from 'mobx'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import messages from '~/messages'
import ChoiceTable from '~/components/forms/createProductOptionForm/choiceTable'
import { getRandomId } from '~/lib/idGenerator'
import validator from '~/lib/validator'

class CreateProductOptionForm extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            searchedSuboptions: []
        }
    }

    handleSubmit() {
        const { isSubmitting } = this.props
        if (!isSubmitting) {
            this.props.form.validateFields((err, values) => {
                if (!err) {
                    this.props.onSubmit(values, () => { this.props.form.resetFields() })
                }
            })
        }
    }

    resetForm() {
        this.props.form.resetFields()
    }

    getEmptyChoices() {
        return {
            _id: getRandomId(),
            name: undefined,
            photos: [],
            subchoices: []
        }
    }

    getInitialChoices(choices) {
        return choices.map(c => {
            return {
                _id: c._id,
                name: c.name,
                photos:[
                    {
                        uid: c._id,
                        name: 'xxx.png',
                        status: 'done',
                        url: c.photo,
                    }
                ],
                subchoices: c.subchoices
            }
        })
    }

    render() {
        const { isSubmitting, intl, initial } = this.props
        const { getFieldDecorator } = this.props.form
        return (
            <Form>
                <Form.Item
                    label={intl.formatMessage({ ...messages.name })}>
                    {
                        getFieldDecorator('fieldName', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) }
                            ],
                            initialValue: initial ? initial.fieldName : undefined
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.pleaseInput })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.remark })}>
                    {
                        getFieldDecorator('remark', {
                            initialValue: initial ? initial.remark : ''
                        })(
                            <Input
                                placeholder={intl.formatMessage({ ...messages.remark })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item
                    label={intl.formatMessage({ ...messages.priority })}>
                    {
                        getFieldDecorator('priority', {
                            initialValue: initial ? initial.priority : 1
                        })(
                            <InputNumber
                                placeholder={intl.formatMessage({ ...messages.priority })}
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
                <Form.Item>
                    {
                        getFieldDecorator('choices', {
                            rules: [
                                { required: true, message: intl.formatMessage({ ...messages.pleaseInput }) },
                                { validator: (rule, value, callback) => validator.validateChoices(rule, value, callback, intl) }
                            ],
                            initialValue: initial ? this.getInitialChoices(initial.choices) : [this.getEmptyChoices(), this.getEmptyChoices()]
                        })(
                            <ChoiceTable
                                disabled={isSubmitting} />
                        )
                    }
                </Form.Item>
            </Form>
        )
    }
}

export default injectIntl(
    inject('commonStore', 'optionStore', 'shopStore')(
        observer(Form.create()(CreateProductOptionForm))
    )
)