import React from 'react'
import { injectIntl } from 'react-intl'
import { Card, Table, message } from 'antd'
import {
    Chart,
    Geom,
    Axis,
    Tooltip,
} from "bizcharts";
import messages from '~/messages'

class TopBookmarkedProductChart extends React.Component {

    renderTableColumns() {
        const { intl } = this.props
        return [
            {
                title: intl.formatMessage({...messages.rank}),
                key: 'rank',
                render: (text, record, index) => {
                    return index + 1
                }
            },
            {
                title: intl.formatMessage({...messages.productName}),
                key: 'productName',
                render: (text, record, index) => {
                    console.log(record)
                    return record.product.name
                }
            },
            {
                title: intl.formatMessage({...messages.bookmarkCount}),
                key: 'bookmarkCount',
                render: (text, record, index) => {
                    return record.totalCount
                }
            },
        ]
    }

    render() {
        const { data, intl } = this.props
        return (
            <Card title={intl.formatMessage({...messages.topBookmarkedProduct})}>
                <Table columns={this.renderTableColumns()} dataSource={data} pagination={false}/>
            </Card>
        )
    }

}

export default injectIntl(TopBookmarkedProductChart)