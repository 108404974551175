import { action, observable } from 'mobx'

class CommonStore {
    @observable username = localStorage.getItem('username')
    @observable email = localStorage.getItem('email')
    @observable role = localStorage.getItem('role')
    @observable token = localStorage.getItem('token')
    @observable user = undefined

    @action setUser(user) {
        const { username, email, role, shop, token, tokenExpAt } = user
        localStorage.setItem('username', username)
        localStorage.setItem('email', email)
        localStorage.setItem('role', role)
        localStorage.setItem('token', token)
        localStorage.setItem('tokenExpAt', tokenExpAt)
        this.username = username
        this.email = email
        this.role = role
        this.token = token
    }

    @action removeUser() {
        localStorage.removeItem('username')
        localStorage.removeItem('email')
        localStorage.removeItem('role')
        localStorage.removeItem('token')
        localStorage.removeItem('tokenExpAt')
        this.username = null
        this.email = null
        this.role = null
        this.token = null
    }

    @action updateUsernameAndEmail(username, email) {
        localStorage.setItem('username', username)
        localStorage.setItem('email', email)
        this.username = username
        this.email = email
    }
}
export default new CommonStore()
