import React, { Component } from 'react'
import { inject, observer } from 'mobx-react'
import { injectIntl } from 'react-intl'
import { DATE_FORMAT } from '~/constants/format'
import styled from 'styled-components'
import messages from '~/messages'
import moment from 'moment'
import { Table, Button, message, Modal, Input, Icon, Checkbox, Row, Divider } from 'antd'
import { statusOptions } from '~/constants/options'
import EditProductStockForm from '~/components/forms/editProductStockForm'
import { toJS } from 'mobx'

const ActionWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 16px;

    button {
        margin-right: 8px;
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
`

@inject('commonStore', 'productStore', 'shopStore') @observer
class EditProductStockModal extends Component {

    constructor(props) {
        super(props)
        this.state = {
        }
        this.formRef = React.createRef()
    }

    handleOnStockUpdate(values, reset) {
        const { productStore, commonStore, intl, product, shopStore } = this.props
        const { token } = commonStore
        const { currentShop } = shopStore
        const stocks = values.stocks.map(s => {
            const { _id, priceAdjustment, stock, cost } = s
            return { _id, priceAdjustment, stock, cost }
        })
        productStore.updateProductStock(token, currentShop._id, product._id, stocks)
            .then(() => {
                reset()
                message.success(intl.formatMessage({ ...messages.updateProductOptionSuccess }))
                this.props.onClose()
            })
            .catch((e) => {
                message.error(intl.formatMessage({ ...messages.updateProductOptionFailure }))
            })
    }

    render() {
        const { intl, visible, isSubmitting, productStore, product } = this.props
        return (
            <Modal
                visible={visible}
                mask
                maskClosable={false}
                centered
                width='960px'
                title={intl.formatMessage({ ...messages.stockManagement })}
                okText={intl.formatMessage({...messages.updateThisPage})}
                cancelText={intl.formatMessage({...messages.cancel})}
                onOk={() => this.formRef.handleSubmit()}
                onCancel={() => {
                    this.props.onClose()
                    this.formRef.resetForm()
                }}>
                <EditProductStockForm
                    product={product}
                    initial={toJS(productStore.productStocks)}
                    options={toJS(productStore.productOptions)}
                    isSubmitting={isSubmitting}
                    onSubmit={(values, reset) => this.handleOnStockUpdate(values, reset)}
                    wrappedComponentRef={(inst) => this.formRef = inst}/>
            </Modal>
        )
    }
}

export default injectIntl(EditProductStockModal)