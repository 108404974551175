import React from 'react'
import { inject, observer } from 'mobx-react'
import styled from 'styled-components'
import { withRouter } from "react-router"
import { injectIntl } from 'react-intl'
import { toJS } from 'mobx'
import { Card, Radio, Button, DatePicker } from 'antd'
import messages from '~/messages'
import moment from 'moment'
import { Helmet } from 'react-helmet'
import { DATE_FORMAT, DATE_ONLY_FORMAT } from '~/constants/format'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: stretch;
    padding: 16px;
    background-color: white;
    
    .ant-card:not(:last-child) {
        margin-bottom: 16px;
    }
`

const RowWrapper = styled.div`
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    margin-bottom: 16px;

`

const ActionLeftWrapper = styled.div`
    flex-grow: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
`

@inject('commonStore', 'analysisStore', 'shopStore') @observer
class ShopperReportPage extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            call4VanRange: [moment(), moment()],
            shopperDeliveryExportRange: [moment(), moment()],
            shopperDeliveryRange: [moment(), moment()]
        }
    }

    async handleOnCall4VanReportDownload() {
        const { analysisStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { call4VanRange } = this.state
        await analysisStore.getCall4VanReport(token, call4VanRange[0].startOf('day').format(), call4VanRange[1].endOf('day').format())
        const report = toJS(analysisStore.call4VanReport)
        const sheetHeader = [
            `${intl.formatMessage({ ...messages.orderId })}`,
            `Call4Van ID`,
            `${intl.formatMessage({ ...messages.createdAt })}`,
            `${intl.formatMessage({ ...messages.startingPoint })}`,
            `${intl.formatMessage({ ...messages.endingPoint })}`,
        ]
        const data = report.map(r => {
            return [
                r.orderId,
                r.call4VanOrderId,
                moment(r.createdAt).format(DATE_FORMAT),
                r.startingPoint,
                r.endingPoint
            ]
        })
        import('~/lib/xlsxHelper').then(xlsxHelper => {
            xlsxHelper.exportData(sheetHeader, data, `call4VanReport_${call4VanRange[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${call4VanRange[1].endOf('day').format(DATE_ONLY_FORMAT)}.xlsx`)
        })
    }
    async handleOnShopperDeliveryExportDownload() {
        const { analysisStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { shopperDeliveryExportRange } = this.state
        await analysisStore.listShopperFoodOrder(token, shopperDeliveryExportRange[0].startOf('day').format(), shopperDeliveryExportRange[1].endOf('day').format())
        const report = toJS(analysisStore.shopperFoodOrders)
        const sheetHeader = [
            '訂單號',
            `${intl.formatMessage({ ...messages.date })}`,
            '分店名稱',
            '外賣方式',
            '總金額(已包運費)',
            '運費',
            '退款金額',
            'C4V ID',
            'Zeek ID',
            `${intl.formatMessage({ ...messages.startingPoint })}`,
            `${intl.formatMessage({ ...messages.endingPoint })}`
        ]
        const data = report.map(r => {
            return [
                r.orderId,
                moment(r.createdAt).format(DATE_FORMAT),
                r.storeName,
                r.deliveryMethod,
                r.totalPrice,
                r.deliveryFee,
                r.refundAmount,
                r.call4VanOrderId,
                r.zeekOrderId,
                r.startingPoint,
                r.endingPoint
            ]
        })
        import('~/lib/xlsxHelper').then(xlsxHelper => {
            xlsxHelper.exportData(sheetHeader, data, `shopper_food_delivery_${shopperDeliveryExportRange[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${shopperDeliveryExportRange[1].endOf('day').format(DATE_ONLY_FORMAT)}.xlsx`)
        })
    }

    async handleOnShopperDeliveryReportDownload() {
        const { analysisStore, commonStore, intl } = this.props
        const { token } = commonStore
        const { shopperDeliveryRange } = this.state
        await analysisStore.getShopperReport(token, shopperDeliveryRange[0].startOf('day').format(), shopperDeliveryRange[1].endOf('day').format())
        const report = toJS(analysisStore.shopperReport)
        const sheetHeader = [
            `${intl.formatMessage({ ...messages.date })}`,
            '外賣單數量',
            '外賣營業額',
            '外賣單取消數量',
            '自取單數量',
            '自取營業額',
            '自取單取消數量',
            '每日退款金額',
            '每日總營業額',
            '外賣盈利',
            '自取盈利',
            '總盈利',
        ]
        const data = report.map(r => {
            return [
                r._id,
                r.deliveryOrderCount,
                r.deliveryAmount,
                r.cancelledDeliveryOrderCount,
                r.selfPickUpOrderCount,
                r.selfPickUpAmount,
                r.cancelledSelfPickUpOrderCount,
                r.dailyTotalRefund,
                r.dailyTotalAmount,
                r.deliveryGross,
                r.selfPickUpGross,
                r.totalGross
            ]
        })
        import('~/lib/xlsxHelper').then(xlsxHelper => {
            xlsxHelper.exportData(sheetHeader, data, `shopperReport_${shopperDeliveryRange[0].startOf('day').format(DATE_ONLY_FORMAT)}_to_${shopperDeliveryRange[1].endOf('day').format(DATE_ONLY_FORMAT)}.xlsx`)
        })
    }

    render() {
        const { intl } = this.props
        return (
            <Container>
                <Helmet>
                    <title>{intl.formatMessage({ ...messages.shopperReport })}</title>
                </Helmet>
                <RowWrapper>
                    <div style={{ marginRight: 16 }}>{`${intl.formatMessage({ ...messages.call4VanReport })}: `}</div>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={this.state.call4VanRange}
                        style={{ marginRight: 16 }}
                        onChange={(date) => this.setState({ call4VanRange: date })}
                        disabledDate={(date) => {
                            return date.diff(moment().startOf('day'), 'day') > 0
                        }} />
                    <Button type='primary' onClick={() => this.handleOnCall4VanReportDownload()}>{intl.formatMessage({ ...messages.download })}</Button>
                </RowWrapper>
                <RowWrapper>
                    <div style={{ marginRight: 16 }}>{`${intl.formatMessage({ ...messages.shopperDeliveryOrderExport })}: `}</div>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={this.state.shopperDeliveryExportRange}
                        style={{ marginRight: 16 }}
                        onChange={(date) => this.setState({ shopperDeliveryExportRange: date })}
                        disabledDate={(date) => {
                            return date.diff(moment().startOf('day'), 'day') > 0
                        }} />
                    <Button type='primary' onClick={() => this.handleOnShopperDeliveryExportDownload()}>{intl.formatMessage({ ...messages.download })}</Button>
                </RowWrapper>
                {/* <RowWrapper>
                    <div style={{ marginRight: 16 }}>{`${intl.formatMessage({ ...messages.shopperDeliveryReport })}: `}</div>
                    <DatePicker.RangePicker
                        allowClear={false}
                        value={this.state.shopperDeliveryRange}
                        style={{ marginRight: 16 }}
                        onChange={(date) => this.setState({ shopperDeliveryRange: date })}
                        disabledDate={(date) => {
                            return date.diff(moment().startOf('day'), 'day') > 0
                        }} />
                    <Button type='primary' onClick={() => this.handleOnShopperDeliveryReportDownload()}>{intl.formatMessage({ ...messages.download })}</Button>
                </RowWrapper> */}
            </Container>
        )
    }
}

export default withRouter(injectIntl(ShopperReportPage))